import { FC } from 'react'
import _ from 'lodash'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { SectionT, TableRowT } from 'model/types'
import styles from 'components/scopeReport/styles'

type Props = {
  section: SectionT
  costPlus?: number
}

const tableStyle = StyleSheet.create({
  headerText: {
    color: '#4f4f4f'
  },
  footerText: {
    color: '#AAAAAA'
  },
  tableRow: {
    height: 16
  }
})

const borderStyle = '#0.5px solid #dddddd'

const ScopeReportExclusions: FC<Props> = ({ section, costPlus }) => {
  const data = _.sortBy(section.rows, ['createdAt'])

  const tableRows = _.map(data, (r: TableRowT) => {
    return (
      <View
        key={r.id}
        style={{
          backgroundColor: '#FFF',
          flexDirection: 'row',
          ...tableStyle.tableRow,
          border: borderStyle,
          borderTop: 'none'
        }}
        wrap={false}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <Text style={{ ...styles.textSmall, color: '#004069', marginLeft: 8 }}>{r.name}</Text>
        </View>
      </View>
    )
  })

  const tableHeader = (
    <View
      style={{
        backgroundColor: '#FFF5F5',
        flexDirection: 'row',
        ...tableStyle.tableRow,
        border: borderStyle,
        marginTop: 20
      }}
      wrap={false}
    >
      <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
        <Text
          style={{
            ...styles.heading,
            ...tableStyle.headerText,
            marginLeft: 6
          }}
        >
          EXCLUSIONS
        </Text>
      </View>
    </View>
  )

  return (
    <>
      {tableHeader}
      {tableRows}
    </>
  )
}

export default ScopeReportExclusions
