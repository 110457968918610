import { Container, Flex, Heading, Text, Button, Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

import BottomPanel from 'components/BottomPanel'

const UploadFiles = () => {
  const navigate = useNavigate()
  return (
    <Flex direction={'column'} bg='gray.50' h='100%' overflow={'hidden'}>
      <Flex direction='column' h='full' overflow={'hidden'} bg='white' align={'center'} justify={'center'} pb='32'>
        <Heading size={'lg'}>Upload your files</Heading>
        <Container alignItems={'center'} w='sm' pt={10}>
          <Text textAlign={'center'}>
            Before you upload your files below, make sure your file is{' '}
            <Button variant={'link'} rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />} color='blue.500'>
              ready to be imported
            </Button>
          </Text>
        </Container>
        <Box
          w='lg'
          borderWidth={'1px'}
          borderStyle={'dashed'}
          borderColor={'gray.300'}
          borderRadius={'base'}
          py={8}
          mt={12}
        >
          <Text textAlign={'center'}>
            Drag and drop or{' '}
            <Text as='span' color='green.700'>
              choose a file
            </Text>{' '}
            to upload your plans
          </Text>
          <Text textAlign={'center'} fontSize='xs' pt={2}>
            Must be .pdf, .png or .jpg file type
          </Text>
        </Box>
      </Flex>
      <BottomPanel stepNum={1} onExit={() => navigate('/')} onNext={() => navigate('/')} />
    </Flex>
  )
}

export default UploadFiles
