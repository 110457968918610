import { useMemo } from 'react'
import _ from 'lodash'
import { Heading, Flex, Text, Button, Center } from '@chakra-ui/react'
import DashboardNavBar from 'pages/dashboard/DashboardNavBar'
import BaseTable from 'react-base-table'
import { useMeasure } from 'react-use'

import { useSelector } from 'model/hooks'
import { getAddress } from 'shared/utils/stringUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { generateId } from 'controllers/db'

type RowT = {
  id: string
  address: string
}

const Dashboard = () => {
  const navigate = useNavigate()
  const projects = useSelector(state => state.projects)
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()

  const onCreateProject = () => {
    const projectId = generateId()
    navigate(`/project/${projectId}/settings`)
  }

  const onEditProject = (projectId: string) => {
    navigate(`/project/${projectId}/settings`)
  }

  const data = useMemo(() => {
    return _.map(projects, p => {
      return {
        id: p.id,
        address: getAddress(p.address)
      }
    })
  }, [projects])

  const columns = [
    {
      flexGrow: 2,
      key: 'addres',
      dataKey: 'address',
      width: 0,
      title: 'PROJECT ADDRESS',
      cellRenderer: ({ cellData }: { cellData: string }) => <Text key={cellData}>{cellData}</Text>
    },
    {
      flexGrow: 1,
      key: 'orderDate',
      dataKey: 'orderDate',
      width: 0,
      title: 'ORDER DATE'
      // cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) =>
      //   cellRenderer('materials', { cellData, rowData })
    },
    {
      flexGrow: 1,
      key: 'deliveryDate',
      dataKey: 'deliveryDate',
      width: 0,
      title: 'DELIVERY DATE'
      // cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) =>
      //   cellRenderer('labor', { cellData, rowData })
    },
    {
      flexGrow: 0,
      key: 'open',
      // dataKey: 'total',
      width: 120,
      title: '',
      cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: RowT }) => (
        <Center>
          <Button size='sm' variant={'link'} colorScheme={'blue'} onClick={() => onEditProject(rowData.id)}>
            Open
          </Button>
        </Center>
      )
    }
  ]

  return (
    <Flex w='full' h='full' direction='column'>
      <DashboardNavBar />
      <Flex justify='center' flex={1}>
        <Flex direction={'column'} w='full' maxW='6xl' py={'24'} overflow={'hidden'} flex={1}>
          <Flex align='center' w='full' justify={'space-between'} pb={8}>
            <Heading size='lg'>Mason orders</Heading>
            <Button colorScheme={'teal'} leftIcon={<FontAwesomeIcon icon={faPlus} />} onClick={onCreateProject}>
              Create project
            </Button>
          </Flex>
          <Flex width='full' flex={1} direction={'row'} grow={1} ref={containerRef}>
            <BaseTable
              key='AdvanceTable'
              classPrefix='AdvanceTable'
              data={data}
              width={width}
              height={height}
              columns={columns}
              ignoreFunctionInColumnCompare={false}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Dashboard
