import { useMemo, ReactNode, FC, useCallback, useState, useEffect } from 'react'
import numeral from 'numeral'
import { Flex, Text, Box, Editable, EditableInput, EditablePreview, Button } from '@chakra-ui/react'
import BaseTable, { ColumnShape } from 'react-base-table'
import 'react-base-table/styles.css'
import 'src/styles.scss'
import { useMeasure } from 'react-use'
import _ from 'lodash'
import EditableTableCell from 'components/EditableTableCell'
import { DictT, TableRowT, TotalRowT } from 'model/types'
import AddTradeItem from 'pages/report/reportEditorContent/tradesTable/AddTradeItem'
import { generateId } from 'controllers/db'
import CustomHeaderCellWhite from 'pages/report/reportEditorContent/tradesTable/CustomHeaderCellWhite'
import CostPlusButton from 'pages/report/reportEditorContent/tradesTable/CostPlusButton'

type Props = {
  subtotal: number
  totalsItems: DictT<TotalRowT>
  setTotalsItems: (totalsItems: DictT<TotalRowT>) => void
  costPlus?: number
  setCostPlus: (value: number | undefined) => void
}

const ReportEditorTotalsTable: FC<Props> = ({ subtotal, totalsItems, setTotalsItems, costPlus, setCostPlus }) => {
  const [containerRef, { width }] = useMeasure<HTMLDivElement>()
  const [itemsNames, setItemsNames] = useState<DictT<string>>({})

  useEffect(() => {
    const names: DictT<string> = {}
    _.forEach(totalsItems, (r: TotalRowT) => _.set(names, r.id, r.name))
    setItemsNames(names)
  }, [totalsItems])

  const data: TotalRowT[] = useMemo(() => {
    const res = [
      {
        id: 'subtotal',
        name: 'Subtotal',
        value: _.isNil(subtotal) ? 0 : subtotal
      }
    ]
    if (!_.isNil(costPlus) && costPlus > 0) {
      res.push({
        id: 'costplus',
        name: 'Cost plus',
        value: _.round((subtotal * costPlus) / 100, 2)
      })
    }
    return [...res, ..._.values(totalsItems)]
  }, [subtotal, totalsItems, costPlus])

  console.log('totalsItems', totalsItems)
  console.log('data', data)

  const headerRenderer = ({
    cells,
    headerIndex,
    columns
  }: {
    cells: ReactNode[]
    headerIndex: number
    columns: ColumnShape
  }) => {
    console.log('headerRenderer', cells, headerIndex, columns)
    return (
      <Flex key={headerIndex} w='full' h='full' alignItems={'center'} bg='gray.600' color='white'>
        {_.map(cells, c => c)}
      </Flex>
    )
  }

  const projectTotal = useMemo(() => {
    return _.sumBy(data, 'value')
  }, [data])

  const updateItemPrice = useCallback(
    (rowData: TableRowT, value: number | undefined) => {
      const rowId = rowData.id
      if (rowId) {
        const item = _.get(totalsItems, rowId)
        const newItem = { ...item, value }
        setTotalsItems({ ...totalsItems, [newItem.id]: newItem })
      }
    },
    [totalsItems]
  )

  const cellRenderer = ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) => {
    const numV = numeral(cellData).value()
    console.log('numV', numV)
    const value = !_.isNil(numV) && numV > 0 ? numV : rowData.id === 'subtotal' ? 0 : undefined
    console.log('rowData', rowData, 'value', value)
    return (
      <EditableTableCell
        key={rowData.id}
        value={value}
        onSubmit={nextValue => updateItemPrice(rowData, nextValue)}
        placeholder='+ Add price'
        notEditable={rowData.id === 'subtotal' || rowData.id === 'costplus'}
      />
    )
  }

  const addTradeItem = (name: string) => {
    console.log('addTradeItem', name)
    const id = generateId()
    const item = { id, name }
    const newTotalsItems = { ...totalsItems, [id]: item }
    setTotalsItems(newTotalsItems)
  }

  const footerRenderer = () => {
    return (
      <Flex w='full' h='full' direction={'column'}>
        <Flex alignItems={'center'} w='full' flex={1} className='BaseTable__table-main' bgColor={'gray.100'}>
          <Flex flex={4} className='BaseTable__row-cell' justifyContent={'flex-end'} pr={4}>
            <Text fontSize={'lg'} color='blue.600' fontWeight={'semibold'}>
              Total cost of project
            </Text>
          </Flex>
          <Flex flex={1} h='full' justifyContent={'center'} borderLeftWidth={'1px'} pr={4} align={'center'}>
            <Text fontSize={'lg'} color='blue.600' fontWeight={'semibold'}>
              {numeral(projectTotal).format('$0,0.00')}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems={'center'} w='full' flex={1} className='BaseTable__table-main'>
          <Box flex={1} className='BaseTable__row-cell'>
            <AddTradeItem apply={addTradeItem} />
          </Box>
        </Flex>
      </Flex>
    )
  }

  const updateItemName = (id: string) => {
    const newTotalsItems = { ...totalsItems }
    _.set(newTotalsItems, [id, 'name'], _.get(itemsNames, id))
    setTotalsItems(newTotalsItems)
  }

  const renderFirstColumnCell = ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) => {
    const rowId = rowData.id
    if (rowData.id === 'costplus') {
      return (
        <Flex w='full' h='full' direction={'row'} justifyContent={'flex-end'} align={'center'} pr={4} gap={2}>
          <Text textAlign={'right'} key={cellData} color={'blue.600'}>
            {`Cost plus (${costPlus}%)`}
          </Text>
          <CostPlusButton
            value={costPlus}
            apply={setCostPlus}
            buttonTitle='change'
            buttonProps={{ variant: 'host', color: 'blue.400', size: 'xs' }}
          />
        </Flex>
      )
    } else if (rowData.id === 'subtotal') {
      return (
        <Flex w='full' h='full' direction={'row'} justifyContent={'flex-end'} align={'center'} pr={4}>
          <Text textAlign={'right'} key={cellData} color={'blue.600'}>
            {cellData}
          </Text>
        </Flex>
      )
    } else if (rowId) {
      return (
        <Flex direction='row' w='full' justify={'flex-end'} pr={4}>
          <Editable
            value={_.get(itemsNames, rowId)}
            onChange={e => setItemsNames({ ...itemsNames, [rowId]: e })}
            onBlur={() => updateItemName(rowId)}
            onSubmit={() => updateItemName(rowId)}
            placeholder='Set item name'
            // w='full'
          >
            <EditablePreview
              textAlign={'right'}
              minW='100%'
              color={_.isEmpty(_.get(itemsNames, rowId)) ? 'gray.300' : 'blue.600'}
            />
            <EditableInput textAlign={'right'} px={2} />
          </Editable>
        </Flex>
      )
    }
  }

  const onResetClick = () => {
    console.log('on reset click')
    setTotalsItems({})
  }

  const columns = [
    {
      flexGrow: 4,
      key: 'name',
      dataKey: 'name',
      width: 0,
      // title: 'TOTALS',
      cellRenderer: renderFirstColumnCell,
      headerRenderer: () => (
        <Flex direction='row' justifyContent={'space-between'} w='full' align={'center'} role={'group'} h='full'>
          <Text color='white'>TOTALS</Text>
          <Flex direction='row' display={'none'} _groupHover={{ display: 'block' }}>
            <Button variant={'link'} size='sm' mr={2} onClick={onResetClick} colorScheme='white'>
              Reset
            </Button>
          </Flex>
        </Flex>
      )
    },
    {
      flexGrow: 1,
      key: 'value',
      dataKey: 'value',
      width: 0,
      title: 'TOTAL',
      cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) =>
        cellRenderer({ cellData, rowData })
    }
  ]

  return (
    <Box w='full' bgColor={'gray.50'} pt={12}>
      <Flex width='full' flex={1} direction={'row'} grow={1} ref={containerRef} px={4}>
        <BaseTable
          key='AdvanceTable'
          classPrefix='AdvanceTable'
          data={data}
          width={width}
          maxHeight={1024}
          fixed={false}
          headerRenderer={headerRenderer}
          columns={columns}
          footerRenderer={footerRenderer}
          footerHeight={100}
          ignoreFunctionInColumnCompare={false}
          components={{
            TableHeaderCell: CustomHeaderCellWhite
          }}
        />
      </Flex>
    </Box>
  )
}

export default ReportEditorTotalsTable
