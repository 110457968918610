import { FC } from 'react'
import { Flex, Button } from '@chakra-ui/react'

import Steps from 'components/Steps'

type BottomPanelProps = {
  onExit: Function
  onNext: Function
  stepNum: number
}

const BottomPanel: FC<BottomPanelProps> = ({ onExit, onNext, stepNum }) => {
  return (
    <Flex direction='row' align='center' justify='space-between' pl={8} py={4} pr={8}>
      <Flex pr='xlg' flex={1} justify={'center'}>
        <Steps num={stepNum} />
      </Flex>
      <Button colorScheme={'green'} size='lg' onClick={() => onNext()}>
        Next
      </Button>
    </Flex>
  )
}

export default BottomPanel
