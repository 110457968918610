import { useImperativeHandle, ForwardRefRenderFunction, Ref, forwardRef, useMemo, useEffect } from 'react'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  useClipboard,
  useToast
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import config from 'shared/config'

export interface IShareProjectModal {
  open(): void
}

type Props = {}

const ShareProjectModal: ForwardRefRenderFunction<IShareProjectModal, Props> = (props: Props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { projectId } = useParams()
  const toast = useToast()

  const url = useMemo(() => {
    return `${config.subBidPackageUrl}/${projectId}`
  }, [projectId])

  const { hasCopied, onCopy } = useClipboard(url)

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Link copied',
        description: 'Paste the link from your clipboard to share',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    }
  }, [hasCopied])

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share bid invite link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex mb={2} py={12}>
            <Input value={url} isReadOnly placeholder='Welcome' />
            <Button colorScheme={'green'} onClick={onCopy} ml={2}>
              {hasCopied ? 'Copied' : 'Copy link'}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ShareProjectModal)
