import config from 'shared/config'
import headers from 'shared/controllers/headers'

export const accessTokenBySharebleKey = async (sharebkeKey: string) => {
  try {
    const url = `${config.dynamicUrl}/proto/accessTokenBySharebleKey/${sharebkeKey}`
    const response = await window.fetch(url, {
      method: 'get',
      headers
    })
    const answer = await response.json()
    return answer.token
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
    return null
  }
}
