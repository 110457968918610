import { useState, FC } from 'react'
import _ from 'lodash'
import { Button, HStack, Input } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  apply: (name: string) => void
}

const AddTradeItem: FC<Props> = ({ apply }) => {
  const [focused, setFocused] = useState(false)
  const [name, setName] = useState('')

  const close = () => {
    setFocused(false)
    setName('')
  }

  const onSubmit = () => {
    console.log('onSubmit')
    if (!_.isEmpty(name)) {
      apply(name)
    }
    close()
  }

  if (focused) {
    return (
      <HStack spacing={4}>
        <Input
          size='sm'
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder='Item name'
          onSubmit={onSubmit}
          onKeyDown={e => (e.key === 'Enter' ? onSubmit() : undefined)}
          autoFocus
        />
        <Button px={8} size='sm' colorScheme={'teal'} onClick={onSubmit}>
          Add
        </Button>
        <Button size='sm' color='gray.400' variant={'link'} onClick={close}>
          Cancel
        </Button>
      </HStack>
    )
  } else {
    return (
      <Button
        size='sm'
        color='gray.400'
        leftIcon={<FontAwesomeIcon icon={faPlus} />}
        variant={'link'}
        onClick={() => setFocused(true)}
      >
        Add item
      </Button>
    )
  }
}

export default AddTradeItem
