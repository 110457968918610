import throttledQueue from 'throttled-queue'
import _ from 'lodash'
import actionTypes from 'model/actionTypes'

import store from 'model/store'
import {
  receiveNotesDict,
  receiveProjectDetails,
  receiveProjectPresets,
  receiveScopeSectionsDict,
  receiveSowItemsDict,
  receiveTradesDict
} from 'model/actions/dictAC'
import { DictT, NoteT, PDetailsT, ProjectPresetT, ScopeSectionDictT, SowItemDictT } from 'model/types'

const throttle = throttledQueue(5, 1000, true)

// const mapper = (actionType: string, records: object) => {
//   const res: any = {}
//   _.forEach(records, (rec: any) => {
//     switch (actionType) {
//       case actionTypes.RECEIVE_LINE_ITEMS:
//         res[rec.id] = {
//           id: rec.id,
//           name: _.get(rec, 'fields.Name'),
//           sowItemId: _.get(rec, ['fields', 'Scope of work items', 0]),
//           alternateOf: _.get(rec, ['fields', 'Alternate of', 0])
//         }
//         break
//       default:
//         return null
//     }
//   })
//   return res
// }

const pushEvent = (ev: any) => {
  throttle(() => processQueueEvent(ev))
}

const createScopeSectionsDict = (records: object): DictT<ScopeSectionDictT> => {
  const res: DictT<ScopeSectionDictT> = {}
  _.forEach(records, (rec: any) => {
    const ss: ScopeSectionDictT = {
      id: rec.id,
      name: _.get(rec, ['fields', 'Name'])
    }
    res[ss.id] = ss
  })
  return res
}

const createSowItemsDict = (records: object): DictT<SowItemDictT> => {
  const res: DictT<SowItemDictT> = {}
  _.forEach(records, (rec: any) => {
    const r: SowItemDictT = {
      id: rec.id,
      name: _.get(rec, 'fields.Name', 'noname'),
      sectionId: _.get(rec, ['fields', 'Scope section', 0]),
      tradeId: _.get(rec, ['fields', 'Trade', 0]),
      applicable: _.get(rec, ['fields', 'Applicable if'], []),
      projects: _.get(rec, ['fields', 'Projects'], [])
    }
    if (r.sectionId && r.tradeId) res[r.id] = r
  })
  return res
}

const createNotesDict = (records: object): DictT<NoteT> => {
  const res: DictT<NoteT> = {}
  _.forEach(records, (rec: any) => {
    const r: NoteT = {
      id: rec.id,
      name: _.get(rec, 'fields.Name'),
      sectionId: _.get(rec, ['fields', 'Scope section', 0]),
      applicable: _.get(rec, ['fields', 'Applicable if'], [])
    }
    const tradeId = _.get(rec, ['fields', 'Trade', 0])
    if (tradeId) r.tradeId = tradeId
    if (r.sectionId && r.name) res[r.id] = r
  })
  return res
}

const createTradesDict = (records: object): DictT<string> => {
  const res: DictT<string> = {}
  _.forEach(records, (rec: any) => {
    res[rec.id] = _.get(rec, ['fields', 'Name'])
  })
  return res
}

const createProjectDetails = (records: object): DictT<PDetailsT> => {
  const res: DictT<PDetailsT> = {}
  _.forEach(records, (rec: any) => {
    const pd: PDetailsT = {
      id: rec.id,
      name: _.get(rec, 'fields.Name'),
      type: _.get(rec, ['fields', 'type'])
    }
    res[rec.id] = pd
  })
  return res
}

const createProjectPresets = (records: object): DictT<ProjectPresetT> => {
  const res: DictT<ProjectPresetT> = {}
  _.forEach(records, (rec: any) => {
    const pd: ProjectPresetT = {
      id: rec.id,
      name: _.get(rec, 'fields.Name'),
      notes: _.get(rec, 'fields.Notes'),
      details: _.get(rec, 'fields.Details'),
      order: _.get(rec, 'fields.Order')
    }
    res[rec.id] = pd
  })
  return res
}

const processQueueEvent = async (ev: any) => {
  console.log('process event queue', ev)
  let url = ev.url
  if (!_.isNil(ev.offset)) url = `${url}&offset=${ev.offset}`
  const r = await fetch(url, { headers })
  const data = await r.json()
  console.log('airtable response', data)
  switch (ev.actionType) {
    case actionTypes.RECEIVE_SCOPE_SECTIONS_DICT: {
      const scopeSectionsDict = createScopeSectionsDict(data.records)
      store.dispatch(receiveScopeSectionsDict(scopeSectionsDict))
      break
    }

    case actionTypes.RECEIVE_SOW_ITEMS_DICT: {
      const sowItemsDict = createSowItemsDict(data.records)
      store.dispatch(receiveSowItemsDict(sowItemsDict))
      break
    }

    case actionTypes.RECEIVE_TRADES_DICT: {
      const tradesDict = createTradesDict(data.records)
      store.dispatch(receiveTradesDict(tradesDict))
      break
    }

    case actionTypes.RECEIVE_PROJECT_DETAILS: {
      const pdetails = createProjectDetails(data.records)
      store.dispatch(receiveProjectDetails(pdetails))
      break
    }

    case actionTypes.RECEIVE_PROJECT_PRESETS: {
      const presets = createProjectPresets(data.records)
      store.dispatch(receiveProjectPresets(presets))
      break
    }

    case actionTypes.RECEIVE_NOTES_DICT: {
      const notes = createNotesDict(data.records)
      store.dispatch(receiveNotesDict(notes))
      break
    }
  }
  // store.dispatch({ type: ev.actionType, payload: mapper(ev.actionType, data.records), sowItemId: ev.sowItemId })
  if (_.has(data, 'offset')) {
    console.log('has offset, push new event')
    pushEvent({ ...ev, offset: data.offset })
  }
}

const headers = {
  Authorization: 'Bearer keyrInx5LQxJ0vG7U',
  'Content-Type': 'application/json'
}

export const fetchScopeSections = (dbId: string) => {
  const queueEvent = {
    actionType: actionTypes.RECEIVE_SCOPE_SECTIONS_DICT,
    url: `https://api.airtable.com/v0/${dbId}/Scope%20Sections?view=Grid%20view`
  }
  pushEvent(queueEvent)
}

export const fetchSowItems = (dbId: string) => {
  const queueEvent = {
    actionType: actionTypes.RECEIVE_SOW_ITEMS_DICT,
    url: `https://api.airtable.com/v0/${dbId}/Scope%20of%20work%20items?view=Grid%20view`
  }
  pushEvent(queueEvent)
}

export const fetchTrades = (dbId: string) => {
  const queueEvent = {
    actionType: actionTypes.RECEIVE_TRADES_DICT,
    url: `https://api.airtable.com/v0/${dbId}/Trades?view=Grid%20view`
  }
  pushEvent(queueEvent)
}

export const fetchProjectPresets = (dbId: string) => {
  const queueEvent = {
    actionType: actionTypes.RECEIVE_PROJECT_PRESETS,
    url: `https://api.airtable.com/v0/${dbId}/Project%20templates?view=Grid%20view`
  }
  pushEvent(queueEvent)
}

export const fetchProjectDetails = (dbId: string) => {
  const queueEvent = {
    actionType: actionTypes.RECEIVE_PROJECT_DETAILS,
    url: `https://api.airtable.com/v0/${dbId}/Applicability%20options?view=Grid%20view`
  }
  pushEvent(queueEvent)
}

export const fetchNotes = (dbId: string) => {
  const queueEvent = {
    actionType: actionTypes.RECEIVE_NOTES_DICT,
    url: `https://api.airtable.com/v0/${dbId}/Notes?view=Grid%20view`
  }
  pushEvent(queueEvent)
}

export const fetchDict = (dbId: string) => {
  fetchScopeSections(dbId)
  fetchSowItems(dbId)
  fetchTrades(dbId)
  fetchProjectPresets(dbId)
  fetchProjectDetails(dbId)
  fetchNotes(dbId)
}

export const fetchLineItems = (dbId: string, sowItemId: string, sowItemName: string) => {
  const formula = encodeURI(`FIND("${sowItemId}", {Sow items IDs})`)
  const queueEvent = {
    actionType: actionTypes.RECEIVE_LINE_ITEMS,
    url: `https://api.airtable.com/v0/${dbId}/Line%20Items?view=Grid%20view&filterByFormula=${formula}`,
    sowItemId
  }
  pushEvent(queueEvent)
}
