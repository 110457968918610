import { forwardRef, useImperativeHandle, ForwardRefRenderFunction, Ref, useContext } from 'react'
import _ from 'lodash'
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from '@chakra-ui/react'
import ProjectFiles from 'components/ProjectFiles'
import { useParams } from 'react-router-dom'
import ProjectContext from 'contexts/ProjectContext'
import { FileT, DictT } from 'shared/types'
import { useSelector } from 'model/hooks'

export interface IProjectFilesModal {
  open(): void
}

type Props = {}

const ProjectFilesModal: ForwardRefRenderFunction<IProjectFilesModal, Props> = (props: Props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { projectId } = useParams()
  const { project, updateProject, tradesDict, projectScope } = useContext(ProjectContext)
  const user = useSelector(state => state.user)
  const tradesIds = _.sortBy(_.keys(projectScope), tradeId => tradesDict[tradeId])

  const attachments = _.get(project, 'attachments', {})

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  const onFilesChanged = (files: DictT<FileT>) => {
    console.log('onFilesChanged', files)
    updateProject({ attachments: files })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Plan files</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <ProjectFiles
            files={attachments}
            setFiles={onFilesChanged}
            storagePath={`/projects/${projectId}/`}
            userId={user.id}
            tradesDict={tradesDict}
            tradesIds={tradesIds}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ProjectFilesModal)
