import { useEffect, useState, useContext } from 'react'
import { Flex } from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import ShareReportNavBar from 'pages/shareReport/ShareReportNavBar'
import ScopeReport from 'components/ScopeReport'
import { useSelector } from 'model/hooks'

import ProjectContext from 'contexts/ProjectContext'
import { getCurrentAccountProfile } from 'model/selectors/profiles'
import Loading from 'pages/Loading'

const ShareProject = () => {
  const accountProfile = useSelector(getCurrentAccountProfile)
  const {
    project,
    sections,
    sectionsOrder,
    scopeHidden,
    totalsItems,
    costPlus,
    tradesDict,
    projectScope,
    projectNotes,
    scopeSections,
    excludedScopeItemsDict
  } = useContext(ProjectContext)

  const [showPdf, setShowPdf] = useState(false)
  const [blob, setBlob] = useState<Blob | undefined>()

  const onBlob = (b: Blob) => {
    console.log('%conRender', 'color: orange;', _.isNil(b))
    setBlob(b)
  }

  useEffect(() => {
    setTimeout(() => {
      setShowPdf(true)
    }, 1000)
  }, [])

  const dateNow = moment().format('L')
  const fileName = `${_.get(project, 'address.name')}_${dateNow}.pdf`

  const renderContent = () => {
    console.log('renderContent showPdf', showPdf)
    if (showPdf && project && accountProfile) {
      return (
        <ScopeReport
          accountProfile={accountProfile}
          project={project}
          sections={sections}
          sectionsOrder={sectionsOrder}
          scopeHidden={scopeHidden}
          totalsItems={totalsItems}
          costPlus={costPlus}
          tradesDict={tradesDict}
          projectScope={projectScope}
          projectNotes={projectNotes}
          scopeSections={scopeSections}
          excludedScopeItemsDict={excludedScopeItemsDict}
          fileName={fileName}
          onBlob={onBlob}
        />
      )
    } else {
      return <Loading note='Pdf report generation...' />
    }
  }

  return (
    <Flex direction={'column'} h='full'>
      <ShareReportNavBar fileName={fileName} blob={blob} projectId={project?.id || 'unknown'} />
      <Flex h='full' w='full' direction={'column'}>
        {renderContent()}
      </Flex>
    </Flex>
  )
}

export default ShareProject
