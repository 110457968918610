import { ReactNode, FC, useState, useEffect } from 'react'
import { Flex, Box, Editable, EditableInput, EditablePreview, Button } from '@chakra-ui/react'
import BaseTable, { ColumnShape } from 'react-base-table'
import 'react-base-table/styles.css'
import 'src/styles.scss'
import { useMeasure } from 'react-use'
import _ from 'lodash'
// import EditableTableCell from 'components/EditableTableCell'
import { DictT, SectionT, TableRowT } from 'model/types'
import CustomTableHeaderCell from 'pages/report/reportEditorContent/tradesTable/CustomTableHeaderCell'
import AddTradeItem from 'pages/report/reportEditorContent/tradesTable/AddTradeItem'
import { generateId } from 'controllers/db'

type Props = {
  section: SectionT
  updateSection: (section: SectionT) => void
}

const ReportEditorExlusionsTable: FC<Props> = ({ section, updateSection }) => {
  const [containerRef, { width }] = useMeasure<HTMLDivElement>()
  const [itemsNames, setItemsNames] = useState<DictT<string>>({})

  useEffect(() => {
    const names: DictT<string> = {}
    _.forEach(section.rows, (r: TableRowT) => r.id && r.name && _.set(names, r.id, r.name))
    setItemsNames(names)
  }, [section])

  const headerRenderer = ({
    cells,
    headerIndex,
    columns
  }: {
    cells: ReactNode[]
    headerIndex: number
    columns: ColumnShape
  }) => {
    console.log('headerRenderer', cells, headerIndex, columns)
    return (
      <Flex key={headerIndex} w='full' h='full' alignItems={'center'} bg='red.50'>
        {_.map(cells, c => c)}
      </Flex>
    )
  }

  const addTradeItem = (name: string) => {
    console.log('addTradeItem', name)
    const id = generateId()
    const item = { id, name }
    const newRows = { ...section.rows, [id]: item }
    updateSection({ ...section, rows: newRows })
  }

  // const renderCellHeader = (name: string) => <Text color='gray.600'>{name}</Text>
  const footerRenderer = () => {
    return (
      <Flex alignItems={'center'} w='full' h='full' className='BaseTable__table-main'>
        <Box flex={1} className='BaseTable__row-cell'>
          <AddTradeItem apply={addTradeItem} />
        </Box>
      </Flex>
    )
  }

  const updateItemName = (id: string) => {
    const newSection = {
      ...section,
      rows: {
        ...section.rows,
        [id]: {
          ...section.rows[id],
          name: _.get(itemsNames, id)
        }
      }
    }
    updateSection(newSection)
  }

  const onRemoveItemClick = (rowId: string) => {
    const newSection = {
      ...section,
      rows: _.keyBy(
        _.filter(section.rows, r => r.id !== rowId),
        'id'
      )
    }
    updateSection(newSection)
  }

  const columns = [
    {
      flexGrow: 1,
      key: 'name',
      dataKey: 'name',
      width: 0,
      title: _.upperCase(section.name),
      cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) => {
        console.log('cellRender', rowData)
        const rowId = rowData.id
        if (rowId) {
          return (
            <Flex direction='row' w='full' h='full' justifyContent={'space-between'} align='center' role={'group'}>
              <Editable
                value={_.get(itemsNames, rowId)}
                onChange={e => setItemsNames({ ...itemsNames, [rowId]: e })}
                onBlur={() => updateItemName(rowId)}
                onSubmit={() => updateItemName(rowId)}
                placeholder='Set item name'
                // w='full'
              >
                <EditablePreview minW='100%' color={_.isEmpty(_.get(itemsNames, rowId)) ? 'gray.300' : 'blue.600'} />
                <EditableInput px={2} />
              </Editable>
              <Button
                display={'none'}
                _groupHover={{ display: 'block' }}
                variant={'link'}
                size='sm'
                mr={2}
                onClick={() => onRemoveItemClick(rowId)}
              >
                Remove
              </Button>
            </Flex>
          )
        } else {
          return null
        }
      }
    }
  ]

  return (
    <Box w='full' bgColor={'gray.50'} pb={4} pt={12}>
      <Flex width='full' flex={1} direction={'row'} grow={1} ref={containerRef} px={4}>
        <BaseTable
          key='AdvanceTable'
          classPrefix='AdvanceTable'
          data={_.values(section.rows)}
          width={width}
          maxHeight={1024}
          fixed={false}
          headerRenderer={headerRenderer}
          columns={columns}
          footerRenderer={footerRenderer}
          footerHeight={50}
          ignoreFunctionInColumnCompare={false}
          components={{
            TableHeaderCell: CustomTableHeaderCell
          }}
        />
      </Flex>
    </Box>
  )
}

export default ReportEditorExlusionsTable
