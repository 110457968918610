import * as types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { DictT, AccountProfileT } from 'model/types'

const initialState: DictT<AccountProfileT> | null = null

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_ACCOUNTS_PROFILES:
      return {
        ...state,
        ...action.profiles
      }
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
