import { DictT } from 'shared/types'
export * from 'shared/types'
export interface ScopeSectionDictT {
  id: string
  name: string
}

/* eslint-disable no-unused-vars */
export enum PDetailTypeT {
  PROJECT_TYPE = 'project type',
  STORIES = 'stories',
  DETAILS = 'details'
}
export interface PDetailsT {
  id: string
  name: string
  type: PDetailTypeT
}
export interface SowItemDictT {
  id: string
  name: string
  sectionId: string
  tradeId: string
  applicable?: string[]
  projects?: string[]
}

export interface NoteT {
  id: string
  name: string
  sectionId: string
  tradeId?: string
  applicable?: string[]
}
export interface ProjectPresetT {
  id: string
  name: string
  notes: string
  details: Array<string>
  order: number
}

export type BaseScopeT = DictT<DictT<DictT<SowItemDictT>>> // tradeId * sectionId * itemId: item
export type BaseNotesT = DictT<DictT<NoteT>> // sectionId * noteId: note
export type TradesDictT = DictT<string> // id: name

export interface ProjectDictsT {
  tradesDict: DictT<string>
  scopeSections: DictT<ScopeSectionDictT>
  presets: DictT<ProjectPresetT>
  detailsDict: DictT<PDetailsT>
  scopeItemsDict: DictT<SowItemDictT>
  notesDict: DictT<NoteT>
}

export type TableRowT = {
  id?: string
  name?: string
  materials?: number
  labor?: number
  total?: number
  createdAt?: number
}

export type SectionT = {
  id: string
  name: string
  rows: DictT<TableRowT>
}

export type TotalRowT = {
  id: string
  name: string
  value?: number
}

export interface DBProjectScopeT {
  accountId?: string
  excludedScopeItems?: string[]
  sectionsOrder: string[]
  sections: DictT<SectionT>
  scopeHidden: boolean
  totalsItems: DictT<TotalRowT>
  costPlus?: number
}
