import { useMemo, ReactNode, FC, useCallback, useState, useEffect } from 'react'
import numeral from 'numeral'
import {
  Flex,
  Text,
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  Button,
  IconButton
} from '@chakra-ui/react'
import BaseTable, { ColumnShape } from 'react-base-table'
import { useMeasure } from 'react-use'
import _ from 'lodash'
import EditableTableCell from 'components/EditableTableCell'
import { DictT, ParticipantDetailsT, SectionT, TableRowT } from 'model/types'
import CustomTableHeaderCell from 'pages/report/reportEditorContent/tradesTable/CustomTableHeaderCell'
import MarkupButton from 'pages/report/reportEditorContent/tradesTable/MarkupButton'
import AddTradeItem from 'pages/report/reportEditorContent/tradesTable/AddTradeItem'
import { generateId } from 'controllers/db'
import CostPlusButton from 'pages/report/reportEditorContent/tradesTable/CostPlusButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faPenToSquare } from '@fortawesome/pro-regular-svg-icons'
import { TRADE_SECTION_ID } from 'constants/reportSections'

type Props = {
  section: SectionT
  updateSection: (section: SectionT) => void
  costPlus?: number
  setCostPlus: (value: number | undefined) => void
  showCostPlus: boolean
  participantDetails?: ParticipantDetailsT
  openEditDetailsModal: () => void
  index: number
  isFirst: boolean
  isLast: boolean
  onResetRemoveClick: () => void
  moveSection: (fromIndex: number, toIndex: number) => void
  baseTradesRows: DictT<TableRowT>
}

const ReportEditorTable: FC<Props> = ({
  section,
  updateSection,
  costPlus,
  setCostPlus,
  showCostPlus = false,
  participantDetails,
  openEditDetailsModal,
  index,
  isFirst,
  isLast,
  onResetRemoveClick,
  moveSection,
  baseTradesRows
}) => {
  console.log('ReportEditorTable', section)
  const [containerRef, { width }] = useMeasure<HTMLDivElement>()
  const [sectionName, setSectionName] = useState(section.name)
  const [itemsNames, setItemsNames] = useState<DictT<string>>({})

  useEffect(() => {
    setSectionName(section.name)
    const names: DictT<string> = {}
    _.forEach(section.rows, (r: TableRowT) => r.id && r.name && _.set(names, r.id, r.name))
    setItemsNames(names)
  }, [section])

  const applyMarkup = (value: number) => {
    console.log('applyMarkup', value)
    console.log('applyMarkup section', section)
    const rows: DictT<TableRowT> = section.rows
    console.log('applyMarkup rows', rows)
    const newRows: DictT<TableRowT> = {}
    _.forEach(rows, (tp: TableRowT, tradeId) => {
      const newRow = {
        ...tp,
        materials: tp.materials ? _.round(((tp.materials || 0) * (100 + value)) / 100, 2) : undefined,
        total: tp.total ? _.round(((tp.total || 0) * (100 + value)) / 100, 2) : undefined,
        labor: tp.labor ? _.round(((tp.labor || 0) * (100 + value)) / 100, 2) : undefined
      }
      newRows[tradeId] = _.omitBy(newRow, _.isNil)
    })
    updateSection({ ...section, rows: newRows })
  }

  const preparedFor = (
    <HStack>
      <Heading size='sm'>Prepared for:</Heading>
      <Text>
        {participantDetails?.firstName} {participantDetails?.lastName} {participantDetails?.customerAddress}
      </Text>
      <Button variant={'link'} colorScheme={'blue'} onClick={openEditDetailsModal}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </Button>
    </HStack>
  )

  const menu = (
    <Flex direction='row' justifyContent={'space-between'} align='center' pl={4}>
      {showCostPlus ? preparedFor : <Box />}
      <Flex justifyContent={'flex-end'} py={4} px={4} gap={4}>
        {showCostPlus && <CostPlusButton value={costPlus} apply={setCostPlus} />}
        <MarkupButton apply={applyMarkup} />
      </Flex>
    </Flex>
  )

  const headerRenderer = ({
    cells,
    headerIndex,
    columns
  }: {
    cells: ReactNode[]
    headerIndex: number
    columns: ColumnShape
  }) => {
    console.log('headerRenderer', cells, headerIndex, columns)
    return (
      <Flex key={headerIndex} w='full' h='full' alignItems={'center'} bg='gray.100'>
        {_.map(cells, c => c)}
      </Flex>
    )
  }

  const updateTradePrice = useCallback(
    (rowData: TableRowT, key: string, value: number | undefined) => {
      const newRow: TableRowT = { ...rowData }
      console.log('newRow', newRow)
      if (key === 'total') {
        newRow.total = value
        newRow.labor = undefined
        newRow.materials = undefined
      } else {
        newRow.total = undefined
        if (key === 'labor') {
          newRow.labor = value
        } else {
          newRow.materials = value
        }
      }
      if (rowData.id) {
        const newRows = { ...section.rows, [rowData.id]: _.omitBy(newRow, _.isNil) }
        const newSection = { ...section, rows: newRows }
        updateSection(newSection)
      }
    },
    [section, updateSection]
  )

  const cellRenderer = (key: string, { cellData, rowData }: { cellData: string; rowData: TableRowT }) => {
    let numV = cellData ? numeral(cellData).value() : null
    // console.log('rowData', rowData, 'cellData', cellData)
    // console.log('numV', numV)
    if (_.isNil(cellData) && key === 'total') {
      const sum = (numeral(rowData.labor).value() || 0) + (numeral(rowData.materials).value() || 0)
      if (sum > 0) numV = sum
    }
    return (
      <EditableTableCell
        key={key}
        value={_.isNil(numV) ? undefined : numV}
        onSubmit={nextValue => updateTradePrice(rowData, key, nextValue)}
        placeholder='+ Add price'
      />
    )
  }

  const addTradeItem = (name: string) => {
    console.log('addTradeItem', name)
    const id = generateId()
    const item = { id, name, createdAt: _.now() }
    const newRows = { ...section.rows, [id]: item }
    updateSection({ ...section, rows: newRows })
  }

  const totals = useMemo(() => {
    const res = {
      materials: 0,
      labor: 0,
      total: 0
    }
    _.forEach(section.rows, (row: TableRowT, tradeId: string) => {
      res.materials = res.materials + (row.materials || 0)
      res.labor = res.labor + (row.labor || 0)
      let catTotal = 0
      if (_.isNil(row.total)) {
        catTotal = (row.materials || 0) + (row.labor || 0)
      } else {
        catTotal = row.total
      }
      res.total = (res.total || 0) + catTotal
    })
    return res
  }, [section.rows])

  // const renderCellHeader = (name: string) => <Text color='gray.600'>{name}</Text>
  const footerRenderer = () => {
    return (
      <Flex alignItems={'center'} w='full' h='full' className='BaseTable__table-main'>
        <Box flex={4} className='BaseTable__row-cell'>
          <AddTradeItem apply={addTradeItem} />
        </Box>
        <Flex flex={1} className='BaseTable__row-cell' justifyContent={'center'}>
          <Text fontSize={'lg'} color='gray.400'>
            {numeral(totals.materials).format('$0,0.00')}
          </Text>
        </Flex>
        <Flex flex={1} className='BaseTable__row-cell' justifyContent={'center'}>
          <Text fontSize={'lg'} color='gray.400'>
            {numeral(totals.labor).format('$0,0.00')}
          </Text>
        </Flex>
        <Flex flex={1} className='BaseTable__row-cell' justifyContent={'center'}>
          <Text fontSize={'lg'} color='gray.400'>
            {numeral(totals.total).format('$0,0.00')}
          </Text>
        </Flex>
      </Flex>
    )
  }

  const editSectionName = () => {
    updateSection({ ...section, name: _.upperCase(sectionName) })
  }

  const updateItemName = (id: string) => {
    const newSection = {
      ...section,
      rows: {
        ...section.rows,
        [id]: {
          ...section.rows[id],
          name: _.get(itemsNames, id)
        }
      }
    }
    updateSection(newSection)
  }

  const onResetRemoveItemClick = (rowId: string) => {
    console.log('onResetRemoveItemClick')
    if (_.has(baseTradesRows, rowId)) {
      const name = _.get(baseTradesRows, [rowId, 'name'])
      const newSection = {
        ...section,
        rows: {
          ...section.rows,
          [rowId]: {
            ...section.rows[rowId],
            name
          }
        }
      }
      updateSection(newSection)
    } else {
      const newSection = {
        ...section,
        rows: _.keyBy(
          _.filter(section.rows, r => r.id !== rowId),
          'id'
        )
      }
      updateSection(newSection)
    }
  }

  const columns = [
    {
      flexGrow: 4,
      key: 'name',
      dataKey: 'name',
      width: 0,
      headerRenderer: () => (
        <Flex direction='row' justifyContent={'space-between'} w='full' align={'center'} role={'group'} h='full'>
          <Editable
            value={sectionName}
            onChange={e => setSectionName(e)}
            onBlur={editSectionName}
            onSubmit={editSectionName}
            placeholder='Set section name'
            // w='full'
          >
            <EditablePreview minW='100%' color={_.isEmpty(sectionName) ? 'gray.300' : 'blue.600'} />
            <EditableInput px={2} />
          </Editable>
          <Flex direction='row' display={'none'} _groupHover={{ display: 'block' }}>
            <Button variant={'link'} size='sm' mr={2} onClick={onResetRemoveClick}>
              {section.id === TRADE_SECTION_ID ? 'Reset' : 'Remove'}
            </Button>
            <IconButton
              variant={'unstyled'}
              size='xs'
              aria-label='arrowUp'
              icon={<FontAwesomeIcon icon={faArrowUp} />}
              isDisabled={isFirst}
              onClick={() => moveSection(index, index - 1)}
            />
            <IconButton
              variant={'unstyled'}
              size='xs'
              aria-label='arrowDown'
              icon={<FontAwesomeIcon icon={faArrowDown} />}
              isDisabled={isLast}
              onClick={() => moveSection(index, index + 1)}
            />
          </Flex>
        </Flex>
      ),
      cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) => {
        // console.log('cellRender', rowData)
        const rowId = _.get(rowData, 'id')
        if (rowId) {
          return (
            <Flex direction='row' w='full' h='full' justifyContent={'space-between'} align='center' role={'group'}>
              <Editable
                value={_.get(itemsNames, rowId)}
                onChange={e => setItemsNames({ ...itemsNames, [rowId]: e })}
                onBlur={() => updateItemName(rowId)}
                onSubmit={() => updateItemName(rowId)}
                placeholder='Set item name'
              >
                <EditablePreview minW='100%' color={_.isEmpty(_.get(itemsNames, rowId)) ? 'gray.300' : 'blue.600'} />
                <EditableInput px={2} />
              </Editable>
              <Button
                display={'none'}
                _groupHover={{ display: 'block' }}
                variant={'link'}
                size='sm'
                mr={2}
                onClick={() => onResetRemoveItemClick(rowId)}
              >
                {_.has(baseTradesRows, rowId) ? 'Reset' : 'Remove'}
              </Button>
            </Flex>
          )
        }
      }
    },
    {
      flexGrow: 1,
      key: 'materials',
      dataKey: 'materials',
      width: 0,
      title: 'MATERIALS',
      cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) =>
        cellRenderer('materials', { cellData, rowData })
    },
    {
      flexGrow: 1,
      key: 'labor',
      dataKey: 'labor',
      width: 0,
      title: 'LABOR',
      cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) =>
        cellRenderer('labor', { cellData, rowData })
    },
    {
      flexGrow: 1,
      key: 'total',
      dataKey: 'total',
      width: 0,
      title: 'TOTAL',
      cellRenderer: ({ cellData, rowData }: { cellData: string; rowData: TableRowT }) =>
        cellRenderer('total', { cellData, rowData })
    }
  ]

  return (
    <Box w='full' bgColor={'gray.50'} pb={4}>
      {menu}
      <Flex width='full' flex={1} direction={'row'} grow={1} ref={containerRef} px={4}>
        <BaseTable
          key='AdvanceTable'
          classPrefix='AdvanceTable'
          data={_.sortBy(section.rows, ['createdAt'])}
          width={width}
          height={(_.size(section.rows) + 2) * 50}
          fixed={false}
          headerRenderer={headerRenderer}
          columns={columns}
          footerRenderer={footerRenderer}
          footerHeight={50}
          ignoreFunctionInColumnCompare={false}
          components={{
            TableHeaderCell: CustomTableHeaderCell
          }}
        />
      </Flex>
    </Box>
  )
}

export default ReportEditorTable
