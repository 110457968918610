import { useCallback, useContext, useMemo, useRef } from 'react'
import _ from 'lodash'
import { Box, Flex, Input, Text, VStack, HStack, Image, Checkbox, Grid, GridItem } from '@chakra-ui/react'
import SingleStoryImg from 'assets/single_story.svg'
import MultiStoriesImg from 'assets/multi_stories.svg'
import NumOfStoriesCard from 'components/NumOfStoriesCard'
import { PDetailsT, AddressT, DictT, PDetailTypeT } from 'model/types'
import AddressAutocomplete from 'components/AddressAutocomplete'
import { getAddress } from 'shared/utils/stringUtils'
import ProjectContext from 'contexts/ProjectContext'

const SettingsContent = () => {
  const { project, updateProject, detailsDict } = useContext(ProjectContext)
  const projectSizeInputRef = useRef<HTMLInputElement>(null)
  const projectSize = _.get(project, 'size')

  console.log('detailsDict', detailsDict)

  const projectPresetId = _.get(project, 'presetId')

  const stories: DictT<PDetailsT> = useMemo(() => {
    const res: DictT<PDetailsT> = {}
    _.forEach(detailsDict, (d: PDetailsT) => {
      if (d.type === PDetailTypeT.STORIES) {
        res[d.id] = d
      }
    })
    return res
  }, [detailsDict])

  const allDetails: DictT<PDetailsT> = useMemo(() => {
    const res: DictT<PDetailsT> = {}
    _.forEach(detailsDict, (d: PDetailsT) => {
      if (d.type === PDetailTypeT.DETAILS) {
        res[d.id] = d
      }
    })
    return res
  }, [detailsDict])

  const details: string[] = _.get(project, 'details', [])

  console.log('project details', details)

  const { singleStoryId, multiStoryId } = useMemo(() => {
    let ssId: string = ''
    let msId: string = ''
    _.forEach(stories, s => {
      if (s.name === 'Single story') {
        ssId = s.id
      } else {
        msId = s.id
      }
    })
    return { singleStoryId: ssId, multiStoryId: msId }
  }, [stories])
  console.log('details', details)

  const address = _.get(project, 'address')

  const updateAddress = (address: AddressT) => {
    console.log('updateAddress', address)
    updateProject({ address })
  }

  const projectStories: string = useMemo(() => {
    return _.filter(details, id => _.has(stories, id))[0]
  }, [details, stories])

  console.log('projectStories', projectStories)
  console.log('stories', stories)

  const addressInput = useMemo(() => {
    if (!_.isEmpty(projectPresetId)) {
      return (
        <VStack spacing={6} align='flex-start'>
          <Text fontWeight='semibold' fontSize={'large'}>
            Address
          </Text>
          <Flex direction={'row'} w='full'>
            <AddressAutocomplete value={getAddress(address)} onSelect={updateAddress} />
          </Flex>
        </VStack>
      )
    }
  }, [projectPresetId, address])

  const updateProjectSize = (v: string) => {
    updateProject({ size: v })
  }

  const sfInput = useMemo(() => {
    if (!_.isEmpty(address) && !_.isEmpty(projectPresetId)) {
      return (
        <VStack spacing={6} align='flex-start'>
          <Text fontWeight='semibold' fontSize={'large'}>
            Livable square feet
          </Text>
          <Input
            ref={projectSizeInputRef}
            value={projectSize || ''}
            onChange={e => updateProjectSize(e.target.value)}
            placeholder='Enter livable square feet  in this project'
            bg='white'
            onKeyPress={ev => (ev.key === 'Enter' ? projectSizeInputRef.current?.blur() : null)}
          />
        </VStack>
      )
    }
  }, [projectPresetId, address, projectSize])

  const updateStories = useCallback(
    (id: string) => {
      if (project) {
        console.log('update stories', id)
        console.log('update details', project.details)
        const newDetails = _.filter(project.details, id => !_.has(stories, id))
        console.log('newDetails', newDetails)
        updateProject({ details: _.uniq([...newDetails, id]) })
      }
    },
    [project, updateProject]
  )

  const numberOfStories = useMemo(() => {
    console.log('numberOfStories render', !_.isEmpty(address) && !_.isEmpty(projectSize))
    console.log('projectStories', projectStories)
    if (!_.isEmpty(address) && !_.isEmpty(projectSize)) {
      return (
        <VStack spacing={8} align={'flex-start'} px={8}>
          <Text fontSize={'lg'} fontWeight={'semibold'}>
            Number of stories
          </Text>
          <HStack spacing={4}>
            <NumOfStoriesCard
              image={<Image src={SingleStoryImg} boxSize='85px' objectFit='contain' />}
              title='Single'
              onClick={() => updateStories(singleStoryId)}
              isSelected={projectStories === singleStoryId}
            />
            <NumOfStoriesCard
              image={<Image src={MultiStoriesImg} boxSize='85px' objectFit={'contain'} />}
              title='Multi'
              onClick={() => updateStories(multiStoryId)}
              isSelected={projectStories === multiStoryId}
            />
          </HStack>
        </VStack>
      )
    }
  }, [projectPresetId, address, projectSize, stories, projectStories])

  const toggleDetail = (dId: string) => {
    if (_.includes(details, dId)) {
      updateProject({ details: _.filter(details, pd => pd !== dId) })
    } else {
      updateProject({ details: [...details, dId] })
    }
  }

  const renderDetails = useMemo(() => {
    if (!_.isEmpty(address) && !_.isEmpty(projectSize) && !_.isNil(projectStories)) {
      const detailsDict = _.keyBy(details)
      return (
        <VStack spacing={8} align={'flex-start'} px={8} w={'full'}>
          <Text fontSize={'lg'} fontWeight={'semibold'}>
            Details
          </Text>
          <Grid templateColumns='repeat(3, 1fr)' gap={4} w='100%'>
            {_.map(allDetails, d => {
              const selected = _.has(detailsDict, d.id)
              return (
                <GridItem w='100%' key={d.id}>
                  <Flex
                    direction='row'
                    align='center'
                    gap='sm'
                    w='100%'
                    borderColor={'green.500'}
                    borderRadius={'base'}
                    borderWidth={'1px'}
                    bgColor={selected ? 'green.50' : undefined}
                    onClick={v => toggleDetail(d.id)}
                    as='button'
                    _hover={{
                      bgColor: 'green.50'
                    }}
                  >
                    <Checkbox
                      ml='4'
                      my='4'
                      size='lg'
                      onChange={e => {
                        console.log('on chbx click', d, !e.target.checked)
                        e.stopPropagation()
                        toggleDetail(d.id)
                      }}
                      colorScheme={'green'}
                      isChecked={selected}
                    />
                    <Flex w='full' justify='center' direction={'row'}>
                      <Text textAlign={'center'}>{d.name}</Text>
                    </Flex>
                  </Flex>
                </GridItem>
              )
            })}
          </Grid>
        </VStack>
      )
    }
  }, [projectPresetId, address, projectSize, projectStories, details])

  return (
    <Flex maxW={'8xl'} flex={1} direction={'column'}>
      <VStack
        // w='full'
        // h='full'
        flex={1}
        spacing={8}
        justify={'flex-start'}
        overflowX={'hidden'}
        overflowY={'auto'}
        align={'flex-start'}
      >
        <Flex direction='row' w='full' h={'fit-content'} shrink={0} gap='md' px={8} py={4}>
          <Box w='50%'>{addressInput}</Box>
          <Box w='35%' pl={8}>
            {sfInput}
          </Box>
        </Flex>
        {numberOfStories}
        {renderDetails}
      </VStack>
    </Flex>
  )
}

export default SettingsContent
