import { FC, useContext } from 'react'
import { Box, VStack, Flex, Text, Skeleton } from '@chakra-ui/react'
import _ from 'lodash'
import ProjectContext from 'contexts/ProjectContext'

type ScopeBuilderMenuProps = {
  setCurrentTradeId: Function
}

const ScopeBuilderMenu: FC<ScopeBuilderMenuProps> = ({ setCurrentTradeId }) => {
  const { baseScope, baseNotes, tradesDict, isDataLoading } = useContext(ProjectContext)
  const tradesIds = _.sortBy(_.keys(baseScope), tradeId => _.get(tradesDict, tradeId))

  const renderItem = (tradeId: string, tradeName: string) => {
    return (
      <Box
        key={tradeId}
        w='full'
        _hover={{ bgColor: 'gray.200' }}
        py={2}
        px={4}
        as='button'
        onClick={() => setCurrentTradeId(tradeId)}
      >
        <Text textAlign={'start'} color={'gray.600'} fontSize={'sm'}>
          {tradeName}
        </Text>
      </Box>
    )
  }

  return (
    <Skeleton isLoaded={!isDataLoading}>
      <Flex direction={'column'} minW={48} h='full'>
        <Box flex={1} overflowX={'hidden'} overflowY={'auto'}>
          <VStack align={'flex-start'} spacing={0}>
            {!_.isEmpty(baseNotes) && renderItem('general_notes', 'General notes')}
            {_.map(tradesIds, tradeId => {
              return renderItem(tradeId, _.get(tradesDict, tradeId))
            })}
          </VStack>
        </Box>
      </Flex>
    </Skeleton>
  )
}

export default ScopeBuilderMenu
