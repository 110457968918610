import * as types from 'model/actionTypes'
import { DictT, UserProfileT } from 'model/types'

export function receiveProfile (userId: string, profile: UserProfileT) {
  return {
    type: types.RECEIVE_PROFILE,
    userId,
    profile
  }
}

export function receiveProfiles (profiles: DictT<UserProfileT>) {
  return {
    type: types.RECEIVE_PROFILES,
    profiles
  }
}

// export function receiveMasonAdmins (masonAdmins) {
//   return {
//     type: types.RECEIVE_MASON_ADMINS,
//     masonAdmins
//   }
// }
