export default [
  '#CFDFFF',
  '#D0F0FD',
  '#C2F5D9',
  '#D1F7C4',
  '#FFEAB6',
  '#FEE2D5',
  '#FFDCE5',
  '#FFDAF6',
  '#EDE2FE',
  '#EEEEEE'
]
