import _ from 'lodash'
import { Unsubscribe } from 'firebase/firestore'
import { UserT, DictT } from 'model/types'

let listeners: DictT<Unsubscribe> = {}

export let existingUser: UserT | undefined

export const setExistingUser = (user: UserT | undefined) => {
  existingUser = user || undefined
}

export function addListener (key: string, l: Unsubscribe) {
  const existingUnsubscribe = _.get(listeners, key)
  if (existingUnsubscribe) existingUnsubscribe()
  listeners[key] = l
}

export function hasListener (key: string) {
  return _.has(listeners, key)
}

export function clearListeners () {
  console.log('clear listeners:')
  setExistingUser(undefined)
  for (const key in listeners) {
    console.log('clear listener', key)
    const l = listeners[key]
    // console.log('off:', l)
    // l.off()
    l()
  }

  listeners = {}
}

export const offListener = (key: string) => {
  const l = _.get(listeners, key)
  if (l) {
    // l.off()
    l()
    delete listeners[key]
  }
}

let userUnsubscribe: Unsubscribe | undefined

export function setUserUnsubscribe (unsubscribe: Unsubscribe) {
  userUnsubscribe = unsubscribe
}

export function clearUserListener () {
  if (userUnsubscribe) userUnsubscribe()
}
