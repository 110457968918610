import { storage } from 'controllers/db'
import { ref, getDownloadURL, getMetadata, uploadBytesResumable } from 'firebase/storage'
// import * as Sentry from '@sentry/react'
import { saveAs } from 'file-saver'
import _ from 'lodash'

export type StorageFileT = {
  url: string
  size: number
  contentType?: string
}

// the method receives local url
export async function saveFile (storagePath: string, fileUrl: string, onProgress?: (v: number) => void) {
  try {
    console.log('saveFile', storagePath, fileUrl)
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        console.log('saveFile error', e)
        // Sentry.captureException(e)
        reject(new TypeError('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', fileUrl, true)
      xhr.send(null)
    })
    // TODO: test it
    const storageRef = ref(storage, storagePath)
    console.log('storage ref', storageRef)
    const uploadTask = uploadBytesResumable(storageRef, blob)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
    }
    const snapshot = await uploadTask
    console.log('totalBytes', snapshot.metadata.size)
    console.log('getDownloadUrl')
    const url = await getDownloadURL(storageRef)
    console.log('download url is', url)
    const sf: StorageFileT = {
      url,
      size: snapshot.metadata.size,
      contentType: snapshot.metadata.contentType
    }
    console.log('sf', sf)
    return sf
  } catch (error) {
    console.log(error)
  }
}

// export async function saveImageBase64DataUrl (storagePath: string, base64Data, onProgress = () => null) {
//   const url = await saveFile(storagePath, base64Data, onProgress)
//   return url
// }

export async function getContentType (url: string) {
  const storagePath = url
    .split('o/')[1]
    .split('?alt')[0]
    .split('%2F')
    .join('/')
  const storageRef = ref(storage, storagePath)
  const { contentType } = await getMetadata(storageRef)
  return contentType
}

export const saveFileObject = async (
  fileObject: File | Blob | Uint8Array | ArrayBuffer,
  storagePath: string,
  onProgress?: (v: number) => void
) => {
  try {
    console.log('saveFileObject', fileObject, storagePath)
    const storageRef = ref(storage, storagePath)
    console.log('storageRef', storageRef)
    const uploadTask = uploadBytesResumable(storageRef, fileObject)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
    }
    const snapshot = await uploadTask
    console.log('snapshot', snapshot)
    console.log('totalBytes', snapshot.metadata.size)

    console.log('totalBytes', snapshot.metadata.size)
    console.log('getDownloadUrl')
    const url = await getDownloadURL(storageRef)
    console.log('download url is', url)
    const sf: StorageFileT = {
      url,
      size: snapshot.metadata.size,
      contentType: snapshot.metadata.contentType
    }
    console.log('sf', sf)
    return sf
  } catch (e) {
    console.log('saveFileObject error: ', e)
  }
}

export const deleteFile = async (storagePath: String) => {
  console.log('deleteFile: the file will be not realy deleted', storagePath)
}

export const downloadFile = async (path: string, name: string) => {
  try {
    const request = await window.fetch(path)
    const response = await request.blob()
    const status = _.get(request, 'status')
    if (status === 200) {
      saveAs(response, name)
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log('downloadFile error', error)
  }
}

export default {
  saveFile,
  // saveImageBase64DataUrl,
  downloadFile,
  getContentType,
  saveFileObject,
  deleteFile
}
