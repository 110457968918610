import { useState } from 'react'
import { Text, Button, Box, Heading, Flex, HStack, PinInput, PinInputField } from '@chakra-ui/react'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { checkVerificationCode, requestSmsCode } from 'controllers/auth'
import { ReactComponent as Logo } from 'assets/masonLogoOnLight.svg'
import { ReactComponent as SignInLogo } from 'assets/signin_logo.svg'

const EnterCode = () => {
  const [loading, setLoading] = useState(false)
  const [codeError, setCodeError] = useState(null)
  const navigate = useNavigate()
  const { phone } = useParams()
  const phoneNumber = parsePhoneNumberFromString(phone || '')

  const onVerificationRes = (res: any) => {
    const er = _.get(res, 'error')
    const resend = _.get(res, 'resend', false)
    console.log('onVerification res resend', resend)
    if (er) {
      setCodeError(er)
      setLoading(false)
      if (resend) {
        goBack()
      }
    }
  }

  const handleSubmit = async (code: string) => {
    console.log('handle submit', code)
    try {
      if (phone) {
        setLoading(true)
        const res = await checkVerificationCode(phone, code)
        onVerificationRes(res)
      }
    } catch (e) {
      console.log('EnterCode, onSubmit, error:', e)
      setLoading(false)
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  const onResponse = () => {
    setLoading(false)
  }

  const handleResendCode = async () => {
    try {
      if (phone) {
        setCodeError(null)
        setLoading(true)
        await requestSmsCode(phone)
        onResponse()
      }
    } catch (e) {
      onResponse()
      console.log('requestSmsCode error', e)
    }
  }

  const renderError = () => {
    if (!_.isEmpty(codeError)) {
      return (
        <Text mt={4} fontSize={'sm'} color={'red.500'}>
          {codeError}
        </Text>
      )
    }
  }

  return (
    <Box p={22}>
      <Flex direction='row' w='full' justify='space-between' align='center' mb={12} margin={{ bottom: 'medium' }}>
        <Logo height='32px' />
      </Flex>
      <Box p={8}>
        <Flex align='center' direction='column'>
          <SignInLogo />
          <Heading color={'blue.600'} mt={6} textAlign='center' fontWeight={'semibold'}>
            Enter validation code
          </Heading>

          <Text mt={6} color={'gray.400'} fontSize={'sm'} textAlign='center'>
            Validation code was sent to your number
          </Text>
          <Button
            variant={'link'}
            leftIcon={<FontAwesomeIcon icon={faPencil} color={'gray.400'} fontSize={12} />}
            onClick={goBack}
            mb={12}
          >
            <Text color={'gray.400'}>{phoneNumber?.formatNational()}</Text>
          </Button>
          <HStack>
            <PinInput
              otp
              onComplete={handleSubmit}
              isDisabled={loading}
              size='lg'
              errorBorderColor='red.500'
              isInvalid={!_.isEmpty(codeError)}
              type='number'
            >
              <PinInputField onFocus={() => setCodeError(null)} />
              <PinInputField onFocus={() => setCodeError(null)} />
              <PinInputField onFocus={() => setCodeError(null)} />
              <PinInputField onFocus={() => setCodeError(null)} />
            </PinInput>
          </HStack>
          {renderError()}
          <Button variant={'link'} onClick={handleResendCode} mt={12}>
            <Text color={'gray.400'} fontSize={'sm'}>
              <u>{"Haven't received a code? Resend"}</u>
            </Text>
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default EnterCode
