import { useState } from 'react'
import { useNavigate } from 'react-router'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import _ from 'lodash'
import { Box, Text } from '@chakra-ui/react'

import { requestSmsCode } from 'controllers/auth'
import AuthLayout from 'pages/auth/AuthLayout'

const FIRST_PART_LENGTH = 7
const PHONE_NUMBER_LENGTH = 14

const EnterPhone = () => {
  const [phone, setPhone] = useState('')
  const [isValid, setValid] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const navigate = useNavigate()

  const onResponse = (phone: string, isSent: boolean) => {
    console.log('onResponse', phone, isSent)
    if (isSent) {
      navigate(`/auth/enter-code/${phone}`)
    } else {
      setErrorMessage('We could not sent SMS to the phone number')
    }
  }

  const onSubmit = async () => {
    try {
      console.log('onSubmit', phone)
      setLoading(true)
      const phoneNumber = parsePhoneNumberFromString(phone, 'US')
      if (phoneNumber) {
        const phoneNum = phoneNumber.number
        const isSent = await requestSmsCode(phoneNum)
        setLoading(false)
        onResponse(phoneNum, isSent)
      }
    } catch (e) {
      setLoading(false)
      console.log('requestSmsCode error', e)
    }
  }

  const onPhoneChange = async (v: string) => {
    if (v && v.length <= FIRST_PART_LENGTH) {
      setPhone(v)
      if (!_.isNil(errorMessage)) {
        setErrorMessage(null)
      }
    } else if (v.length <= PHONE_NUMBER_LENGTH) {
      const aYT = new AsYouType('US')
      const formattedPhone = aYT.input(v)
      const phoneNumber = aYT.getNumber()
      const valid = !_.isNil(phoneNumber) && phoneNumber.isValid()
      setPhone(formattedPhone)
      setValid(valid)
      setErrorMessage(null)
    }
  }

  const renderError = () => {
    return (
      <Box w='md'>
        <Text mt={2} mb={4} color={'red.600'}>
          {errorMessage}
        </Text>
      </Box>
    )
  }

  const buttonDisabled = !isValid || isLoading
  return (
    <AuthLayout
      header={'Sign in to your Mason account'}
      subheader='We will text you a verification code to complete your registration'
      error={renderError()}
      isError={!isValid && !_.isEqual(phone, '')}
      valueName='Phone number'
      value={phone}
      placeholder='(500) 555 0000'
      handleChange={onPhoneChange}
      handleSubmit={onSubmit}
      isValid={!buttonDisabled}
      submitName={'Log in'}
      isLoading={isLoading}
    />
  )
}

export default EnterPhone
