import { useContext, useMemo } from 'react'
import _ from 'lodash'
import { Heading, Flex, Button } from '@chakra-ui/react'
import { faAnglesLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

import { ProjectPresetT } from 'model/types'
import ProjectTypeCard from 'components/ProjectTypeCard'
import ProjectContext from 'contexts/ProjectContext'

const SettingsMenu = () => {
  const navigate = useNavigate()
  const { project, updateProject, presets } = useContext(ProjectContext)
  const projectPresetId = _.get(project, 'presetId')

  const changeProjectType = (preset: ProjectPresetT) => {
    updateProject({ details: preset.details, presetId: preset.id })
  }

  const sortedPresets = useMemo(() => {
    return _.sortBy(presets, 'order')
  }, [presets])

  return (
    <Flex direction={'column'} bg='white' w='sm' h='full' shrink={0}>
      <Flex direction={'row'} align='center'>
        <Button gap='2' variant={'ghost'} color='gray.600' onClick={() => navigate('/')}>
          <FontAwesomeIcon icon={faAnglesLeft} />
        </Button>
        <Heading size='md' px='4' py='4'>
          Project type
        </Heading>
      </Flex>
      <Flex direction='column' spacing={6} overflowX={'hidden'} overflowY={'auto'} h='100%'>
        {_.map(sortedPresets, pr => {
          return (
            <ProjectTypeCard
              key={pr.id}
              title={pr.name}
              desc={pr.notes}
              isSelected={pr.id === projectPresetId}
              onClick={() => changeProjectType(pr)}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default SettingsMenu
