import { useRef, useState, FC, ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { Flex, Button, Progress } from '@chakra-ui/react'
import _ from 'lodash'

// import ProgressBar from 'shared/components/ProgressBar'
import { saveFileObject, StorageFileT } from 'controllers/storage'
import { generateId } from 'controllers/db'
import { FileT } from 'model/types'

type Props = {
  onComplete: (files: FileT[]) => void
  storagePath: string
  disabled?: boolean
  inputProps?: object
  userId: string
}

const FilesPickerButton: FC<Props> = ({ onComplete, storagePath, disabled = false, inputProps = {}, userId }) => {
  const filePickerRef = useRef<HTMLInputElement>(null)
  const [progress, setProgress] = useState<number | undefined>()
  const [inputKey, setInputKey] = useState(_.now())
  const [cancelled, setCancelled] = useState(false)

  const openFilePicker = () => {
    setCancelled(false)
    filePickerRef?.current?.click()
  }

  // const deleteFiles = files => {
  //   // _.forEach(files, f => {
  //   //   const p = `${storagePath}/${f.id}`
  //   //   deleteFile(p)
  //   // })
  // }

  const onFilesPicked = async (event: ChangeEvent) => {
    const upl: File[] = _.values(_.get(event, 'target.files'))
    console.log('onFilesPicked', upl)
    const count = _.size(upl)
    const res: FileT[] = []
    for (const i in upl) {
      const fileRaw = upl[i]
      if (cancelled) return
      console.log(i, 'fileRaw ===>', fileRaw)
      const id = generateId()
      const p = `${storagePath}/${id}`

      const onProgress = (v: number) => {
        console.log('onProgress', v)
        setProgress(_.ceil(v / count + (Number(i) / count) * 100))
      }
      const fileInfo: StorageFileT | undefined = await saveFileObject(fileRaw, p, onProgress)
      console.log('onFilesPicked: fileInfo', fileInfo)
      if (fileInfo) {
        const pFile: FileT = {
          id,
          createdAt: _.now(),
          name: _.get(fileRaw, 'name'),
          createdBy: userId,
          ...fileInfo
        }
        console.log('push file to list', pFile)
        res.push(pFile)
      }
    }

    console.log('%cres', 'color:green', res)
    setInputKey(_.now())

    setProgress(undefined)
    if (cancelled) {
      // deleteFiles(res)
    } else {
      onComplete(res)
    }
  }

  // const renderProgress = () => {
  //   if (!_.isNil(progress)) {
  //     return <Progress value={progress} isIndeterminate={progress === 0} />
  //     // return <ProgressBar progress={progress} onCancel={() => setCancelled(true)} />
  //     // return <Text>progress {progress}</Text>
  //   } else {
  //     return null
  //   }
  // }

  const renderUploadFileButtonSecondary = () => {
    if (!_.isNil(progress)) {
      return <Progress w={32} size={'sm'} value={progress} isIndeterminate={progress === 0} />
    } else {
      return (
        <Button
          leftIcon={<FontAwesomeIcon icon={faPaperclip} />}
          size='sm'
          onClick={openFilePicker}
          disabled={disabled}
        >
          Upload file
        </Button>
        // </ThemeContext.Extend>
      )
    }
  }

  return (
    <Flex h={8} align='center'>
      {renderUploadFileButtonSecondary()}
      <input
        key={inputKey}
        ref={filePickerRef}
        id='file-input'
        onChange={onFilesPicked}
        type='file'
        style={{ display: 'none' }}
        multiple
        {...inputProps}
      />
    </Flex>
  )
}

export default FilesPickerButton
