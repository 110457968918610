import { FC } from 'react'
import _ from 'lodash'
import { Flex, VStack, Text, IconButton, useToast, Divider } from '@chakra-ui/react'
import { Select, OptionBase, GroupBase, MultiValue } from 'chakra-react-select'
import moment from 'moment'
import numeral from 'numeral'

import { DictT, FileT, TradesDictT } from 'model/types'
import FilesPickerButton from 'components/FilesPickerButton'
import { toTimestamp, stringDiff } from 'shared/utils/date'
import { downloadFile, deleteFile } from 'controllers/storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  files: DictT<FileT>
  setFiles: (files: DictT<FileT>) => void
  storagePath: string
  userId: string
  tradesDict: TradesDictT
  tradesIds: string[]
}

interface TradeOptionT extends OptionBase {
  label: string
  value: string
}

const ProjectFiles: FC<Props> = ({ files, setFiles, storagePath, userId, tradesDict, tradesIds }) => {
  const toast = useToast()

  // const tradesIds = _.sortBy(_.keys(tradesDict), tradeId => tradesDict[tradeId])

  const onComplete = (uploadedFiles: FileT[]) => {
    console.log('onComplete', uploadedFiles)
    const newFiles = {
      ...files,
      ..._.keyBy(uploadedFiles, 'id')
    }
    setFiles(newFiles)
  }

  // console.log('Project files', files)

  const handleDownload = async (f: FileT) => {
    const res = await downloadFile(f.url, f.name)
    if (!res) {
      toast({
        title: 'Error',
        description: 'File not found',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  const renderDownloadButton = (f: FileT) => {
    return (
      <IconButton
        size='sm'
        aria-label='Download file'
        variant='ghost'
        icon={<FontAwesomeIcon icon={faCloudDownloadAlt} />}
        onClick={() => handleDownload(f)}
      />
    )
  }

  const onDeleteFilePress = (fId: string) => {
    const p = `${storagePath}/${fId}`
    deleteFile(p)
    const newFiles = { ...files }
    _.unset(newFiles, fId)
    setFiles(newFiles)
  }

  const renderTrashButton = (f: FileT) => {
    return (
      <IconButton
        size='sm'
        aria-label='Delete file'
        variant='ghost'
        icon={<FontAwesomeIcon icon={faTrashAlt} />}
        onClick={() => onDeleteFilePress(f.id)}
      />
    )
  }

  // const onChangeTrade = (f: FileT, e: ChangeEvent<HTMLSelectElement>) => {
  //   const tradeId = e.target.value
  //   console.log('onChangeTrade', f, e)
  //   console.log('target value', e.target.value)
  //   const newFiles = { ...files }
  //   if (tradeId) {
  //     _.set(newFiles, [f.id, 'tradeId'], tradeId)
  //   } else {
  //     _.unset(newFiles, [f.id, 'tradeId'])
  //   }
  //   setFiles(newFiles)
  // }

  // const renderSelectTrade = (f: FileT) => {
  //   return (
  //     <Select placeholder='any trade' size='xs' value={f.tradeId} onChange={e => onChangeTrade(f, e)}>
  //       {_.map(tradesIds, tradeId => {
  //         return (
  //           <option key={tradeId} value={tradeId}>
  //             {tradesDict[tradeId]}
  //           </option>
  //         )
  //       })}
  //     </Select>
  //   )
  // }

  // const buttonName = (trades: string[] = []) => {
  //   switch (trades.length) {
  //     case 0:
  //       return 'All trades'
  //     case 1:
  //       return `${trades[0]}`
  //     default:
  //       return `${trades[0]} and ${trades.length - 1} others`
  //   }
  // }

  // const renderSelectTrade = (f: FileT) => {
  //   const trades = f.trades || []
  //   return (
  //     <Menu closeOnSelect={false} key={f.id}>
  //       <MenuButton size='xs' color='blue.300' as={Button} colorScheme='blue' variant='unstyled'>
  //         <HStack>
  //           <Text>{buttonName(trades)}</Text>
  //           <FontAwesomeIcon icon={faChevronDown} />
  //         </HStack>
  //       </MenuButton>
  //       <MenuList size='sm' minWidth='240px' h={'lg'} overflowY='scroll'>
  //         {/* <VStack overflowY='auto' h='full'> */}
  //         {_.map(tradesIds, tradeId => {
  //           return (
  //             <MenuItem key={tradeId} value={tradeId}>
  //               {tradesDict[tradeId]}
  //             </MenuItem>
  //           )
  //         })}
  //         {/* </VStack> */}
  //       </MenuList>
  //     </Menu>
  //   )
  // }

  const onChangeTrades = (f: FileT, options: MultiValue<TradeOptionT>) => {
    const newTrades = _.intersection(
      _.map(options, o => o.value),
      tradesIds
    )
    const newFiles = { ...files }
    if (!_.isEmpty(newTrades)) {
      _.set(newFiles, [f.id, 'trades'], newTrades)
    } else {
      _.unset(newFiles, [f.id, 'trades'])
    }
    setFiles(newFiles)
  }

  const renderSelectTrade = (f: FileT) => {
    const trades = f.trades || []
    const value = _.map(trades, tradeId => ({ value: tradeId, label: tradesDict[tradeId] }))
    const options = _.map(tradesIds, tradeId => ({ value: tradeId, label: tradesDict[tradeId] }))
    return (
      <Select<TradeOptionT, true, GroupBase<TradeOptionT>>
        size='sm'
        isMulti
        closeMenuOnSelect={false}
        selectedOptionStyle='check'
        hideSelectedOptions={false}
        value={value}
        options={options}
        placeholder='Add to all trades'
        onChange={v => onChangeTrades(f, v)}
      />
    )
  }

  const renderFiles = () => {
    const sortedFiles = _.sortBy(files, f => toTimestamp(_.get(f, 'createdAt')))
    return (
      <VStack divider={<Divider />} w='full'>
        {_.map(sortedFiles, f => {
          return (
            <Flex direction='row' key={f.id} align='center' w='full'>
              <Flex flex={3} direction='column' pb={1}>
                <Text fontSize='sm' pb={1}>
                  {f.name}
                </Text>
                {renderSelectTrade(f)}
              </Flex>
              {/* <Flex flex={1} mx={2} justify='center'>

              </Flex> */}
              <Flex flex={1} mx={2} justify='center'>
                <Text textAlign={'center'} fontSize='xs' color={'gray.400'}>{`uploaded ${stringDiff(
                  moment(),
                  moment(f.createdAt)
                )}`}</Text>
              </Flex>
              <Flex mx={1} justify='center' flex={0.5}>
                <Text fontSize='xs'>{numeral(f.size).format('0.0 b')}</Text>
              </Flex>
              <Flex w={8}>{renderDownloadButton(f)}</Flex>
              <Flex w={8}>{renderTrashButton(f)}</Flex>
            </Flex>
          )
        })}
      </VStack>
    )
  }

  return (
    <VStack spacing={4}>
      {renderFiles()}
      <Flex w='full'>
        <FilesPickerButton userId={userId} storagePath={storagePath} onComplete={onComplete} />
      </Flex>
    </VStack>
  )
}

export default ProjectFiles
