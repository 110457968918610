import { useMemo, useContext, FC } from 'react'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'
// import ReportEditorTradesTable from 'pages/report/reportEditorContent/ReportEditorTradesTable'
import ReportEditorTable from 'pages/report/reportEditorContent/ReportEditorTable'
import AddTableButton from 'pages/report/reportEditorContent/AddTableButton'

import { generateId } from 'shared/utils/stringUtils'
import { DictT, SectionT, TotalRowT } from 'model/types'
import ReportEditorExlusionsTable from 'pages/report/reportEditorContent/ReportEditorExclusionsTable'
import ReportEditorTotalsTable from 'pages/report/reportEditorContent/ReportEditorTotalsTable'
import ReportsEditorScopeOfWork from 'pages/report/reportEditorContent/ReportsEditorScopeOfWork'
import ProjectContext from 'contexts/ProjectContext'
import { EXCLUSIONS_SECTION_ID, TRADE_SECTION_ID } from 'constants/reportSections'
import { arrayMove } from 'shared/utils/array'

type Props = {
  openEditDetailsModal: () => void
}

const ReportEditorContent: FC<Props> = ({ openEditDetailsModal }) => {
  const {
    // projectScope,
    // tradesDict,
    project,
    sectionsOrder,
    setSectionsOrder,
    sections,
    setSections,
    scopeHidden,
    setScopeHidden,
    totalsItems,
    setTotalsItems,
    costPlus,
    setCostPlus,
    baseTradesRows
  } = useContext(ProjectContext)

  console.log('sections', sections)
  console.log('totalsItems', totalsItems)
  console.log('sectionsOrder', sectionsOrder)
  console.log('baseTradesRows', baseTradesRows)

  const addNewTable = (name: string) => {
    const sectionId = generateId()
    const section: SectionT = {
      id: sectionId,
      name: _.upperCase(name),
      rows: {}
    }
    setSections({ ...sections, [sectionId]: section })
    setSectionsOrder([...sectionsOrder, sectionId])
  }

  const updateSection = (section: SectionT) => {
    setSections({ ...sections, [section.id]: section })
  }

  const subtotal = useMemo(() => {
    let res: number = 0
    _.forEach(sections, (s, sectionId) => {
      _.forEach(s.rows, row => {
        if (!_.isNil(row.total)) {
          res += row.total
        } else {
          res += row.materials || 0
          res += row.labor || 0
        }
      })
    })
    return res
  }, [sections])

  const updateTotalsItems = (newTotalItems: DictT<TotalRowT>) => {
    console.log('updateTotalsItems', newTotalItems)
    setTotalsItems(newTotalItems)
  }

  const scopeOfWorkList = useMemo(() => {
    return <ReportsEditorScopeOfWork scopeHidden={scopeHidden} setScopeHidden={v => setScopeHidden(v)} />
  }, [scopeHidden])

  const onResetRemoveClick = (sectionId: string) => {
    console.log('onResetRemoveClick', sectionId)
    if (sectionId === TRADE_SECTION_ID) {
      console.log('reset trades section, baseTradesRows', baseTradesRows)
      const newTradeSection = {
        id: TRADE_SECTION_ID,
        rows: { ...baseTradesRows },
        name: 'TRADES'
      }
      const newSections = { ...sections, [sectionId]: newTradeSection }
      console.log('set newSections', newSections)
      setSections(newSections)
    } else {
      console.log('remove section')
      const newSections = { ...sections }
      _.unset(newSections, sectionId)
      const newSectionsOrder = _.filter(sectionsOrder, sId => sId !== sectionId)
      setSectionsOrder(newSectionsOrder)
      setSections(newSections)
    }
  }

  const moveSection = (fromIndex: number, toIndex: number) => {
    const newSectionsOrder = arrayMove(sectionsOrder, fromIndex, toIndex)
    setSectionsOrder(newSectionsOrder)
  }

  if (_.isEmpty(sections)) return null
  return (
    <Box w='full' h='100%' bgColor={'gray.50'} overflowX={'hidden'} overflowY={'scroll'} pb={32}>
      <Box pb='8'>
        {_.map(sectionsOrder, (sectionId, index) => {
          const s = _.get(sections, sectionId)
          if (sectionId !== EXCLUSIONS_SECTION_ID && s) {
            return (
              <ReportEditorTable
                key={sectionId}
                section={s}
                updateSection={updateSection}
                costPlus={costPlus}
                setCostPlus={setCostPlus}
                showCostPlus={index === 0}
                participantDetails={project?.participantDetails}
                openEditDetailsModal={openEditDetailsModal}
                index={index}
                isFirst={index === 0}
                isLast={index === _.size(sectionsOrder) - 1}
                onResetRemoveClick={() => onResetRemoveClick(sectionId)}
                moveSection={moveSection}
                baseTradesRows={baseTradesRows}
              />
            )
          }
        })}
      </Box>
      <AddTableButton apply={addNewTable} />
      <ReportEditorExlusionsTable section={_.get(sections, EXCLUSIONS_SECTION_ID)} updateSection={updateSection} />
      <ReportEditorTotalsTable
        subtotal={subtotal}
        totalsItems={totalsItems}
        setTotalsItems={updateTotalsItems}
        costPlus={costPlus}
        setCostPlus={v => setCostPlus(v)}
      />
      {scopeOfWorkList}
    </Box>
  )
}

export default ReportEditorContent
