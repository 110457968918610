import { ChangeEvent, ChangeEventHandler, useState, FC, useEffect } from 'react'
import numeral from 'numeral'
import _ from 'lodash'
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  InputGroup,
  InputRightElement,
  HStack,
  Input,
  Portal,
  ButtonProps
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPercent } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  value?: number
  apply: (perc: number | undefined) => void
  buttonProps?: ButtonProps
  buttonTitle?: string
}

const CostPlusButton: FC<Props> = ({ value, apply, buttonProps = {}, buttonTitle = 'Add Cost Plus' }) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(!isOpen)
  const [v, setV] = useState(_.toString(value))
  const close = () => {
    setV('0')
    setIsOpen(false)
  }

  useEffect(() => {
    setV(_.toString(value))
  }, [value])

  const onValueChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const valueRaw = event.target.value.replace(/[^0-9.,-]/g, '')
    const newValue = valueRaw.replace(/[^0-9.,-]/g, '')
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(v).value()
      if (newV === curV || _.isNil(v)) {
        setV(valueRaw)
      } else {
        setV(numeral(newValue).format('0,0.[00]'))
      }
    } else {
      setV('')
    }
  }

  const onUpdateClick = () => {
    const cost = _.size(v) > 0 ? numeral(v).value() : null
    if (!_.isNil(cost)) {
      apply(cost)
    } else {
      apply(undefined)
    }
    close()
  }

  return (
    <Popover isLazy isOpen={isOpen} onClose={close}>
      <PopoverTrigger>
        <Button variant={'outline'} bgColor={'white'} size='sm' onClick={() => open()} {...buttonProps}>
          {buttonTitle}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverHeader fontWeight='semibold'>Cost plus</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody pt={10}>
            <InputGroup>
              <Input value={v} onChange={onValueChange} />
              <InputRightElement>
                <FontAwesomeIcon icon={faPercent} />
              </InputRightElement>
            </InputGroup>
            <HStack pt={8} pb={4} justifyContent={'flex-end'} spacing={6}>
              <Button variant={'outline'} onClick={close}>
                Cancel
              </Button>
              <Button colorScheme={'teal'} onClick={onUpdateClick}>
                Update
              </Button>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default CostPlusButton
