import _ from 'lodash'
import { doc, getDoc } from 'firebase/firestore'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { signInWithCustomToken, signOut as fbSignOut } from 'firebase/auth'

import { db, auth } from 'controllers/db'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import { NavigateFunction } from 'react-router-dom'

export const userIdByPhone = async (phone: string) => {
  const docRef = doc(db, 'uidByPhone', phone)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const uidByPhone = docSnap.data()
    return uidByPhone.uid
  } else {
    return null
  }
}

export const requestSmsCode = async (phone: string) => {
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')
  if (phoneNumber) {
    const phoneNum = phoneNumber.number
    try {
      const body = {
        phone: phoneNum
      }
      const url = `${config.dynamicUrl}/proto/requestSmsCode`
      const response = await window.fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify(body)
      })
      const answer = await response.json()
      console.log('requestSmsCode', answer)
      return _.get(answer, 'sent', false)
    } catch (e) {
      if (typeof e === 'string') {
        console.log('requestSmsCode error:', e.toUpperCase())
      } else if (e instanceof Error) {
        console.log('requestSmsCode error:', e.message)
      }
    }
  }
}

export const signIn = async (authToken: string) => {
  await signInWithCustomToken(auth, authToken)
}

export const signOut = async (navigate: NavigateFunction) => {
  try {
    if (auth && auth.currentUser) {
      // trackEvent('logout')
      // clearUserListener()
      // clearListeners()
      // Sentry.setUser()
      await fbSignOut(auth)
    } else {
      navigate('/auth')
    }
  } catch (e) {
    // Sentry.captureException(e)
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
  }
}

export async function checkVerificationCode (phone: string, code: string, email?: string, name?: string) {
  const body = {
    phone,
    code,
    email,
    name
  }
  console.log('checkVerificationCode', body)
  const url = `${config.dynamicUrl}/proto/checkVerificationCode`
  const response = await window.fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const answer = await response.json()
  console.log('checkVerificationCode answer', answer)
  const authToken = _.get(answer, 'authToken')
  if (authToken) {
    await signIn(authToken)
  }
  return answer
}
