import * as types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { User } from 'firebase/auth'

const initialState: User | null = null

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_AUTH_DATA:
      return action.authData
    default:
      return state
  }
}
