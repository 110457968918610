import { FC, useMemo } from 'react'
import { Document, Page, Font, Text } from '@react-pdf/renderer'
import _ from 'lodash'
import ScopeReportHeader from 'components/scopeReport/ScopeReportHeader'
import {
  AccountProfileT,
  ProjectT,
  DictT,
  SectionT,
  TotalRowT,
  BaseScopeT,
  BaseNotesT,
  ScopeSectionDictT
} from 'model/types'
import styles from 'components/scopeReport/styles'
import PDFViewer from 'components/PdfViewer'
import { EXCLUSIONS_SECTION_ID } from 'constants/reportSections'
import ScopeReportTable from 'components/scopeReport/ScopeReportTable'
import ScopeReportExclusions from 'components/scopeReport/ScopeReportExclusions'
import ScopeReportTotals from 'components/scopeReport/ScopeReportTotals'
import ReportScopeOfWork from 'components/scopeReport/ReportScopeOfWork'

Font.register({
  family: 'Lato',
  fonts: [
    { src: '/fonts/latoregular.ttf' }, // font-style: normal, font-weight: normal
    // { src: source2, fontStyle: 'italic' },
    { src: '/fonts/latosemibold.ttf', fontWeight: 600 },
    { src: '/fonts/latobold.ttf', fontWeight: 700 }
  ]
})

type Props = {
  accountProfile: AccountProfileT
  project: ProjectT
  sections: DictT<SectionT>
  sectionsOrder: string[]
  scopeHidden: boolean
  totalsItems: DictT<TotalRowT>
  costPlus?: number
  tradesDict: DictT<string>
  projectScope: BaseScopeT
  projectNotes: BaseNotesT
  scopeSections: DictT<ScopeSectionDictT>
  excludedScopeItemsDict: DictT<string>
  onBlob?: (blob: Blob) => any
  fileName: string
}

const ScopeReport: FC<Props> = ({
  accountProfile,
  project,
  sections,
  sectionsOrder,
  scopeHidden,
  totalsItems,
  costPlus,
  tradesDict,
  projectScope,
  projectNotes,
  scopeSections,
  excludedScopeItemsDict,
  onBlob,
  fileName
}) => {
  const exclusionsSection = _.find(sections, s => s.id === EXCLUSIONS_SECTION_ID)
  const exclusionsRowsAmount = _.size(exclusionsSection?.rows)

  const subtotal = useMemo(() => {
    let res: number = 0
    _.forEach(sections, (s, sectionId) => {
      _.forEach(s.rows, row => {
        if (!_.isNil(row.total)) {
          res += row.total
        } else {
          res += row.materials || 0
          res += row.labor || 0
        }
      })
    })
    return res
  }, [sections])

  const renderScopeOfWork = () => {
    if (!scopeHidden) {
      return (
        <ReportScopeOfWork
          tradesDict={tradesDict}
          projectScope={projectScope}
          projectNotes={projectNotes}
          scopeSections={scopeSections}
          excludedScopeItemsDict={excludedScopeItemsDict}
        />
      )
    }
  }

  return (
    <PDFViewer width='100%' height='100%' title='some file name?' onBlob={onBlob}>
      <Document title={fileName}>
        <Page size='A4' style={{ flexDirection: 'column', backgroundColor: '#ffffff', padding: 22 }} wrap>
          <ScopeReportHeader accountProfile={accountProfile} project={project} />
          <Text style={{ ...styles.textLarge, paddingTop: 32, textAlign: 'center', fontWeight: 'bold' }}>
            BUDGET SUMMARY
          </Text>
          {_.map(sectionsOrder, sectionId => {
            const s = _.get(sections, sectionId)
            if (sectionId !== EXCLUSIONS_SECTION_ID && s) {
              return <ScopeReportTable key={sectionId} section={s} />
            }
          })}
          {exclusionsSection && exclusionsRowsAmount > 0 && <ScopeReportExclusions section={exclusionsSection} />}
          <ScopeReportTotals subtotal={subtotal} totalsItems={totalsItems} costPlus={costPlus} />
          {renderScopeOfWork()}
        </Page>
      </Document>
    </PDFViewer>
  )

  // return (
  //   <PdfViewer>
  //     <Document>
  //       <Page size='A4' style={{ flexDirection: 'column', backgroundColor: '#ffffff' }}>
  //         <ScopeReportHeader accountProfile={accountProfile} project={project} />
  //         <Text style={{ paddingTop: 50 }}>asdd</Text>
  //       </Page>
  //     </Document>
  //   </PdfViewer>
  // )
}

export default ScopeReport
