import { useMemo } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import _ from 'lodash'

import ScopeBuilder from 'pages/ScopeBuilder'
import ProjectSettings from 'pages/ProjectSettings'
import UploadFiles from 'pages/UploadFiles'
import ReportEditor from 'pages/ReportEditor'
import NavWrapper from 'navigation/NavWrapper'
import EnterPhone from 'pages/auth/EnterPhone'
import EnterCode from 'pages/auth/EnterCode'
import Dashboard from 'pages/Dashboard'
import ProjectDataProvider from 'contexts/ProjectDataProvider'
import ShareReport from 'pages/ShareReport'
import Loading from 'pages/Loading'
import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'

const AppRouter = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  console.log('appLoaded', appLoaded)

  const publicRoutes = (
    <>
      <Route path='/auth/enter-code/:phone' element={<EnterCode />} />
      <Route path='/auth' element={<EnterPhone />} />
    </>
  )

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData) || !_.isNil(appLoaded)) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <>
          <Route path='/project/:projectId' element={<ProjectDataProvider />}>
            <Route path='settings' element={<ProjectSettings />} />
            <Route path='scope-builder' element={<ScopeBuilder />} />
            <Route path='report_editor' element={<ReportEditor />} />
            <Route path='share' element={<ShareReport />} />
          </Route>
          <Route path='/files' element={<UploadFiles />} />
          <Route path='/' element={<Dashboard />} />
        </>
      )
    }
  }, [authData, appLoaded])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NavWrapper />}>
          {privateRoutes}
          {publicRoutes}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
