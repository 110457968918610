import { NavigateFunction } from 'react-router-dom'
import { onAuthStateChanged, User } from 'firebase/auth'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import _ from 'lodash'
import qs from 'qs'

import { fetchDict } from 'controllers/data'
import { auth, db } from 'controllers/db'
import { receiveAuthData } from 'model/actions/authDataAC'
import store from 'model/store'
import {
  // addListener,
  clearListeners,
  clearUserListener,
  setUserUnsubscribe,
  existingUser,
  setExistingUser
} from 'controllers/listeners'
import { UserT } from 'model/types'
import { receiveUser, clear, logoutUser } from 'model/actions/userAC'
import { fetchAccount } from 'controllers/account'
import { fetchProjects } from 'controllers/projects'
import { fetchAccountsProfiles, fetchUsersProfiles, fetchOwnAccountsProfiles } from 'controllers/profiles'

// const getAccount = async (accountId: string) => {
//   const accSN = await getDoc(doc(db, 'accounts', accountId))
//   return accSN.data() as AccountT
// }

async function initAccountData (user: UserT) {
  console.log('initAccountData, userId', user.id)
  console.log('user: ', user)
  const currentAccountId = _.get(user, 'currentAccountId')
  console.log('initAccountData: currentAccountId', currentAccountId)
  if (currentAccountId) {
    // const account = await getAccount(currentAccountId)
    fetchAccount(currentAccountId)
    fetchProjects(currentAccountId)
    // fetchWorkOrders(currentAccountId, account.isGC)
    // fetchChannelWeb(user.id)
    fetchUsersProfiles(currentAccountId, user.id)
    // fetchMasonAdmins()
    fetchAccountsProfiles(currentAccountId)
    fetchOwnAccountsProfiles(user)
    // fetchBids(currentAccountId)
    // fetchOutgoingInvitations(currentAccountId)
    // fetchContacts(currentAccountId)
    // fetchInbox(user.id, currentAccountId)
    // fetchPrivateWorkOrders(currentAccountId)
  } else {
    // const e = new Error(`User ${user.id} has no currentAccountId`)
    // Sentry.captureException(e)
  }
}

const userChanged = async (user: UserT, isLocalChange: boolean, navigate: NavigateFunction) => {
  try {
    const currentAccountNow = _.get(existingUser, 'currentAccountId')
    console.log('%cuser changed, currentAccountNow', 'color: lightgreen', currentAccountNow)
    console.log('%cuser changed, newAccountId', 'color: lightgreen', _.get(user, 'currentAccountId'))
    const queryParams = qs.parse(_.get(window, 'location.search', ''), { ignoreQueryPrefix: true })
    const toAccountId = _.get(queryParams, 'accountId')
    console.log('%ctoAccountId', 'color: yellow', toAccountId)
    if (!_.isNil(toAccountId)) {
      navigate(window.location.pathname, { replace: true })
      if (_.includes(user.adminOfAccounts, toAccountId)) {
        console.log('is account admin true')
        const dbUserAccountId = _.get(user, 'currentAccountId')
        console.log('dbUserAccountId', dbUserAccountId, 'toAccountId', toAccountId)
        if (toAccountId !== dbUserAccountId) {
          console.log('need to switch acount true')
          await updateDoc(doc(db, 'users', user.id), { currentAccountId: toAccountId })
          return null
        }
      }
    }
    if (!currentAccountNow && _.has(user, 'currentAccountId') && !isLocalChange) {
      // console.log('userChanged 1')
      setExistingUser(user)
      store.dispatch(receiveUser(user))
      await initAccountData(user)
    } else if (currentAccountNow !== user.currentAccountId && !isLocalChange) {
      // console.log('userChanged 2')
      setExistingUser(user)
      store.dispatch(clear())
      clearListeners()
      store.dispatch(receiveUser(user))
      //      const screenName = await getDestinationScreen(user.id)
      navigate('/')
      await initAccountData(user)
    } else if (!isLocalChange) {
      // console.log('userChanged 3')
      setExistingUser(user)
      store.dispatch(receiveUser(user))
    } else {
      // console.log('userChanged 4')
      store.dispatch(receiveUser(user))
    }
  } catch (e) {
    console.log('userChanged error', e)
  }
}

const fetchUser = async (authData: User, navigate: NavigateFunction) => {
  console.log('fetch user', authData.uid)
  try {
    clearUserListener()
    const userId = authData.uid
    const unsubscribe = onSnapshot(
      doc(db, 'users', userId),
      { includeMetadataChanges: true },
      userSN => {
        const isLocalChange = userSN.metadata.hasPendingWrites
        // console.log('isLocalChange', isLocalChange)
        const user = userSN.data() as UserT
        // console.log('user received', user)
        userChanged(user, isLocalChange, navigate)
      },
      err => {
        console.log('fetch user error', err)
        // Sentry.captureMessage('fetch user error')
        // Sentry.captureException(err)
      }
    )
    setUserUnsubscribe(unsubscribe)
  } catch (e) {
    // Sentry.captureException(e)
    console.log('fetch user error', e)
  }
}

const init = async () => {
  console.log('init')
}

const onAuth = async (authData: User | null, navigate: NavigateFunction) => {
  console.log('onAuthStateChanged', authData)
  if (authData) {
    console.log('authData', authData)
    store.dispatch(receiveAuthData(authData))
    const pathname = window.location.pathname
    if (_.startsWith(pathname, '/auth')) {
      navigate('/')
    }
    // analytics.trackUserId(authData.uid)
    init()
    // const screenName = await getDestinationScreen(authData.uid)
    // console.log('destination screen', screenName)
    // await navigateToStart(screenName, navigate)
    await fetchUser(authData, navigate)
  } else {
    store.dispatch(receiveAuthData(null))
    clearUserListener()
    clearListeners()
    // let needToRedirect = true
    // const pathname = window.location.pathname
    // if (_.startsWith(pathname, '/acceptinvite')) needToRedirect = false
    // if (_.startsWith(pathname, '/invitation')) needToRedirect = false
    // if (_.startsWith(pathname, '/subproposal')) needToRedirect = false
    // if (_.startsWith(pathname, '/auth/enter-email')) needToRedirect = false
    // if (needToRedirect) {
    navigate('/auth')
    // navigate('/')
    // }
    store.dispatch(logoutUser())
  }
}

export const appInitialized = (dbId: string, navigate: NavigateFunction): void => {
  try {
    fetchDict(dbId)
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    // Sentry.captureException(e)
    console.log('app initialization error', e)
  }
}
