import { FC } from 'react'
import _ from 'lodash'
import { Box, Text, Flex, Button, FormControl, FormLabel, Input, Heading } from '@chakra-ui/react'

import { ReactComponent as Logo } from 'assets/masonLogoOnLight.svg'

type Props = {
  header: string
  subheader: any
  error: any
  isError: boolean
  valueName: string
  value: string
  placeholder: string
  handleChange: Function
  handleSubmit: Function
  isValid: boolean
  submitName: string
  footer?: any
  hideInputField?: boolean
  isLoading?: boolean
}

const AuthLayout: FC<Props> = ({
  header,
  subheader = 'We will text you a verification code to complete your registration.',
  error,
  isError,
  valueName,
  value,
  placeholder,
  handleChange,
  handleSubmit,
  isValid,
  submitName = 'Continue',
  footer,
  hideInputField,
  isLoading
}) => {
  const renderSubheader = () => {
    if (_.isString(subheader)) {
      return (
        <Text textAlign='start' fontSize={'sm'} color={'blue.600'}>
          {subheader}
        </Text>
      )
    } else {
      return subheader
    }
  }

  const onEnter = () => {
    if (isValid) {
      handleSubmit()
    }
  }

  return (
    <Flex padding={6} direction={'column'} align='flex-start' w='full' h='full'>
      <Flex direction='row' w='full' justify='space-between' align='center' mb={12}>
        <Logo height='32px' />
      </Flex>
      <Flex justify={'center'} w='full'>
        <Box align='center' py={24}>
          <Heading color={'blue.600'} mt={6} textAlign='center' fontWeight={'semibold'}>
            {header}
          </Heading>
          <Box>
            <Box pt={18}>{renderSubheader()}</Box>
            {!hideInputField && (
              <FormControl pt={12}>
                <FormLabel fontSize={'sm'}>{valueName}</FormLabel>
                <Input
                  id='phone'
                  type='phone'
                  placeholder={placeholder}
                  value={value}
                  onChange={e => handleChange(e.target.value)}
                  isDisabled={isLoading}
                />
              </FormControl>
            )}
            <Box height='xxsmall' margin={{ bottom: 'xxsmall' }}>
              {error}
            </Box>
            <Box width='medium' height='45px' pt={12}>
              <Button
                w='full'
                colorScheme={'green'}
                onClick={onEnter}
                disabled={!isValid}
                size='lg'
                isLoading={isLoading}
              >
                {submitName}
              </Button>
            </Box>
            {footer}
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default AuthLayout
