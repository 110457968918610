import { FC } from 'react'
import { Flex } from '@chakra-ui/react'

import ScopeBuilderMenu from 'pages/scopeBuilder/ScopeBuilderMenu'
import ScopeBuilderContent from 'pages/scopeBuilder/ScopeBuilderContent'
import ScopeBuilderNavBar from 'pages/scopeBuilder/ScopeBuilderNavBar'

const ScopeBuilder: FC = () => {
  const navigateToTradeId = (tradeId: string) => {
    const elt = document.getElementById(tradeId)
    if (elt) {
      elt.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <Flex direction={'column'} h={'full'} w='full'>
      <ScopeBuilderNavBar />
      <Flex flex={1} h='100%' overflow={'hidden'}>
        <ScopeBuilderMenu setCurrentTradeId={navigateToTradeId} />
        <ScopeBuilderContent />
      </Flex>
    </Flex>
  )
}

export default ScopeBuilder
