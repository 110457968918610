import { useContext, memo, FC } from 'react'
import { Box, VStack, Flex, Heading, Switch, Text } from '@chakra-ui/react'
import _ from 'lodash'
import TradeSection from 'pages/scopeBuilder/scopeBuilderContent/TradeSection'
import ProjectContext from 'contexts/ProjectContext'

type Props = {
  scopeHidden: boolean
  setScopeHidden: (value: boolean) => void
}

const ReportEditorScopeOfWork: FC<Props> = ({ scopeHidden = false, setScopeHidden }) => {
  const { tradesDict, projectScope, projectNotes, scopeSections } = useContext(ProjectContext)

  const tradesIds = _.sortBy(_.keys(projectScope), tradeId => _.get(tradesDict, tradeId))

  return (
    <Box w='full' pt={24} px={4}>
      <Flex w='full' direction='row' justifyContent={'center'}>
        <Box flex={1} />
        <Flex justify={'center'} flex={1}>
          <Heading color={scopeHidden ? 'red.600' : undefined} size={'md'}>
            SCOPE OF WORK
          </Heading>
        </Flex>
        <Flex justifyContent={'flex-end'} flex={1} align='center'>
          <Text fontSize={'sm'} color={scopeHidden ? 'red.500' : 'blue.600'}>
            {scopeHidden ? 'Not included' : 'Include in report'}
          </Text>
          <Switch
            colorScheme={scopeHidden ? 'red' : 'blue'}
            pl={4}
            size='sm'
            isChecked={!scopeHidden}
            onChange={e => setScopeHidden(!e.target.checked)}
          />
        </Flex>
      </Flex>
      {!scopeHidden && (
        <VStack align={'flex-start'} spacing={16} px={8} py={8} bgColor={'white'} borderRadius={'base'} mt={8}>
          {!_.isEmpty(projectNotes) && (
            <TradeSection
              key={'general_notes'}
              sections={projectNotes}
              tradeId={'general_notes'}
              tradeName={'General notes'}
              sectionsDict={scopeSections}
              readOnly
            />
          )}
          {_.map(tradesIds, tradeId => {
            return (
              <TradeSection
                key={tradeId}
                sections={projectScope[tradeId]}
                tradeId={tradeId}
                tradeName={_.get(tradesDict, tradeId)}
                sectionsDict={scopeSections}
                readOnly
              />
            )
          })}
        </VStack>
      )}
    </Box>
  )
}

export default memo(ReportEditorScopeOfWork)
