import { FC, useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Text } from '@chakra-ui/react'
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from '@choc-ui/chakra-autocomplete'
// import { getDomesticAddress } from 'shared/utils/stringUtils'
import * as googleApis from 'shared/utils/googleApis.web'
import { v4 } from 'uuid'
// import { getDomesticAddress } from 'shared/utils/stringUtils'
import { DictT, AddressT } from 'model/types'

const MIN_SEARCH_LENGTH = 2
const MAX_LENGTH = 100

type AddressAutocompleteProps = {
  value: string
  onSelect: (address: AddressT) => void
}

type SearchingRequestT = {
  abort: () => void
}

type OptionT = {
  place_id: string
  description: string
}

const AddressAutocomplete: FC<AddressAutocompleteProps> = ({ value, onSelect }) => {
  const [sessionToken] = useState(v4())
  const [address, setAddress] = useState(value)
  const [options, setOptions] = useState<DictT<OptionT>>({})
  const searchingRequests = useRef<SearchingRequestT[]>([])

  console.log('AddressAutocomplete, value', value)

  useEffect(() => {
    console.log('set address', value)
    setAddress(value)
  }, [value])

  const onChange = (event: { target: { value: string } }) => {
    const text = event.target.value
    // console.log('address autocompletion onChange', text)
    setAddress(text.substring(0, MAX_LENGTH))
  }

  const abortSearchingRequests = () => {
    const sr = searchingRequests.current
    _.forEach(sr, r => r && r.abort())
    searchingRequests.current = []
  }

  const updateSearchResults = (results: Array<OptionT>) => {
    const newOptions: DictT<OptionT> = {}

    _.forEach(results, r => {
      newOptions[r.place_id] = r
    })
    setOptions(newOptions)
  }

  const addSearchingRequest = (r: SearchingRequestT) => {
    const curRequests = searchingRequests.current || []
    searchingRequests.current = [r, ...curRequests]
  }

  const search = () => {
    if (address.length >= MIN_SEARCH_LENGTH) {
      abortSearchingRequests()
      const request = googleApis.autocomplete(address, sessionToken, (responseJSON: object) => {
        const results = _.get(responseJSON, 'predictions', [])
        if (!_.isEmpty(results)) {
          updateSearchResults(results)
        } else {
          updateSearchResults([])
        }
      })
      addSearchingRequest(request)
    } else {
      updateSearchResults([])
    }
  }

  const debouncedSearch = _.debounce(search, 500)

  useEffect(() => {
    debouncedSearch()
  }, [address])

  const onSelectOption = async (value: string) => {
    console.log('onSelectOption', value)
    const item = _.get(options, value)
    console.log('onPressSearchResult', item)
    const structured: { main: string; secondary: string } = {
      main: _.get(item, 'structured_formatting.main_text', ''),
      secondary: _.get(item, 'structured_formatting.secondary_text', '')
    }
    try {
      const res = await googleApis.getPlaceDetailsFormatted(value, structured)
      onSelect({ ...res, placeId: value })
    } catch (e) {
      // Sentry.captureException(e)
      console.log('get details error', e)
    }
  }

  const autocompleteOptions: DictT<OptionT> = _.size(options) > 1 ? options : {}

  return (
    <AutoComplete
      w='full'
      rollNavigation
      openOnFocus
      emptyState={
        <Text textAlign={'center'} fontSize={'sm'} color='gray.500'>
          <i>no suggestions</i>
        </Text>
      }
      bgColor='red'
      onChange={onSelectOption}
    >
      <AutoCompleteInput
        w='100%'
        variant={'outline'}
        placeholder='Enter address or city'
        autoFocus={_.isEmpty(value)}
        onChange={onChange}
        value={address}
        sx={{
          input: { bgColor: 'white' }
        }}
      />
      <AutoCompleteList bgColor='yellow'>
        {_.map(autocompleteOptions, (option, oid) => (
          <AutoCompleteItem key={`option-${oid}`} value={oid} fixed>
            {option.description}
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  )
}

export default AddressAutocomplete

// class AddressAutocomplete extends Component {
//   constructor (props) {
//     super(props)
//     this.state = {
//       text: '',
//       selected: [],
//       options: [],
//       sessionToken: uuidv4()
//     }
//     this.searchingRequests = []
//     this.search = _.debounce(this.search, 500)
//   }

//   static getDerivedStateFromProps (props, prevState) {
//     if (prevState.prevValue !== props.value) {
//       return {
//         text: props.value || '',
//         options: [],
//         prevValue: props.value
//       }
//     }
//     return null
//   }

//   componentWillUnmount () {
//     this.abortSearchingRequests()
//     this.onDetailsReceived = () => null
//   }

//   clearPredictions = () => this.setState({ options: [], selected: [] })

//   abortSearchingRequests = () => {
//     this.searchingRequests.forEach(request => {
//       request.abort()
//     })
//     this.searchingRequests = []
//   }

//   getTextForSearchResult = searchResult =>
//     searchResult.description || searchResult.formatted_address || searchResult.name

//   updateSearchResults = searchResults => {
//     const options = searchResults.map((item, index) => ({
//       value: index,
//       // label: this.getTextForSearchResult(item),
//       label: getDomesticAddress(item),
//       item: item
//     }))
//     this.setState({ options })
//   }

//   search = text => {
//     const { sessionToken } = this.state
//     if (text.length >= MIN_SEARCH_LENGTH) {
//       this.abortSearchingRequests()
//       const request = googleApis.autocomplete(text, sessionToken, responseJSON => {
//         const results = _.get(responseJSON, 'predictions', [])
//         if (!_.isEmpty(results)) {
//           this.updateSearchResults(results)
//         } else {
//           this.updateSearchResults([])
//         }
//       })
//       this.searchingRequests.push(request)
//     } else {
//       this.updateSearchResults([])
//     }
//   }

//   onChange = event => {
//     const text = event.target.value
//     // console.log('address autocompletion onChange', text)
//     this.setState({
//       text: text.substring(0, MAX_LENGTH)
//     })
//     this.search(text)
//   }

//   onDetailsReceived = addressInfo => {
//     // console.log('addressInfo', addressInfo)
//     this.props.onSelect(addressInfo)
//   }

//   onSelect = async event => {
//     const item = _.get(event, 'suggestion.item')
//     // console.log('onPressSearchResult', item, 'event', event)
//     const placeId = item.place_id
//     const structured = {
//       main: _.get(item, 'structured_formatting.main_text', null),
//       secondary: _.get(item, 'structured_formatting.secondary_text', null)
//     }
//     try {
//       const res = await googleApis.getPlaceDetailsFormatted(placeId, structured)
//       this.onDetailsReceived({ ...res, placeId })
//     } catch (e) {
//       Sentry.captureException(e)
//       console.log('get details error', e)
//     }
//   }

//   onBlur = () => {
//     this.setState({ focused: false, text: this.props.value })
//     this.props.onBlur()
//   }

//   render () {
//     const { text, options } = this.state
//     const { placeholder, error, onFocus, disabled, textInputProps } = this.props
//     // console.log('Address autocomplete render, options', options)
//     return (
//       <TextInput
//         suggestions={options}
//         value={text}
//         onSuggestionSelect={this.onSelect}
//         preferredPosition='below'
//         dropAlign={{ left: 'left', top: 'bottom' }}
//         error={error}
//         onFocus={onFocus}
//         onBlur={this.onBlur}
//         disabled={disabled}
//         onChange={this.onChange}
//         placeholder={placeholder}
//         plain
//         {...textInputProps}
//       />
//     )
//   }
// }

// AddressAutocomplete.defaultProps = {
//   textInputProps: {},
//   onBlur: () => null,
//   onFocus: () => null
// }

// AddressAutocomplete.propTypes = {
//   value: PropTypes.string,
//   onSelect: PropTypes.func.isRequired,
//   label: PropTypes.string,
//   error: PropTypes.any,
//   onFocus: PropTypes.func,
//   disabled: PropTypes.bool,
//   placeholder: PropTypes.string,
//   textInputProps: PropTypes.object,
//   onBlur: PropTypes.func
// }

// export default AddressAutocomplete

// export { AddressAutocomplete }
