import { NavigateFunction } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore'
import _ from 'lodash'

import { auth, db } from 'controllers/db'
import { clearListeners } from 'controllers/listeners'

export const switchAccount = async (accId: string, navigate: NavigateFunction) => {
  console.log('SWITCH ACCOUNT', accId)
  if (_.isNil(accId)) {
    console.warn('cannot switch to account', accId)
    return null
  }
  try {
    const uid = _.get(auth, 'currentUser.uid')
    if (uid) {
      console.log(' ----> history replace ')
      navigate('/', { replace: true })
      clearListeners()
      await updateDoc(doc(db, 'users', uid), { currentAccountId: accId })
    } else {
      console.log('switchAccount, no uid')
      // Sentry.captureMessage(`${accId} switchAccount, uid is undefined`, 'fatal')
    }
  } catch (e) {
    console.log('cant change account', e)
    // Sentry.captureException(e)
  }
}
