import { createStore } from 'redux'

import rootReducer from 'model/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension'

const composeEnhancers = composeWithDevTools({})

const configureStore = (initialState: {}) => {
  const enhancer = composeEnhancers()
  return createStore(rootReducer, initialState, enhancer)
}

const store = configureStore({})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
