import { FC } from 'react'
import { Text, Flex } from '@chakra-ui/react'

type ProjectTypeCardProps = {
  title: string
  desc: string
  isSelected: boolean
  onClick: Function
}

const ProjectTypeCard: FC<ProjectTypeCardProps> = ({ title, desc, isSelected, onClick }) => {
  return (
    <Flex
      direction={'column'}
      borderWidth='1px'
      borderColor={'green.500'}
      borderRadius='base'
      outline={isSelected ? '1px solid' : undefined}
      outlineColor='green.500'
      bgColor={isSelected ? 'green.50' : undefined}
      mx='4'
      my='2'
      minH='158px'
      px={4}
      py={6}
      gap={4}
      as='button'
      _hover={{
        outline: '1px solid',
        outlineColor: 'green.500',
        bgColor: 'green.50'
      }}
      onClick={() => onClick()}
    >
      <Text fontWeight='semibold' fontSize='lg'>
        {title}
      </Text>
      <Text fontSize={'xs'}>{desc}</Text>
    </Flex>
  )
}

export default ProjectTypeCard
