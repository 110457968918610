import { FC, useMemo } from 'react'
import _ from 'lodash'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { DictT, TotalRowT } from 'model/types'
import styles from 'components/scopeReport/styles'
import numeral from 'numeral'

type Props = {
  subtotal: number
  totalsItems: DictT<TotalRowT>
  costPlus?: number
}

const tableStyle = StyleSheet.create({
  headerText: {
    color: '#4f4f4f'
  },
  footerText: {
    color: '#AAAAAA'
  },
  tableRow: {
    height: 16
  }
})

const borderStyle = '#0.5px solid #dddddd'

const ScopeReportTotals: FC<Props> = ({ subtotal, totalsItems, costPlus }) => {
  const data: TotalRowT[] = useMemo(() => {
    const res = [
      {
        id: 'subtotal',
        name: 'Subtotal',
        value: _.isNil(subtotal) ? 0 : subtotal
      }
    ]
    if (!_.isNil(costPlus) && costPlus > 0) {
      res.push({
        id: 'costplus',
        name: `Cost plus (${costPlus}%)`,
        value: _.round((subtotal * costPlus) / 100, 2)
      })
    }
    return [...res, ..._.values(totalsItems)]
  }, [subtotal, totalsItems, costPlus])

  const projectTotal = useMemo(() => {
    return _.sumBy(data, 'value')
  }, [data])

  const tableHeader = (
    <View
      style={{
        backgroundColor: '#4A5568',
        flexDirection: 'row',
        ...tableStyle.tableRow,
        // border: borderStyle,
        border: '#0.5px solid #4A5568',
        marginTop: 20
      }}
      wrap={false}
    >
      <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
        <Text
          style={{
            ...styles.heading,
            ...tableStyle.headerText,
            color: '#fff',
            marginLeft: 6
          }}
        >
          TOTALS
        </Text>
      </View>
      <View
        style={{
          width: 78,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          borderLeft: borderStyle
        }}
      >
        <Text
          style={{
            ...styles.heading,
            ...tableStyle.headerText,
            color: '#fff'
          }}
        >
          TOTAL
        </Text>
      </View>
    </View>
  )

  const tableRows = _.map(data, (r: TotalRowT) => {
    return (
      <View
        key={r.id}
        style={{
          backgroundColor: '#FFF',
          flexDirection: 'row',
          ...tableStyle.tableRow,
          border: borderStyle,
          borderTop: 'none'
        }}
        wrap={false}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <Text style={{ ...styles.textSmall, color: '#004069', marginRight: 8 }}>{r.name}</Text>
        </View>
        <View
          style={{
            width: 78,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderLeft: borderStyle
          }}
        >
          <Text style={{ ...styles.textSmall, color: '#4A5568', textAlign: 'center' }}>
            {numeral(r.value).format('$0,0.00')}
          </Text>
        </View>
      </View>
    )
  })

  const projectTotalRow = (
    <View
      style={{
        backgroundColor: '#F7FAFC',
        flexDirection: 'row',
        ...tableStyle.tableRow,
        border: borderStyle,
        borderTop: 'none',
        height: 24
      }}
      wrap={false}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Text style={{ ...styles.textXLarge, fontWeight: 'semibold', color: '#004069', marginRight: 8 }}>
          Total cost of project
        </Text>
      </View>
      <View
        style={{
          width: 78,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          borderLeft: borderStyle
        }}
      >
        <Text style={{ ...styles.textXLarge, fontWeight: 'normal', color: '#4A5568', textAlign: 'center' }}>
          {numeral(projectTotal).format('$0,0.00')}
        </Text>
      </View>
    </View>
  )

  return (
    <>
      {tableHeader}
      {tableRows}
      {projectTotalRow}
    </>
  )
}

export default ScopeReportTotals
