import { ChangeEvent, ChangeEventHandler, useState, FC } from 'react'
import numeral from 'numeral'
import _ from 'lodash'
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  InputGroup,
  InputRightElement,
  HStack,
  Input
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPercent } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  apply: (perc: number) => void
}

const MarkupButton: FC<Props> = ({ apply }) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(!isOpen)
  const [v, setV] = useState('0')
  const close = () => {
    setV('0')
    setIsOpen(false)
  }

  const onValueChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const valueRaw = event.target.value.replace(/[^0-9.,-]/g, '')
    const newValue = valueRaw.replace(/[^0-9.,-]/g, '')
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(v).value()
      if (newV === curV || _.isNil(v)) {
        setV(valueRaw)
      } else {
        setV(numeral(newValue).format('0,0.[00]'))
      }
    } else {
      setV('')
    }
  }

  const onUpdateClick = () => {
    const cost = _.size(v) > 0 ? numeral(v).value() : null
    if (!_.isNil(cost)) {
      apply(cost)
    }
    close()
  }

  return (
    <Popover isLazy isOpen={isOpen} onClose={close}>
      <PopoverTrigger>
        <Button variant={'outline'} bgColor={'white'} size='sm' onClick={() => open()}>
          Add Markup
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight='semibold'>Markup</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody pt={10}>
          <InputGroup>
            <Input value={v} onChange={onValueChange} />
            <InputRightElement>
              <FontAwesomeIcon icon={faPercent} />
            </InputRightElement>
          </InputGroup>
          <HStack pt={8} pb={4} justifyContent={'flex-end'} spacing={6}>
            <Button variant={'outline'} onClick={close}>
              Cancel
            </Button>
            <Button colorScheme={'teal'} onClick={onUpdateClick}>
              Update
            </Button>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default MarkupButton
