import { useRef } from 'react'

import { Flex } from '@chakra-ui/react'
import ReportNavBar from 'pages/report/ReportNavBar'
import ReportEditorContent from 'pages/report/ReportEditorContent'
import EditReportParticipantModal, { IEditReportParticipantModal } from 'modals/EditReportParticipantModal'

const ReportEditor = () => {
  const detailsModalRef = useRef<IEditReportParticipantModal>(null)

  const openEditDetailsModal = () => {
    console.log('openEditDetailsModal')
    detailsModalRef && detailsModalRef.current && detailsModalRef.current.open()
  }

  return (
    <Flex w='full' h='100%' overflow={'hidden'} direction={'column'}>
      <ReportNavBar openEditDetailsModal={openEditDetailsModal} />
      <ReportEditorContent openEditDetailsModal={openEditDetailsModal} />
      <EditReportParticipantModal ref={detailsModalRef} />
    </Flex>
  )
}

export default ReportEditor
