import { useMemo } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Flex, Text, Box, Menu, MenuButton, Avatar, MenuList, MenuGroup, MenuItem, Divider } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons'

import { ReactComponent as Logo } from 'assets/masonLogoOnDark.svg'
import { useSelector } from 'model/hooks'
import { getCurrentAccountProfile } from 'model/selectors/profiles'
import { getName } from 'shared/utils/stringUtils'
import { getAccountsProfiles, getUser } from 'model/selectors/base'
import { signOut } from 'controllers/auth'
import { switchAccount } from 'controllers/user'

const DashboardNavBar = () => {
  const navigate = useNavigate()
  const user = useSelector(getUser)
  const accountProfile = useSelector(getCurrentAccountProfile)
  const accountsProfiles = useSelector(getAccountsProfiles)

  const accountsIds = useMemo(() => {
    if (user) {
      const accountsIds = user.adminOfAccounts
      const otherAccounts = _.filter(accountsIds, accId => accId !== user.currentAccountId)
      const sortedIds = _.sortBy(otherAccounts, accId => _.get(accountsProfiles, [accId, 'lastCreatedProjectTime'], 0))
      return _.reverse(sortedIds)
    } else {
      return []
    }
  }, [user])

  const menu = useMemo(() => {
    return (
      <Menu>
        <MenuButton>
          <Flex direction='row' align={'center'} gap='2'>
            <Avatar size={'sm'} src={_.get(accountProfile, 'avatarSmall')} />
            <Text color='white'>{getName(accountProfile)}</Text>
          </Flex>
        </MenuButton>
        {/* <Portal> */}
        <MenuList>
          <MenuItem icon={<FontAwesomeIcon icon={faPowerOff} />} onClick={() => signOut(navigate)}>
            Sign Out
          </MenuItem>
          <Divider />
          <MenuGroup title='Switch account' overflowY={'hidden'}>
            <Flex direction={'column'} maxH={'70vh'} overflowY={'auto'}>
              {_.map(accountsIds, accId => {
                const accProfile = _.get(accountsProfiles, accId)
                if (accProfile) {
                  return (
                    <MenuItem
                      key={accId}
                      icon={<Avatar size={'sm'} src={_.get(accProfile, 'avatarSmall', _.get(accProfile, 'avatar'))} />}
                      onClick={() => switchAccount(accId, navigate)}
                    >
                      {getName(accProfile)}{' '}
                      {accProfile.scopeBuilderProjectsAmount && accProfile.scopeBuilderProjectsAmount > 0
                        ? `(${accProfile.scopeBuilderProjectsAmount})`
                        : ''}
                    </MenuItem>
                  )
                } else {
                  return null
                }
              })}
            </Flex>
          </MenuGroup>
        </MenuList>
        {/* </Portal> */}
      </Menu>
    )
  }, [user, accountsProfiles])

  return (
    <Flex
      bgColor={'blue.600'}
      direction='row'
      justify={'space-between'}
      align='center'
      h={'16'}
      px={4}
      w='full'
      shrink={0}
    >
      <Box>
        <Logo height='24px' />
      </Box>
      {menu}
    </Flex>
  )
}

export default DashboardNavBar
