import { FC } from 'react'
import _ from 'lodash'
import { Text } from '@react-pdf/renderer'
import styles from 'components/scopeReport/styles'

import { DictT, BaseScopeT, BaseNotesT, ScopeSectionDictT } from 'model/types'
import ReportTradeSection from 'components/scopeReport/ReportTradeSection'

type Props = {
  tradesDict: DictT<string>
  projectScope: BaseScopeT
  projectNotes: BaseNotesT
  scopeSections: DictT<ScopeSectionDictT>
  excludedScopeItemsDict: DictT<string>
}

const ReportScopeOfWork: FC<Props> = ({
  tradesDict,
  projectScope,
  projectNotes,
  scopeSections,
  excludedScopeItemsDict
}) => {
  const tradesIds = _.sortBy(_.keys(projectScope), tradeId => _.get(tradesDict, tradeId))

  const notes = () => {
    if (!_.isEmpty(projectNotes)) {
      return (
        <ReportTradeSection
          key={'general_notes'}
          sections={projectNotes}
          tradeId={'general_notes'}
          tradeName={'General notes'}
          sectionsDict={scopeSections}
          excludedScopeItemsDict={excludedScopeItemsDict}
        />
      )
    }
  }

  return (
    <>
      <Text style={{ ...styles.textLarge, paddingTop: 32, textAlign: 'center', fontWeight: 'bold' }}>
        SCOPE OF WORK
      </Text>
      {notes()}
      {_.map(tradesIds, tradeId => {
        return (
          <ReportTradeSection
            key={tradeId}
            sections={projectScope[tradeId]}
            tradeId={tradeId}
            tradeName={_.get(tradesDict, tradeId)}
            sectionsDict={scopeSections}
            excludedScopeItemsDict={excludedScopeItemsDict}
          />
        )
      })}
    </>
  )
}

export default ReportScopeOfWork
