import * as types from 'model/actionTypes'
import { UserT } from 'model/types'

export function logoutUser () {
  return {
    type: types.LOGOUT
  }
}

export function receiveUser (user: UserT) {
  return {
    type: types.RECEIVE_USER,
    user
  }
}

export function clear () {
  return {
    type: types.CLEAR
  }
}
