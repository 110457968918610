import { StepsStyleConfig } from 'chakra-ui-steps'
import { mode } from '@chakra-ui/theme-tools'

const CustomSteps = {
  ...StepsStyleConfig,
  defaultProps: {
    size: 'sm',
    colorScheme: 'green'
  },
  baseStyle: (props: any) => {
    // console.log('CustomSteps base style props', props)
    // console.log('CustomSteps base conf', StepsStyleConfig)
    const res = {
      ...StepsStyleConfig.baseStyle(props),
      icon: {
        ...StepsStyleConfig.baseStyle(props).icon,
        strokeWidth: '1px'
      },
      stepIconContainer: {
        ...StepsStyleConfig.baseStyle(props).stepIconContainer,
        bg: 'white',
        borderColor: 'gray.300'
      },
      description: {
        fontWeight: 'bold'
      },
      label: {
        // ...StepsStyleConfig.baseStyle(props).label,
        color: mode('gray.800', 'gray.100')(props),
        fontWeight: 'normal',
        textAlign: 'center',
        fontSize: 'sm'
      }
    }
    // console.log('final theme', res)
    return res
  }
}

export default {
  components: {
    Steps: CustomSteps
  },
  colors: {
    brand: 'blue.500',
    blue: {
      50: '#E0E8ED',
      100: '#B3C6D2',
      200: '#80A0B4',
      300: '#4D7996',
      400: '#265D80',
      500: '#004069',
      600: '#003A61',
      700: '#003256',
      800: '#002A4C',
      900: '#001C3B'
    },
    green: {
      50: '#e8f9f6',
      100: '#c5f1e7',
      200: '#9ee8d8',
      300: '#77dec8',
      400: '#59d7bc',
      500: '#3cd0b0',
      600: '#36cba9',
      700: '#2ec4a0',
      800: '#27be97',
      900: '#1ab387'
    },
    red: {
      50: '#fdebeb',
      100: '#f9cdcd',
      200: '#f5abab',
      300: '#f18989',
      400: '#ee7070',
      500: '#eb5757',
      600: '#e94f4f',
      700: '#e54646',
      800: '#e23c3c',
      900: '#dd2c2c'
    }
    // gray: {
    //   30: '#fafafa',
    //   50: '#eaeaea',
    //   100: '#c9c9c9',
    //   200: '#a6a6a6',
    //   300: '#828282',
    //   400: '#676767',
    //   500: '#4c4c4c',
    //   600: '#454545',
    //   700: '#3c3c3c',
    //   800: '#333333',
    //   900: '#242424'
    // },
    // gray: {
    //   50: '#fafafa',
    //   100: '#f5f5f5',
    //   200: '#eeeeee',
    //   300: '#e0e0e0',
    //   400: '#bdbdbd',
    //   500: '#9e9e9e',
    //   600: '#757575',
    //   700: '#616161',
    //   800: '#424242',
    //   900: '#212121'
    // }
  },
  fonts: {
    heading: 'Lato',
    body: 'Lato'
  }
}
