import _ from 'lodash'
import stringHash from 'string-hash'
import moment from 'moment'
import numeral from 'numeral'

export function initialsByName (name) {
  const ar = _.split(name, ' ')
  const fst = _.get(ar, 0)
  const snd = _.get(ar, ar.length - 1)
  const res = initialsByFirstLastName(fst, snd)
  return res
}

export function initialsByFirstLastName (firstName, lastName) {
  const fst = _.get(firstName, 0, '')
  const snd = _.get(lastName, 0, '')
  return `${fst}${snd}`
}

export function cutString (str, num) {
  return _.truncate(str, {
    length: num,
    separator: /@,? +/
  })
}

export function firstNameByName (name) {
  return _.get(_.split(name, ' '), 0)
}

export function lastNameByName (name) {
  const ar = _.split(name, ' ')
  if (ar.length > 1) {
    return _.get(ar, ar.length - 1)
  } else {
    return ''
  }
}

export function typingStringByNames (names = []) {
  switch (names.length) {
    case 0:
      return ''
    case 1:
      return `${names[0]} is typing...`
    case 2:
      return `${names[0]} and ${names[1]} are typing...`
    default:
      return `${names[0]} and ${names.length - 1} others are typing...`
  }
}

export function getName (contact) {
  if (_.has(contact, 'name')) {
    return contact.name
  } else if (_.has(contact, 'firstName') && _.has(contact, 'lastName')) {
    return `${_.get(contact, 'firstName', '')} ${_.get(contact, 'lastName', '')}`
  } else if (_.has(contact, 'email')) {
    return contact.email
  } else if (_.has(contact, 'phone')) {
    return contact.phone
  } else {
    return _.get(contact, 'contactEmail', '')
  }
}

export function getRealName (contact) {
  if (_.has(contact, 'name')) {
    return _.get(contact, 'name')
  } else if (_.has(contact, 'firstName') || _.has(contact, 'lastName')) {
    return _.has(contact, 'firstName') && _.has(contact, 'lastName')
      ? `${_.get(contact, 'firstName', '')} ${_.get(contact, 'lastName', '')}`
      : _.get(contact, 'firstName', '') || _.get(contact, 'lastName', '')
  }
  return ''
}

export function getDisplayName (contact) {
  if (_.has(contact, 'firstName') && _.has(contact, 'lastName')) {
    const lastName = _.capitalize(_.get(contact, 'lastName', ''))
    const firstNameInitial = _.capitalize(_.get(contact, 'firstName', ' ')[0])
    return `${lastName}, ${firstNameInitial}.`
  }
  return getName(contact)
}

export function getFirstName (contact) {
  if (_.has(contact, 'firstName')) {
    return contact.firstName
  } else if (_.has(contact, 'name')) {
    return firstNameByName(contact.name)
  } else if (_.has(contact, 'email')) {
    return contact.email
  } else {
    return _.get(contact, 'contactEmail', '')
  }
}

export function getLastName (contact) {
  if (_.has(contact, 'lastName')) {
    return contact.lastName
  } else if (_.has(contact, 'name')) {
    return lastNameByName(contact.name)
  } else {
    return ''
  }
}

export function getInitials (contact) {
  const name = _.isString(contact) ? contact : getName(contact)
  return _.upperCase(initialsByName(name))
}

export function seenByStringByNames (names = []) {
  switch (names.length) {
    case 0:
      return ''
    case 1:
      return `Seen by ${names[0]}`
    case 2:
      return `Seen by ${names[0]} and ${names[1]}`
    default:
      return `Seen by ${names[0]} and ${names.length - 1} others`
  }
}

export function pictureUrlByString (str) {
  const hash = stringHash(str)
  const num = hash % 148
  return `https://picsum.photos/id/${num}/60/60`
}

export function titleCase (str) {
  return _.map(_.split(str.toString(), ' '), _.upperFirst).join(' ')
}

export function generateId (length = 6) {
  let res = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    res += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return res
}

const statesAbbr = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
}

export const getStateAbbr = address => {
  if (_.has(address, 'stateAbbr')) return address.stateAbbr
  const st = _.get(address, 'state')
  return _.get(statesAbbr, st, '')
}

export function getAddress (address) {
  const street = _.get(address, 'structured.main', '')
  const city = _.get(address, 'city', '')
  const state = getStateAbbr(address)
  const zip = _.get(address, 'zipcode', '')
  const res = []
  const firstPath = `${street} ${city}`
  const secondPath = `${state} ${zip}`
  if (!_.isEmpty(_.trim(firstPath))) res.push(firstPath)
  if (!_.isEmpty(_.trim(secondPath))) res.push(secondPath)
  return res.join(', ')
}

// returns address format: Austin, TX 93281
export function getAddressLine2 (address) {
  const city = _.get(address, 'city', '')
  const state = getStateAbbr(address)
  const zip = _.get(address, 'zipcode', '')
  const ar = _.filter([city, `${state} ${zip}`], v => !_.isEmpty(v))
  return _.join(ar, ', ')
}

export function getDomesticAddress (address) {
  const terms = _.get(address, 'terms', [])
  const domesticAddress = []
  _.map(terms, (term, index) => {
    if (!(index === terms.length - 1 && term.value === 'USA')) {
      if (index === terms.length - 3) {
        domesticAddress.push(term.value + ',')
      } else {
        domesticAddress.push(term.value)
      }
    }
  })
  return domesticAddress.join(' ')
}

export function formatBytes (bytes) {
  return numeral(bytes).format('0.00b')
}

export const formatProfile = value =>
  _.omitBy(
    {
      id: _.get(value, 'id'),
      name: getName(value),
      email: _.get(value, 'email'),
      phone: _.get(value, 'phone'),
      avatar: _.get(value, 'avatarSmall', _.get(value, 'avatar')),
      address: _.get(value, 'address')
    },
    _.isNil
  )

export const getWorkOrderSendingData = (workOrder, contractorTypes) => {
  const id = _.get(workOrder, 'id')
  const address = _.get(workOrder, 'projectAddress')
  const contractorTypeName = _.get(contractorTypes, [_.get(workOrder, 'tradeId'), 'name'])
  const tradeName = _.get(workOrder, 'label', contractorTypeName)
  const files = _.map(_.get(workOrder, 'files'), formatFile)
  const dates = _.omitBy(
    {
      bidsDueDate: getFormattedTimeObject(_.get(workOrder, 'bidsDueDate')),
      startDate: getFormattedTimeObject(_.get(workOrder, 'startDate')),
      rfiDueDate: getFormattedTimeObject(_.get(workOrder, 'rfiDueDate')),
      createdAt: getFormattedTimeObject(_.get(workOrder, 'createdAt').toDate())
    },
    _.isNil
  )
  return {
    id,
    address,
    tradeName,
    files,
    dates
  }
}

const formatFile = file => ({
  ...file,
  size: file.size ? formatBytes(file.size) : '',
  createdAt: getFormattedTimeObject(file.createdAt)
})

export const arrayToSentence = a => [a.slice(0, -1).join(', '), a.pop()].filter(w => w !== '').join(' and ')

export const getFormattedTimeObject = timestamp => ({
  monthDay: moment(timestamp).format('ll'),
  fullDate: moment(timestamp).format('lll'),
  dateDiff: moment(timestamp).fromNow()
})

export const wrapMentions = (text, regex, renderReplacement) => {
  const textArray = text.split(regex)
  return textArray.map((str, index) => {
    if (regex.test(str)) {
      const name = _.replace(_.replace(str, '@[', ''), /\]\(.*\)/, '')
      if (!_.isFunction(renderReplacement)) return name
      return renderReplacement(name, index)
    }
    return str
  })
}

export const isNumeric = (str) => {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

export const splitStringAtIndex = (string, index) => {
  return [string.substring(0, index), string.substring(index + 1)]
}
