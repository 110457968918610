import moment from 'moment'
import _ from 'lodash'

export function daysDiff (fromDate, toDate) {
  const fm = fromDate.startOf('day')
  const tm = toDate.startOf('day')
  return fm.diff(tm, 'days')
}

export function stringDiff (s, e) {
  const diff = moment.duration(e.diff(s))
  return diff.humanize(true)
}

export function stringDiffDays (s, e) {
  const diff = moment.duration(e.diff(s, 'days'), 'days')
  return diff.humanize(true)
}

export function stringDiffHours (s, e) {
  const delta = e.diff(s, 'hours')
  const diff = moment.duration(delta, delta < 24 ? 'hours' : 'days')
  return diff.humanize(true)
}

export function greating () {
  const hours = moment().hours()
  return hours < 12 ? 'Morning' : hours < 18 ? 'Afternoon' : 'Evening'
}

export function toTimestamp (t) {
  // console.log('toTimestamp', t, 'isObject', _.isObject(t), 'has seconds', _.has(t, 'seconds'))
  try {
    if (_.isNull(t)) {
      // the value can be null if it's just added to firestore and has not uploaded to remote db yet, it's a local update
      return _.now()
    } else if (_.isNil(t)) {
      // console.log('timestamp is nill', t)
      return 0
    } else if (_.has(t, 'toMillis')) {
      return t.toMillis()
    } else if (_.isObject(t) && _.has(t, 'seconds') && _.has(t, 'nanoseconds')) {
      return t.seconds * 1000 + t.nanoseconds / 1000000
    } else {
      return Number(t)
    }
  } catch (e) {
    console.warn('cannot convert to timestamp', t, e)
  }
}

export const fromNow = v => {
  const t = toTimestamp(v)
  return stringDiff(moment(), moment(t))
}
