import { Flex } from '@chakra-ui/react'
import { Step, Steps } from 'chakra-ui-steps'
import { FC } from 'react'

const steps = [{ label: 'SELECT TEMPLATE' }, { label: 'UPLOAD FILES' }, { label: 'PAYMENT' }]

type StepsProps = {
  num: number
}

const CustomSteps: FC<StepsProps> = ({ num }) => {
  return (
    <Flex direction='column' w='lg'>
      <Steps labelOrientation='vertical' activeStep={num}>
        {steps.map(({ label }, index) => (
          <Step size='sm' key={index} label={label} />
        ))}
      </Steps>
    </Flex>
  )
}

export default CustomSteps
