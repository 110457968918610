import { useContext, FC, useRef, useState } from 'react'
import _ from 'lodash'
import {
  IconButton,
  Flex,
  Heading,
  HStack,
  Select,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box
} from '@chakra-ui/react'
import { faChevronLeft, faArrowUpRightFromSquare, faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProjectContext from 'contexts/ProjectContext'
import { useNavigate } from 'react-router-dom'
import SaveReportTemplateModal, { ISaveReportTemplateModal } from 'src/modals/SaveReportTemplateModal'
import { useSelector } from 'model/hooks'
import ShareProjectModal, { IShareProjectModal } from 'src/modals/ShareProjectModal'

type Props = {
  openEditDetailsModal: () => void
}

const ReportNavBar: FC<Props> = ({ openEditDetailsModal }) => {
  const { project, reportChanged, loadTemplate } = useContext(ProjectContext)
  const navigate = useNavigate()
  const saveTemplateModalRef = useRef<ISaveReportTemplateModal>(null)
  const reportTemplates = useSelector(state => _.get(state, 'account.reportTemplates', {}))
  const { currentTemplateId } = useContext(ProjectContext)
  const [isLoading, setIsLoading] = useState(false)
  const shareProjectModalRef = useRef<IShareProjectModal>(null)

  const templatesHidden = false
  const shareButtonHidden = false

  const goBack = () => navigate(`/project/${project?.id}/scope-builder`)

  const leftSide = (
    <HStack direction='row' align={'center'} spacing={2} flex={1} justifyContent='flex-start'>
      <IconButton
        variant={'ghost'}
        aria-label='Go back'
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={goBack}
      />
      <Heading size={'md'}>Report - </Heading>
      <Text color='gray.600' pr={6}>
        {_.get(project, 'address.name')}
      </Text>
      <Box>
        <Menu isLazy>
          <MenuButton>
            <FontAwesomeIcon icon={faEllipsis} />
          </MenuButton>
          <MenuList>
            {/* MenuItems are not rendered unless Menu is open */}
            <MenuItem onClick={openEditDetailsModal}>Edit details</MenuItem>
            <MenuItem onClick={goBack}>View Scope</MenuItem>
            <MenuItem onClick={() => shareProjectModalRef?.current?.open()}>Edit Bid Packages</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  )

  const sortedTemplates = _.sortBy(reportTemplates, 'createdAt')

  const onChangeTemplate = async (tId: string | undefined) => {
    console.log('onChangeTemplate', tId)
    if (tId !== currentTemplateId) {
      setIsLoading(true)
      await loadTemplate(tId)
      setIsLoading(false)
    }
  }

  const middle = (
    <HStack direction='row' align={'center'} spacing={2} flex={1} justifyContent='center'>
      {!_.isEmpty(sortedTemplates) && (
        <Flex direction={'row'} w='48' px={4}>
          <Select
            value={currentTemplateId || ''}
            // placeholder='Select template'
            placeholder='Default template'
            isDisabled={isLoading}
            // defaultValue='Select template'
            onChange={e => onChangeTemplate(e.target.value)}
          >
            {/* <option value='' disabled>
              {'Select template'}
            </option> */}
            {_.map(sortedTemplates, t => {
              return (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              )
            })}
          </Select>
        </Flex>
      )}
      {reportChanged && (
        <Button color='cyan.800' variant={'link'} onClick={() => saveTemplateModalRef.current?.open()}>
          Save template
        </Button>
      )}
    </HStack>
  )

  const rightSide = (
    <Flex direction='row' align={'center'} pr={4} flex={1} justifyContent='flex-end'>
      <Button
        leftIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
        colorScheme={'teal'}
        onClick={() => navigate(`/project/${project?.id}/share`)}
      >
        Share
      </Button>
    </Flex>
  )

  return (
    <Flex
      direction={'row'}
      align='center'
      justify={'space-between'}
      py={4}
      borderBottom='1px'
      borderColor={'gray.200'}
      overflow={'hidden'}
    >
      {leftSide}
      {!templatesHidden && middle}
      {!shareButtonHidden && rightSide}
      <SaveReportTemplateModal ref={saveTemplateModalRef} />
      <ShareProjectModal ref={shareProjectModalRef} />
    </Flex>
  )
}

export default ReportNavBar
