import _ from 'lodash'
// import * as Sentry from '@sentry/react'
import { query, onSnapshot, collection, where } from '@firebase/firestore'

import { addListener } from 'controllers/listeners'
import { receiveProfiles } from 'model/actions/profilesAC'
import { receiveAccountsProfiles } from 'model/actions/accountsProfilesAC'
import store from 'model/store'
// import { setSentryUserProfile, setSentryCompanyProfile } from 'controllers/sentry'
import { db } from 'controllers/db'
import { UserT } from 'model/types'

export const fetchUsersProfiles = (accountId: string, userId: string) => {
  try {
    console.log('fetchUsersProfiles, accountId:', accountId)
    const q = query(collection(db, 'usersProfiles'), where('accounts', 'array-contains', accountId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const profiles = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.unset(p, 'accounts')
          // if (doc.id === userId) setSentryUserProfile(p)
          _.set(profiles, doc.id, p)
        })
        console.log('fetchUsersProfiles:', _.size(profiles))
        store.dispatch(receiveProfiles(profiles))
      },
      err => {
        console.log(`fetch usersProfiles error: ${err}`)
        // Sentry.captureException(err)
      }
    )
    addListener('usersProfiles', unsubscribe)
  } catch (e) {
    console.log('usersProfiles error', e)
    // Sentry.captureException(e)
  }
}

export const fetchAccountsProfiles = (accountId: string) => {
  try {
    console.log('fetchAccountsProfiles, accountId:', accountId)
    const q = query(collection(db, 'accountsProfiles'), where('accounts', 'array-contains', accountId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const profiles = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.unset(p, 'accounts')
          // if (doc.id === accountId) setSentryCompanyProfile(p)
          _.set(profiles, doc.id, p)
        })
        console.log('fetchAccountsProfiles:', _.size(profiles))
        store.dispatch(receiveAccountsProfiles(profiles))
      },
      err => {
        console.log(`fetchAccountsProfiles error: ${err}`)
      }
    )
    addListener('accountsProfiles', unsubscribe)
  } catch (e) {
    console.log('fetchAccountsProfiles error', e)
    // Sentry.captureException(e)
  }
}

export const fetchOwnAccountsProfiles = (user: UserT) => {
  try {
    const accountsId = _.get(user, 'adminOfAccounts', [])
    console.log('fetchOwnAccountsProfiles, user.adminOfAccounts:', accountsId)
    if (!_.isEmpty(accountsId)) {
      const chunks = _.chunk(accountsId, 10)
      for (const i in chunks) {
        const ids = chunks[i]
        const q = query(collection(db, 'accountsProfiles'), where('id', 'in', ids))
        const unsubscribe = onSnapshot(
          q,
          sn => {
            const profiles = {}
            sn.forEach(doc => {
              const p = doc.data()
              _.unset(p, 'accounts')
              _.set(profiles, doc.id, p)
            })
            console.log('fetchOwnAccountsProfiles:', _.size(profiles))
            store.dispatch(receiveAccountsProfiles(profiles))
          },
          err => {
            console.log(`fetchOwnAccountsProfiles error: ${err}`)
          }
        )
        addListener(`ownAccountsProfiles${i}`, unsubscribe)
      }
    } else {
      console.warn('fetchOwnAccountsProfiles, the list is empty', user.id)
    }
  } catch (e) {
    console.log('fetchOwnAccountsProfiles error', e)
    // Sentry.captureException(e)
  }
}
