import CustomTableHeaderCell from 'pages/report/reportEditorContent/tradesTable/CustomTableHeaderCell'

const CustomHeaderCellWhite = ({
  className,
  column,
  columnIndex
}: {
  className: string
  column: any
  columnIndex: number
}) => {
  return <CustomTableHeaderCell color='white' className={className} column={column} columnIndex={columnIndex} />
}

export default CustomHeaderCellWhite
