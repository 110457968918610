import * as types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { DictT, ProjectT } from 'model/types'

const initialState: DictT<ProjectT> | null = null

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_PROJECTS:
      return action.projects
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
