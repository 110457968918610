import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  heading: {
    fontFamily: 'Lato',
    fontSize: 5.5,
    fontWeight: 'bold'
  },
  textXSmall: {
    fontFamily: 'Lato',
    fontSize: 6
  },
  textSmall: {
    fontFamily: 'Lato',
    fontSize: 7
  },
  textMedium: {
    fontFamily: 'Lato',
    fontSize: 8
  },
  textLarge: {
    fontFamily: 'Lato',
    fontSize: 9
  },
  textXLarge: {
    fontFamily: 'Lato',
    fontSize: 12
  }
})

export default styles
