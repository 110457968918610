import { FC, useContext } from 'react'
import { Box, VStack } from '@chakra-ui/react'
import _ from 'lodash'

import TradeSection from 'pages/scopeBuilder/scopeBuilderContent/TradeSection'
import ProjectContext from 'contexts/ProjectContext'

type ScopeBuilderContentProps = {}

const ScopeBuilderContent: FC<ScopeBuilderContentProps> = () => {
  const { baseScope, tradesDict, scopeSections, baseNotes } = useContext(ProjectContext)
  const tradesIds = _.sortBy(_.keys(baseScope), tradeId => _.get(tradesDict, tradeId))
  return (
    <Box py={6} px={6} w='full' h='full' overflowX={'hidden'} overflowY={'auto'}>
      <VStack align={'flex-start'} spacing={16}>
        {baseNotes && (
          <TradeSection
            sections={baseNotes}
            tradeId={'general_notes'}
            tradeName='General notes'
            sectionsDict={scopeSections}
          />
        )}
        {_.map(tradesIds, tradeId => {
          const sections = _.get(baseScope, tradeId)
          if (!_.isEmpty(sections)) {
            return (
              <TradeSection
                key={tradeId}
                sections={sections}
                tradeName={_.get(tradesDict, tradeId)}
                tradeId={tradeId}
                sectionsDict={scopeSections}
              />
            )
          } else {
            return null
          }
        })}
      </VStack>
    </Box>
  )
}

export default ScopeBuilderContent
