export const RECEIVE_SCOPE_SECTIONS_DICT = 'RECEIVE_SCOPE_SECTIONS_DICT'
export const RECEIVE_SOW_ITEMS_DICT = 'RECEIVE_SOW_ITEMS_DICT'
export const RECEIVE_TRADES_DICT = 'RECEIVE_TRADES_DICT'
export const RECEIVE_LINE_ITEMS = 'lineItems'
export const RECEIVE_PROJECT_PRESETS = 'RECEIVE_PROJECT_PRESETS'
export const RECEIVE_PROJECT_DETAILS = 'RECEIVE_PROJECT_DETAILS'
export const RECEIVE_NOTES_DICT = 'RECEIVE_NOTES_DICT'
export const CLEAR = 'CLEAR'
export const LOGOUT = 'LOGOUT'
export const RECEIVE_AUTH_DATA = 'RECEIVE_AUTH_DATA'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT'
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS'
export const RECEIVE_ACCOUNTS_PROFILES = 'RECEIVE_ACCOUNTS_PROFILES'
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE'
export const RECEIVE_PROFILES = 'RECEIVE_PROFILES'

export default {
  RECEIVE_SCOPE_SECTIONS_DICT,
  RECEIVE_SOW_ITEMS_DICT,
  RECEIVE_TRADES_DICT,
  RECEIVE_LINE_ITEMS,
  RECEIVE_PROJECT_PRESETS,
  RECEIVE_PROJECT_DETAILS,
  CLEAR,
  LOGOUT,
  RECEIVE_AUTH_DATA,
  RECEIVE_ACCOUNT,
  RECEIVE_PROJECTS,
  RECEIVE_ACCOUNTS_PROFILES,
  RECEIVE_PROFILE,
  RECEIVE_PROFILES,
  RECEIVE_NOTES_DICT
}
