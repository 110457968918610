import { combineReducers } from 'redux'
import scopeSectionsDict from 'model/reducers/scopeSectionsDict'
import sowItemsDict from 'model/reducers/sowItemsDict'
import tradesDict from 'model/reducers/tradesDict'
import projectDetails from 'model/reducers/projectDetails'
import projectPresets from 'model/reducers/projectPresets'
import authData from 'model/reducers/authData'
import user from 'model/reducers/user'
import account from 'model/reducers/account'
import projects from 'model/reducers/projects'
import profiles from 'model/reducers/profiles'
import accountsProfiles from 'model/reducers/accountsProfiles'
import notesDict from 'model/reducers/notesDict'

export const rootReducer = combineReducers({
  scopeSectionsDict,
  sowItemsDict,
  tradesDict,
  projectDetails,
  projectPresets,
  authData,
  user,
  account,
  projects,
  profiles,
  accountsProfiles,
  notesDict
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
