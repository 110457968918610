'use strict'
exports.apiKey = 'AIzaSyDa0ZCps4M9J_cJEAq_SSpUBfLxvSM0StM'
exports.authDomain = 'mason-live.firebaseapp.com'
exports.databaseURL = 'https://mason-live.firebaseio.com'
exports.projectId = 'mason-live'
exports.storageBucket = 'mason-live.appspot.com'
exports.messagingSenderId = '261429687409'
exports.appId = '1:261429687409:web:02331dd7ee8b6d56a557d0'
exports.measurementId = 'G-S8RZE0V51E'
exports.dynamicUrl = 'https://us-central1-mason-live.cloudfunctions.net'
exports.supportEmail = 'support@usemason.com'
exports.proposalUrl = 'https://doc.usemason.com'
exports.plaidPublicKey = '49365bace69030434658eb5148c16b'
exports.plaidEnv = 'development'
exports.googleMapsApiKey = 'AIzaSyDa0ZCps4M9J_cJEAq_SSpUBfLxvSM0StM'
exports.isDev = false
exports.internalVersion = 244
exports.stripeClientId = 'ca_F9VaIgfuie3a72Y0i10bgCCQpwo8cXb9'
exports.stripeAuthorizeUri = 'https://connect.stripe.com/express/oauth/authorize'
exports.homeownerUrl = 'https://ho.usemason.com'
exports.hellosignClientId = '1bd7c91a8143a803d058f4e90e59ac2b'
exports.workOrderUrl = 'https://wo.usemason.com'
exports.sentryHICPublicDSN = 'https://3d74ee7136b94b4da6e38f5e4cf3f496@sentry.io/1808364'
exports.sentrySubsPublicDSN = 'https://c8c9e8314bb140fcb7afdaf70aa79a5a@sentry.io/1808374'
exports.sentryHICWebPublicDSN = 'https://0e09b24e2ee84d72a7161e61eb2c48c1@sentry.io/1808395'
exports.adminUrl = 'https://admin.usemason.com'
exports.zapierStripeExpressConnectStart = 'https://hooks.zapier.com/hooks/catch/6329595/otsphnz/'
exports.zapierStripeExpressConnectExit = 'https://hooks.zapier.com/hooks/catch/6329595/ot8bszi/'
exports.stripeApiKey = 'pk_live_CbF1SSJic6LxLUQ3nWfCtgec002Ac7m8RV'
exports.zapierAppInitialized = 'https://hooks.zapier.com/hooks/catch/6329595/o11lkkd/'
exports.zapierOnboardingStarted = 'https://hooks.zapier.com/hooks/catch/6329595/o110ifr/'
exports.zapierOnboardingStep = 'https://hooks.zapier.com/hooks/catch/6329595/o11xbh1/'
exports.geojsonUrl =
  'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/geojson%2FgeoJsonSummary.json?alt=media&token=ef68d191-6dbb-49be-a49a-a469eb09d4a1'
exports.adobeClientIdMason = '1ebce3a9476c47f6847b9005ecb1d09d'
exports.adobeClientIdBidlevel = '35ac983ec7914904b4a749b6370c2370'
exports.adobeClientIdReport = '068741907d5648b383122f917ade744f'
exports.adobeClientIdInvitation = '6cb5cecca15d4dac8cebf17f7800651f'
exports.adobeClientIdStorybook = '4e886e869c9647fa816a05256660de6f'
exports.branchKey = 'key_live_jnMJF34WWYgbMeTpEZgaxhnhtFaYxXHv'
exports.gcHostname = 'bidlevel.co'
exports.courierToken = 'TWQ5KT3RAQ4YXEHK1JP6W44ZKR8D'
exports.algoliaAppId = '7RIIE7FIGA'
exports.algoliaApiKey = '3ebd5c59d7dcb346a01c8aefb2b1c1d8'
exports.subHost = 'https://app.usemason.com'
exports.gcHost = 'https://app.bidlevel.co'
exports.reportUrl = 'https://rp.bidlevel.co'
exports.calendlyUrl = 'https://calendly.com/bidlevel/precon-call'
exports.sentryToken = '775e7777c06d4c60bfbf834684dba1effbe2296191cf4281887e6ab4b8cd06d6'
exports.invitationUrl = 'https://inv.usemason.com'
exports.retoolServicesPage =
  'https://masonco.retool.com/embedded/public/e8121d26-73a5-459b-a85e-3daf2a1d33fd?_environment=production&_hideTimer=true&_hideNav=true'
exports.retoolPreconProjectsPage =
  'https://masonco.retool.com/apps/Precon%20-%20Live/Precon%20projects?_environment=production&_hideTimer=true&_hideNav=true'
exports.takeoffTeamPage =
  'https://masonco.retool.com/embedded/public/8484f6f1-8141-4c2c-8268-a9e78cc0f7c6?_environment=production&_hideTimer=true&_hideNav=true'
exports.retoolServicesAdminPage =
  'https://masonco.retool.com/embedded/public/2b8b51c0-8433-4f1e-994d-3df4845f2f54?_environment=production&_hideTimer=true&_hideNav=true'
exports.retoolGCPage =
  'https://masonco.retool.com/apps/Precon%20-%20Live/General%20Contractors?_environment=production&_hideTimer=true&_hideNav=true'
exports.hubspotChatSrc = 'https://js-na1.hs-scripts.com/19892828.js'
exports.retoolDealFilesPage =
  'https://masonco.retool.com/apps/00856d34-4b81-11ec-a917-2b58b0b34672/Precon%20-%20Live/HubSpot%20Deal%20files?_releaseVersion=latest&_environment=production&_hideTimer=true&_hideNav=true'
exports.subBidPackageUrl = 'https://bid.usemason.com'
