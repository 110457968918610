import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  // useMemo,
  // useEffect,
  useState,
  useContext
} from 'react'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormLabel,
  FormControl,
  Text,
  Select
} from '@chakra-ui/react'
import _ from 'lodash'
import ProjectContext from 'contexts/ProjectContext'
import { useSelector } from 'model/hooks'

export interface ISaveReportTemplateModal {
  open(): void
}

type Props = {}

const SaveReportTemplateModal: ForwardRefRenderFunction<ISaveReportTemplateModal, Props> = (
  props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [templateId, setTemplateId] = useState<string | undefined>()
  const reportTemplates = useSelector(state => _.get(state, 'account.reportTemplates', {}))
  const { createReportTemplate, updateReportTemplate, currentTemplateId } = useContext(ProjectContext)

  useImperativeHandle(ref, () => ({
    open: () => {
      setName('')
      if (currentTemplateId) {
        setTemplateId(currentTemplateId)
      }
      onOpen()
    }
  }))

  const sortedTemplates = _.sortBy(reportTemplates, 'createdAt')

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save template</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={2} pt={8} pb={2}>
            <FormLabel>Create new template</FormLabel>
            <Flex>
              <Input value={name} placeholder='Enter template name' onChange={e => setName(e.target.value)} />
              <Button
                colorScheme={'green'}
                ml={2}
                isDisabled={_.isEmpty(name)}
                onClick={() => {
                  createReportTemplate(name)
                  onClose()
                }}
              >
                Create
              </Button>
            </Flex>
          </FormControl>
          <Text color={'gray.500'} textAlign={'center'}>
            or
          </Text>
          <FormControl mb={2} pt={2} pb={12}>
            <FormLabel>Update an existing template</FormLabel>
            <Flex>
              <Select
                value={templateId}
                onChange={e => setTemplateId(e.target.value)}
                placeholder='Select template'
                isDisabled={_.isEmpty(sortedTemplates)}
              >
                {_.map(sortedTemplates, t => {
                  return (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  )
                })}
              </Select>
              <Button
                colorScheme={'green'}
                ml={2}
                isDisabled={_.isEmpty(templateId)}
                onClick={() => {
                  if (templateId) {
                    updateReportTemplate(templateId)
                  }
                  onClose()
                }}
              >
                Update
              </Button>
            </Flex>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(SaveReportTemplateModal)
