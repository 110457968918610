import { FC, useState } from 'react'
import _ from 'lodash'
import { Editable, EditableInput, EditablePreview, Flex } from '@chakra-ui/react'
import numeral from 'numeral'

type Props = {
  value?: number
  placeholder?: string
  onSubmit: (nextValue: number | undefined) => void
  formatString?: string
  notEditable?: boolean
}

const EditableTableCell: FC<Props> = ({
  value,
  placeholder,
  onSubmit,
  formatString = '$0,0.[00]',
  notEditable = false
}) => {
  const [v, setV] = useState<string | undefined>(undefined)
  const [editing, setEditing] = useState(false)

  // useEffect(() => {
  //   setV(value || '')
  // }, [value])

  const onChange = (nextV: string) => {
    console.log('setV', nextV)
    const valueRaw = nextV.replace(/[^0-9.,$]/g, '')
    const newValue = valueRaw.replace(/[^0-9.,]/g, '')
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(v).value()
      if (newV === curV || _.isNil(v)) {
        setV(valueRaw)
      } else {
        setV(numeral(newValue).format(formatString))
      }
    } else {
      setV('')
    }
  }

  const apply = () => {
    setEditing(false)
    if (!_.isNil(v)) {
      const numV = numeral(v).value()
      const cost = _.size(v) > 0 ? (_.isNil(numV) ? undefined : numV) : undefined
      const finalValue = onSubmit(cost)
      if (!_.isNil(finalValue)) {
        setV(numeral(finalValue).format(formatString))
      } else {
        setV(undefined)
      }
    }
  }

  let curValue: string = _.toString(value)
  if (!_.isEmpty(_.toString(curValue))) {
    const format = editing && !notEditable ? '$0,0.[00]' : '$0,0.00'
    curValue = numeral(curValue).format(format)
  }
  if (!_.isNil(v)) curValue = v
  if (_.isNil(curValue)) curValue = ''

  return (
    <Flex borderLeftWidth={1} w='full' h='full' align={'center'} px={2}>
      <Editable
        value={curValue}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={apply}
        onSubmit={apply}
        onEdit={() => setEditing(true)}
        w='full'
        isDisabled={notEditable}
      >
        <EditablePreview pl={2} minW='100%' color={_.isEmpty(curValue) ? 'gray.300' : 'blue.600'} />
        <EditableInput px={2} />
      </Editable>
    </Flex>
  )
}

export default EditableTableCell
