import * as types from 'model/actionTypes'
import { DictT } from 'model/types'

const initialState: DictT<string> = {}

export default function (state = initialState, action: { type: string; tradesDict: DictT<string> }) {
  switch (action.type) {
    case types.RECEIVE_TRADES_DICT:
      return {
        ...state,
        ...action.tradesDict
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
