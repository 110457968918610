export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

export const pdfHeaders = {
  ...headers,
  'Content-Type': 'application/pdf'
}

export default headers
