import * as types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { ProjectPresetT, DictT } from 'model/types'

const initialState: DictT<ProjectPresetT> | null = null

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_PROJECT_PRESETS:
      return action.presets
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
