import { Flex } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'

import SettingsMenu from 'pages/projectSettings/SettingsMenu'
import SettingsContent from 'pages/projectSettings/SettingsContent'
import BottomPanel from 'components/BottomPanel'

const ProjectSettings = () => {
  const navigate = useNavigate()
  const { projectId } = useParams()

  return (
    <Flex direction={'column'} bg='gray.50' h='100%' overflow={'hidden'}>
      <Flex direction='row' h='full' overflow={'hidden'}>
        <SettingsMenu />
        <SettingsContent />
      </Flex>
      <BottomPanel
        stepNum={0}
        onExit={() => navigate('/')}
        onNext={() => navigate(`/project/${projectId}/scope-builder`, { state: { canGoBack: true } })}
      />
    </Flex>
  )
}

export default ProjectSettings
