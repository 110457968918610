import { doc, onSnapshot, updateDoc } from 'firebase/firestore'

import { db } from 'controllers/db'
import store from 'model/store'
import { receiveAccount } from 'model/actions/accountAC'
import { addListener } from 'controllers/listeners'
import { AccountT } from 'model/types'

export const fetchAccount = (accountId: string) => {
  try {
    const accountUnsubscribe = onSnapshot(
      doc(db, 'accounts', accountId),
      docSN => {
        const account = docSN.data() as AccountT
        store.dispatch(receiveAccount(account))
      },
      err => {
        console.log(`fetch account error: ${err}`)
        // Sentry.captureException(err)
      }
    )
    addListener('account', accountUnsubscribe)
  } catch (e) {
    console.log('fetch account error', e)
    // Sentry.captureException(e)
  }
}

export const updateAccount = async (accountId: string, params: Partial<AccountT>) => {
  try {
    console.log('updateAccount', accountId, params)
    const ref = doc(db, 'accounts', accountId)
    await updateDoc(ref, params)
  } catch (e) {
    console.log('updateAccount account error', e)
    // Sentry.captureException(e)
  }
}
