import * as types from 'model/actionTypes'
import { DictT, ScopeSectionDictT, SowItemDictT, ProjectPresetT, PDetailsT, NoteT } from 'model/types'

export function receiveScopeSectionsDict (scopeSectionsDict: DictT<ScopeSectionDictT>) {
  return {
    type: types.RECEIVE_SCOPE_SECTIONS_DICT,
    scopeSectionsDict
  }
}

export function receiveSowItemsDict (sowItemsDict: DictT<SowItemDictT>) {
  return {
    type: types.RECEIVE_SOW_ITEMS_DICT,
    sowItemsDict
  }
}

export function receiveTradesDict (tradesDict: DictT<string>) {
  return {
    type: types.RECEIVE_TRADES_DICT,
    tradesDict
  }
}

export function receiveProjectPresets (presets: DictT<ProjectPresetT>) {
  return {
    type: types.RECEIVE_PROJECT_PRESETS,
    presets
  }
}

export function receiveProjectDetails (pdetails: DictT<PDetailsT>) {
  return {
    type: types.RECEIVE_PROJECT_DETAILS,
    pdetails
  }
}

export function receiveNotesDict (notes: DictT<NoteT>) {
  return {
    type: types.RECEIVE_NOTES_DICT,
    notes
  }
}
