import { useContext, useRef, useMemo, useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Flex,
  Button,
  HStack,
  Text,
  Tag,
  TagLabel,
  Skeleton,
  useToast,
  useClipboard,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Tooltip
} from '@chakra-ui/react'
import {
  faAnglesLeft,
  faArrowUpRightFromSquare,
  faChevronDown,
  faHouse,
  faSpinner
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ShareProjectModal, { IShareProjectModal } from 'src/modals/ShareProjectModal'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import ProjectContext from 'contexts/ProjectContext'
import { generateId } from 'controllers/db'
import { updateAccount } from 'controllers/account'
import ProjectFilesModal, { IProjectFilesModal } from 'modals/ProjectFilesModal'

// import ImportProjectButton from 'pages/scopeBuilder/ImportProjectButton'

const ScopeBuilderNavBar = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const account = useSelector(state => state.account)
  const location = useLocation()
  const { project, isNew, hasChanges, saveProject, detailsDict, isDataLoading, reloadAirtable } = useContext(
    ProjectContext
  )
  const shareProjectModalRef = useRef<IShareProjectModal>(null)
  const projectFilesModalRef = useRef<IProjectFilesModal>(null)
  const projectDetails = _.get(project, 'details', [])
  const [loading, setLoading] = useState(false)

  const url = useMemo(() => {
    // return `${config.subHost}${location.pathname}?share=${account.sharableKey}`
    return `${window.location.host}${location.pathname}?share=${account.sharebleKey}`
  }, [account.sharebleKey])

  const { hasCopied, onCopy } = useClipboard(url)

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Link copied',
        description: 'Paste the link from your clipboard to share',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    }
  }, [hasCopied])

  useEffect(() => {
    if (!_.has(account, 'sharebleKey')) {
      console.log('sharebleKey is not set')
      const newKey = generateId()
      console.log('new sharebleKey is generated', newKey)
      updateAccount(account.id, { sharebleKey: newKey })
    }
  }, [])

  const tags = _.map(projectDetails, d => {
    const info = _.get(detailsDict, d)
    return (
      <Tag key={d} bgColor='gray.400' size='sm'>
        <Skeleton isLoaded={!isDataLoading}>
          <TagLabel color='white'>{info?.name}</TagLabel>
        </Skeleton>
      </Tag>
    )
  })

  const onReloadClick = async () => {
    console.log('onReloadClick')
    setLoading(true)
    await reloadAirtable()
    setLoading(false)
  }

  const renderButtons = () => {
    if (isNew || hasChanges) {
      return (
        <Button variant={'solid'} colorScheme={'teal'} onClick={saveProject}>
          Save project
        </Button>
      )
    } else {
      return (
        <HStack>
          <Button
            variant={'outline'}
            leftIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            colorScheme={'gray'}
            onClick={() => shareProjectModalRef?.current?.open()}
          >
            Share Bid Packages
          </Button>
          <Button
            variant={'solid'}
            colorScheme={'teal'}
            onClick={() => navigate(`/project/${project?.id}/report_editor`)}
          >
            Create proposal
          </Button>
        </HStack>
      )
    }
  }

  const goBack = () => {
    navigate(`/project/${project?.id}/settings`)
  }

  const dropdownMenu = () => {
    return (
      <Menu>
        <Tooltip
          label='The menu is not available because the project is not saved yet'
          isDisabled={!isNew}
          hasArrow
          bg='red.600'
          maxW={'48'}
        >
          <MenuButton disabled={loading || isNew} color={loading ? 'gray.300' : 'gray.600'}>
            <FontAwesomeIcon icon={loading ? faSpinner : faChevronDown} spin={loading} fixedWidth />
          </MenuButton>
        </Tooltip>
        <MenuList>
          <MenuItem onClick={onCopy}>Copy Public Link</MenuItem>
          <MenuItem onClick={onReloadClick}>Reload from Airtable</MenuItem>
          <MenuItem onClick={() => shareProjectModalRef?.current?.open()}>Bid Package Link</MenuItem>
          <MenuItem onClick={() => projectFilesModalRef?.current?.open()}>Manage Files</MenuItem>
        </MenuList>
      </Menu>
    )
  }

  return (
    <Flex bgColor={'white'} w='full' direction='row' justify={'space-between'} pr={2} pt={2}>
      <HStack>
        <Button gap='2' variant={'ghost'} color='gray.600' onClick={goBack}>
          <FontAwesomeIcon icon={faAnglesLeft} />
          <FontAwesomeIcon icon={faHouse} />
        </Button>
        <Text color='gray.600'>{_.get(project, 'address.name')}</Text>
        <Box pl={4}>{dropdownMenu()}</Box>
        <HStack pl={8}>{tags}</HStack>
      </HStack>
      {/* <ImportProjectButton /> */}
      {renderButtons()}
      <ShareProjectModal ref={shareProjectModalRef} />
      <ProjectFilesModal ref={projectFilesModalRef} />
    </Flex>
  )
}

export default ScopeBuilderNavBar
