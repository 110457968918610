import { FC, useState, useContext, MouseEvent } from 'react'
import { Box, Heading, Text, VStack, Flex, StackDivider, Collapse } from '@chakra-ui/react'
import _ from 'lodash'
import { useMeasure } from 'react-use'

import itemsColors from 'constants/itemsColors'
import { roundRobinGet } from 'shared/utils/array'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import ProjectContext from 'contexts/ProjectContext'
import ScopeItemRow from 'pages/scopeBuilder/scopeBuilderContent/ScopeItemRow'
import { SowItemDictT } from 'model/types'
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'

type TradeSectionProps = {
  sectionsDict: object
  sections: object
  tradeId: string
  readOnly?: boolean
  tradeName: string
}

const TradeSection: FC<TradeSectionProps> = ({ sectionsDict, sections, tradeId, readOnly = false, tradeName }) => {
  const { excludedScopeItemsDict, toggleSowItem, toggleSowSectionItems } = useContext(ProjectContext)
  const [scrollRef] = useMeasure<HTMLDivElement>()
  const [closedZones, setClosedZones] = useState({})

  const toggleZone = (tradeId: string, sectionId: string) => {
    const key = tradeId + sectionId
    const open = !_.get(closedZones, key, false)
    setClosedZones(closedZones => ({ ...closedZones, [key]: open }))
  }

  // const toggleItem = useCallback(sowItemId => {
  //   toggleSowItem(sowItemId)
  // }, [])

  const onToggleSectionClick = (event: MouseEvent<HTMLElement>, itemsIds: string[], add: boolean) => {
    event.stopPropagation()
    toggleSowSectionItems(itemsIds, add)
  }

  const renderZone = (sowItems: object, sectionId: string, index: number, tradeId: string) => {
    const bgColor = roundRobinGet(itemsColors, index)
    const key = tradeId + sectionId
    const open = !_.get(closedZones, key, false)
    return (
      <Box key={sectionId}>
        <Flex
          direction={'row'}
          w='fit-content'
          align='center'
          gap={2}
          as='button'
          onClick={() => toggleZone(tradeId, sectionId)}
        >
          <Box transform={open ? 'rotate(90deg)' : undefined} transitionDuration={'0.2s'}>
            <FontAwesomeIcon icon={faCaretRight} />
          </Box>
          <Text bgColor={bgColor} px={4} rounded={'base'}>
            {_.get(sectionsDict, [sectionId, 'name'])}
          </Text>
          <Box
            _hover={{ color: 'blue.600' }}
            color='gray.600'
            pl={2}
            as='button'
            onClick={(event: MouseEvent<HTMLElement>) => onToggleSectionClick(event, _.keys(sowItems), true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} size='sm' />
          </Box>
          <Box
            _hover={{ color: 'red.500' }}
            color='gray.600'
            pl={1}
            as='button'
            onClick={(event: MouseEvent<HTMLElement>) => onToggleSectionClick(event, _.keys(sowItems), false)}
          >
            <FontAwesomeIcon icon={faMinusCircle} size='sm' />
          </Box>
        </Flex>
        <Collapse in={open}>
          <VStack align={'flex-start'} divider={<StackDivider borderColor='gray.200' />} pt={2} pl={4} spacing={0}>
            {_.map(_.sortBy(sowItems, 'name'), (sowItem: SowItemDictT) => {
              if (readOnly && _.has(excludedScopeItemsDict, sowItem.id)) return undefined
              return (
                <ScopeItemRow
                  key={sowItem.id}
                  isExcluded={_.has(excludedScopeItemsDict, sowItem.id)}
                  readOnly={readOnly}
                  itemId={sowItem.id}
                  name={sowItem.name}
                  toggle={toggleSowItem}
                />
              )
            })}
          </VStack>
        </Collapse>
      </Box>
    )
  }

  const zonesIds = _.sortBy(_.keys(sections), sectionId => _.get(sectionsDict, [sectionId, 'name']))
  return (
    <Box key={tradeId} ref={scrollRef} id={tradeId}>
      <Heading size='md' color={'blue.600'}>
        {tradeName}
      </Heading>
      <VStack align={'flex-start'} pt='4' spacing={4}>
        {_.map(zonesIds, (sectionId, index) => renderZone(_.get(sections, sectionId), sectionId, index, tradeId))}
      </VStack>
    </Box>
  )
}

export default TradeSection
