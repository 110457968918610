import { Box, Text, Flex } from '@chakra-ui/react'
import { FC, memo, PropsWithChildren } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinusCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  isExcluded: boolean
  readOnly: boolean
  itemId: string
  name: string
  toggle: (itemId: string) => void
}

const ScopeItemRow: FC<Props> = ({ isExcluded, readOnly, itemId, name, toggle }) => {
  const renderSowIcon = (itemId: string) => {
    if (!isExcluded) {
      return (
        <>
          <Box color='green.300' pr={2} pl={1} display={'block'} _groupHover={{ display: 'none' }}>
            <FontAwesomeIcon icon={faCheck} size='sm' />
          </Box>
          <Box
            color='red.500'
            pr={2}
            pl={1}
            display={'none'}
            _groupHover={{ display: 'block' }}
            as='button'
            onClick={() => toggle(itemId)}
          >
            <FontAwesomeIcon icon={faMinusCircle} size='sm' />
          </Box>
        </>
      )
    } else {
      return (
        <>
          <Box color='gray.300' pr={2} pl={1} display={'block'} _groupHover={{ display: 'none' }}>
            <FontAwesomeIcon icon={faPlusCircle} size='sm' />
          </Box>
          <Box
            color='blue.600'
            pr={2}
            pl={1}
            display={'none'}
            _groupHover={{ display: 'block' }}
            as='button'
            onClick={() => toggle(itemId)}
          >
            <FontAwesomeIcon icon={faPlusCircle} size='sm' />
          </Box>
        </>
      )
    }
  }

  // console.log('scope item row render')
  if (isExcluded && readOnly) return null
  return (
    <Box key={itemId} _hover={{ bgColor: 'gray.100' }} py={1} w='full' role={readOnly ? undefined : 'group'}>
      <Flex direction={'row'} w='fit-content' align='center' gap={2}>
        {renderSowIcon(itemId)}
        <Text
          textAlign={'left'}
          key={itemId}
          color={isExcluded ? 'gray.300' : 'blue.600'}
          textDecoration={isExcluded ? 'line-through' : undefined}
        >
          {name}
        </Text>
      </Flex>
    </Box>
  )
}

const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<Props>>,
  nextProps: Readonly<PropsWithChildren<Props>>
) => {
  const r: string[] = []
  _.forEach(prevProps, (v, k) => {
    if (v !== _.get(nextProps, k)) {
      r.push(k)
    }
  })
  const res = _.isEmpty(r) || (_.size(r) === 1 && r[0] === 'toggle')
  // console.log('changes', r, 'res', res)
  return res
  // return _.isEqual(prevProps, nextProps)
}

export default memo(ScopeItemRow, propsAreEqual)
