import * as types from 'model/actionTypes'
import { DictT, SowItemDictT } from 'model/types'

const initialState: DictT<SowItemDictT> = {}

export default function (state = initialState, action: { type: string; sowItemsDict: DictT<SowItemDictT> }) {
  switch (action.type) {
    case types.RECEIVE_SOW_ITEMS_DICT:
      return {
        ...state,
        ...action.sowItemsDict
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
