import _ from 'lodash'

export const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to

    const [item] = array.splice(from, 1)
    array.splice(endIndex, 0, item)
  }
}

export const arrayMove = (array, from, to) => {
  array = [...array]
  arrayMoveMutate(array, from, to)
  return array
}

export const roundRobinGet = (array, index) => {
  if (_.isEmpty(array) || _.isNil(index) || index < 0) return null
  const l = _.size(array)
  const i = index % l
  return array[i]
}
