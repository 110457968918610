import { Provider } from 'react-redux'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import AppRouter from 'navigation/AppRouter'
import theme from './theme'
import store from 'model/store'
import 'react-base-table/styles.css'
import 'src/styles.scss'

export const App = () => (
  <Provider store={store}>
    <ChakraProvider theme={extendTheme(theme)}>
      <AppRouter />
    </ChakraProvider>
  </Provider>
)
