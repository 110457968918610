import { FC } from 'react'
import _ from 'lodash'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { SectionT, TableRowT } from 'model/types'
import styles from 'components/scopeReport/styles'
import numeral from 'numeral'

type Props = {
  section: SectionT
}

const tableStyle = StyleSheet.create({
  headerText: {
    color: '#4f4f4f'
  },
  footerText: {
    color: '#AAAAAA'
  },
  tableRow: {
    height: 16
  }
})

const borderStyle = '#0.5px solid #dddddd'

const ScopeReportTable: FC<Props> = ({ section }) => {
  const data = _.sortBy(section.rows, ['createdAt'])

  const columnWidth = 78

  const totalMaterials = _.reduce(
    section.rows,
    (res, r) => {
      const m = _.get(r, 'materials', 0)
      return res + m
    },
    0
  )

  const totalLabor = _.reduce(
    section.rows,
    (res, r) => {
      const m = _.get(r, 'labor', 0)
      return res + m
    },
    0
  )

  const totalTotal = _.reduce(
    section.rows,
    (res, r) => {
      const m = _.get(r, 'materials', 0)
      const l = _.get(r, 'labor', 0)
      const t = _.get(r, 'total')
      if (_.isNil(t)) {
        return res + m + l
      } else {
        return res + t
      }
    },
    0
  )

  const tableRows = _.map(data, (r: TableRowT) => {
    const materials = r.materials ? numeral(r.materials).format('$0,0.00') : '-'
    const labor = r.labor ? numeral(r.labor).format('$0,0.00') : '-'
    let totalValue = r.total
    if (_.isNil(totalValue)) {
      totalValue = _.get(r, 'materials', 0) + _.get(r, 'labor', 0)
    }
    const total = totalValue > 0 ? numeral(totalValue).format('$0,0.00') : '-'
    return (
      <View
        key={r.id}
        style={{
          backgroundColor: '#FFF',
          flexDirection: 'row',
          ...tableStyle.tableRow,
          border: borderStyle,
          borderTop: 'none'
        }}
        wrap={false}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <Text style={{ ...styles.textSmall, color: '#004069', marginLeft: 8 }}>{r.name}</Text>
        </View>
        <View
          style={{
            width: columnWidth,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderLeft: borderStyle
          }}
        >
          <Text style={{ ...styles.textSmall, color: '#4A5568', textAlign: 'center' }}>{materials}</Text>
        </View>
        <View
          style={{
            width: columnWidth,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderLeft: borderStyle
          }}
        >
          <Text style={{ ...styles.textSmall, color: '#4A5568', textAlign: 'center' }}>{labor}</Text>
        </View>
        <View
          style={{
            width: columnWidth,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderLeft: borderStyle
          }}
        >
          <Text style={{ ...styles.textSmall, color: '#4A5568', textAlign: 'center' }}>{total}</Text>
        </View>
      </View>
    )
  })

  const tableHeader = (
    <View
      style={{
        backgroundColor: '#EDF2F7',
        flexDirection: 'row',
        ...tableStyle.tableRow,
        border: borderStyle,
        marginTop: 20
      }}
      wrap={false}
    >
      <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
        <Text
          style={{
            ...styles.heading,
            ...tableStyle.headerText,
            marginLeft: 6
          }}
        >
          {section.name}
        </Text>
      </View>
      <View
        style={{
          width: columnWidth,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          borderLeft: borderStyle
        }}
      >
        <Text style={{ ...styles.heading, ...tableStyle.headerText, textAlign: 'center' }}>MATERIALS</Text>
      </View>
      <View
        style={{
          width: columnWidth,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          borderLeft: borderStyle
        }}
      >
        <Text style={{ ...styles.heading, ...tableStyle.headerText, textAlign: 'center' }}>LABOR</Text>
      </View>
      <View
        style={{
          width: columnWidth,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          borderLeft: borderStyle
        }}
      >
        <Text style={{ ...styles.heading, ...tableStyle.headerText, textAlign: 'center' }}>TOTAL</Text>
      </View>
    </View>
  )

  const tableFooter = (
    <View
      style={{
        backgroundColor: '#fff',
        flexDirection: 'row',
        ...tableStyle.tableRow,
        height: 20
      }}
      wrap={false}
    >
      <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }} />
      <View
        style={{
          width: columnWidth,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Text style={{ ...styles.textSmall, ...tableStyle.footerText, textAlign: 'center' }}>
          {numeral(totalMaterials).format('$0,0.00')}
        </Text>
      </View>
      <View
        style={{
          width: columnWidth,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Text style={{ ...styles.textSmall, ...tableStyle.footerText, textAlign: 'center' }}>
          {numeral(totalLabor).format('$0,0.00')}
        </Text>
      </View>
      <View
        style={{
          width: columnWidth,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Text style={{ ...styles.textSmall, ...tableStyle.footerText, textAlign: 'center' }}>
          {numeral(totalTotal).format('$0,0.00')}
        </Text>
      </View>
    </View>
  )

  return (
    <>
      {tableHeader}
      {tableRows}
      {tableFooter}
    </>
  )
}

export default ScopeReportTable
