import { FC, useState, useEffect } from 'react'
import _ from 'lodash'
import { IconButton, Flex, HStack, Button, useClipboard, useToast } from '@chakra-ui/react'
import { faChevronLeft, faCopy, faPaperclipVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveAs } from 'file-saver'

// import ProjectContext from 'contexts/ProjectContext'
import { useNavigate } from 'react-router-dom'
import { saveFileObject } from 'controllers/storage'

type Props = {
  fileName: string
  blob?: Blob
  projectId: string
}

const ReportNavBar: FC<Props> = ({ fileName, blob, projectId }) => {
  // const { project } = useContext(ProjectContext)
  const [linkGeneration, setLinkGeneration] = useState(false)
  const [link, setLink] = useState<string | undefined>()
  const { hasCopied, onCopy } = useClipboard(link || '')
  const toast = useToast()
  const navigate = useNavigate()

  const onDownloadFileClick = () => {
    if (blob) {
      saveAs(blob, fileName)
    }
  }

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Link copied',
        description: 'Paste the link from your clipboard to share',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    }
  }, [hasCopied])

  useEffect(() => {
    if (!_.isNil(link)) {
      onCopy()
    }
  }, [link])

  const copyLink = async () => {
    if (_.isNil(link) && !_.isNil(blob)) {
      setLinkGeneration(true)
      const storagePath = `/projects/${projectId}/reports/${fileName}`
      const f = await saveFileObject(blob, storagePath)
      setLinkGeneration(false)
      if (f?.url) {
        setLink(f?.url)
      }
    } else {
      onCopy()
    }
  }

  const leftSide = (
    <HStack direction='row' align={'center'} spacing={2}>
      <IconButton
        variant={'ghost'}
        aria-label='Go back'
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={() => navigate(-1)}
      />
    </HStack>
  )

  const rightSide = (
    <Flex direction='row' align={'center'} pr={4} gap={4}>
      <Button
        variant={'outline'}
        leftIcon={<FontAwesomeIcon icon={faPaperclipVertical} />}
        // colorScheme={'teal'}
        // onClick={() => navigate(`/project/${project?.id}/share`)}
        isDisabled={_.isNil(blob)}
        onClick={onDownloadFileClick}
      >
        Download
      </Button>
      <Button
        leftIcon={<FontAwesomeIcon icon={faCopy} />}
        colorScheme={'teal'}
        isLoading={linkGeneration}
        isDisabled={linkGeneration || _.isNil(blob)}
        onClick={copyLink}
      >
        Copy Link
      </Button>
    </Flex>
  )

  return (
    <Flex
      direction={'row'}
      align='center'
      justify={'space-between'}
      py={4}
      borderBottom='1px'
      borderColor={'gray.200'}
      overflow={'hidden'}
    >
      {leftSide}
      {rightSide}
    </Flex>
  )
}

export default ReportNavBar
