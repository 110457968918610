import { FC } from 'react'
import _ from 'lodash'
import { View, Text } from '@react-pdf/renderer'
import styles from 'components/scopeReport/styles'
import { roundRobinGet } from 'shared/utils/array'
import itemsColors from 'constants/itemsColors'
import { SowItemDictT, DictT } from 'model/types'

type Props = {
  sectionsDict: object
  sections: object
  tradeId: string
  tradeName: string
  excludedScopeItemsDict: DictT<string>
}

const ReportTradeSection: FC<Props> = ({ sectionsDict, sections, tradeId, tradeName, excludedScopeItemsDict }) => {
  const zonesIds = _.sortBy(_.keys(sections), sectionId => _.get(sectionsDict, [sectionId, 'name']))

  const renderZone = (sowItems: object, sectionId: string, index: number) => {
    const bgColor = roundRobinGet(itemsColors, index)
    return (
      <View key={sectionId} style={{ flexDirection: 'column', paddingTop: 10 }}>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              ...styles.textSmall,
              fontWeight: 'semibold',
              backgroundColor: bgColor,
              marginLeft: 11,
              paddingHorizontal: 5,
              paddingVertical: 1,
              borderRadius: 1
            }}
          >
            {_.get(sectionsDict, [sectionId, 'name'])}
          </Text>
          <View style={{ flex: 1 }} />
        </View>
        {_.map(_.sortBy(sowItems, 'name'), (sowItem: SowItemDictT) => {
          if (_.has(excludedScopeItemsDict, sowItem.id)) return undefined
          return (
            <View
              key={sowItem.id}
              style={{ marginLeft: 30, marginTop: 4, flexDirection: 'row', alignItems: 'flex-start' }}
              wrap={false}
            >
              <Text style={{ ...styles.textMedium, color: '#004069' }}>•</Text>
              <Text style={{ ...styles.textXSmall, color: '#004069', marginLeft: 4 }}>{sowItem.name}</Text>
            </View>
          )
        })}
      </View>
    )
  }

  return (
    <View key={tradeId} style={{ flexDirection: 'column', paddingTop: 12 }}>
      <Text style={{ ...styles.textLarge, fontWeight: 'semibold', color: '#004069' }}>{tradeName}</Text>
      {_.map(zonesIds, (sectionId, index) => renderZone(_.get(sections, sectionId), sectionId, index))}
    </View>
  )
}

export default ReportTradeSection
