import _ from 'lodash'
import { RootState } from 'model/rootReducer'
import { UserT, DictT, AccountProfileT } from 'model/types'
import { createSelector } from '@reduxjs/toolkit'

export const getProjectDetails = (state: RootState) => state.projectDetails
export const getTrades = (state: RootState) => state.tradesDict
export const getSowItems = (state: RootState) => state.sowItemsDict
export const getUser = (state: RootState) => state.user as UserT | null
export const getUserId = (state: RootState) => _.get(state, 'user.id')
export const getAccountId = (state: RootState) => _.get(state, 'account.id')
export const getProfiles = (state: RootState) => state.profiles
export const getAccountsProfiles = (state: RootState) => state.accountsProfiles as DictT<AccountProfileT> | null
export const getProjects = (state: RootState) => state.projects
export const getAccount = (state: RootState) => state.account

export const getAppLoaded = createSelector(
  [getAccount, getUser, getProjects, getAccountsProfiles],
  (account, user, projects, accountsProfiles) => {
    const lst = [account, user, projects, accountsProfiles]
    const doneAmount = _.reduce(
      lst,
      (res, elt) => {
        return _.isNil(elt) ? res : res + 1
      },
      0
    )
    return doneAmount === _.size(lst) ? null : (doneAmount / _.size(lst)) * 100
  }
)
