import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import _ from 'lodash'

import { appInitialized } from 'controllers/init'
import { useSearchParams, Outlet } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { accessTokenBySharebleKey } from 'controllers/share'
import { signIn } from 'controllers/auth'

const NavWrapper = () => {
  const navigate = useNavigate()
  const [search, setSearchParams] = useSearchParams()

  useEffect(() => {
    const run = async () => {
      console.log('call appInitialized')
      const sharebleKey = search.get('share')
      console.log('sharebleKey', sharebleKey)
      if (sharebleKey) {
        const token = await accessTokenBySharebleKey(sharebleKey)
        console.log('token', token)
        if (token) {
          search.delete('share')
          setSearchParams(search)
          await signIn(token)
        }
      }
      let dbId = search.get('dbId')
      if (_.isNil(dbId)) {
        dbId = 'appPAIqRqHlQXBleg'
      }
      appInitialized(dbId, navigate)
    }
    run()
  }, [])

  return (
    <Box w='full' h='full'>
      <Outlet />
    </Box>
  )
}

export default NavWrapper
