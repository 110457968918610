import { createContext } from 'react'
import {
  BaseScopeT,
  ProjectT,
  DictT,
  ScopeSectionDictT,
  ProjectPresetT,
  PDetailsT,
  BaseNotesT,
  SectionT,
  TotalRowT,
  TableRowT
} from 'model/types'

export interface ProjectContextT {
  project: ProjectT | null
  updateProject: (params: Partial<ProjectT>) => void
  isNew: boolean
  baseScope: BaseScopeT
  tradesDict: DictT<string>
  scopeSections: DictT<ScopeSectionDictT>
  projectScope: BaseScopeT
  hasChanges: boolean
  saveProject: () => void
  excludedScopeItemsDict: DictT<string>
  toggleSowItem: (itemId: string) => void
  presets: DictT<ProjectPresetT>
  detailsDict: DictT<PDetailsT>
  updateExcludedScopeItems: (itemsIds: string[]) => void
  isDataLoading: boolean
  reloadAirtable: () => Promise<null | undefined>
  baseNotes: BaseNotesT
  projectNotes: BaseNotesT
  sectionsOrder: string[]
  setSectionsOrder: (sectionsOrder: string[]) => void
  sections: DictT<SectionT>
  setSections: (sections: DictT<SectionT>) => void
  scopeHidden: boolean
  setScopeHidden: (hidden: boolean) => void
  totalsItems: DictT<TotalRowT>
  setTotalsItems: (totalsItems: DictT<TotalRowT>) => void
  costPlus?: number
  setCostPlus: (costPlus: number | undefined) => void
  reportChanged: boolean
  createReportTemplate: (name: string) => void
  updateReportTemplate: (templateId: string) => void
  currentTemplateId?: string
  loadTemplate: (templateId: string | undefined) => Promise<null | undefined>
  baseTradesRows: DictT<TableRowT>
  toggleSowSectionItems: (itemsIds: string[], add: boolean) => void
}

const Ctx = createContext<ProjectContextT>({
  project: null,
  updateProject: () => null,
  isNew: true,
  baseScope: {},
  tradesDict: {},
  scopeSections: {},
  projectScope: {},
  hasChanges: false,
  saveProject: () => null,
  excludedScopeItemsDict: {},
  toggleSowItem: () => null,
  presets: {},
  detailsDict: {},
  updateExcludedScopeItems: () => null,
  isDataLoading: false,
  reloadAirtable: async () => null,
  baseNotes: {},
  projectNotes: {},
  sectionsOrder: [],
  setSectionsOrder: () => null,
  sections: {},
  setSections: () => null,
  scopeHidden: false,
  setScopeHidden: () => null,
  totalsItems: {},
  setTotalsItems: () => null,
  costPlus: undefined,
  setCostPlus: () => null,
  reportChanged: false,
  createReportTemplate: () => null,
  updateReportTemplate: () => null,
  loadTemplate: async () => null,
  baseTradesRows: {},
  toggleSowSectionItems: () => null
})

export default Ctx
