import * as types from 'model/actionTypes'
import { DictT, ScopeSectionDictT } from 'model/types'

const initialState: DictT<ScopeSectionDictT> = {}

export default function (state = initialState, action: { type: string; scopeSectionsDict: DictT<ScopeSectionDictT> }) {
  switch (action.type) {
    case types.RECEIVE_SCOPE_SECTIONS_DICT:
      return {
        ...state,
        ...action.scopeSectionsDict
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
