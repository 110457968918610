import { FC, ReactElement } from 'react'
import { Text, Flex, Radio, Center } from '@chakra-ui/react'

type NumOfStoriesCardProps = {
  image: ReactElement
  title: string
  isSelected: boolean
  onClick: Function
}

const NumOfStoriesCard: FC<NumOfStoriesCardProps> = ({ image, title, isSelected, onClick }) => {
  return (
    <Flex
      direction={'column'}
      borderWidth='1px'
      borderColor={'green.500'}
      borderRadius='base'
      outline={isSelected ? '1px solid' : undefined}
      outlineColor='green.500'
      bgColor={isSelected ? 'green.50' : undefined}
      minH='185px'
      minW='150px'
      px={4}
      py={4}
      // gap={2}
      as='button'
      _hover={{
        outline: '1px solid',
        outlineColor: 'green.500',
        bgColor: 'green.50'
      }}
      onClick={() => onClick()}
    >
      <Flex w='full' justify={'flex-end'}>
        <Radio isChecked={isSelected} colorScheme={'green'} />
      </Flex>
      <Center w='full'>{image}</Center>
      <Center w='full' pt={2}>
        <Text textAlign={'center'} fontWeight='semibold' fontSize='lg'>
          {title}
        </Text>
      </Center>
    </Flex>
  )
}

export default NumOfStoriesCard
