import { useState, FC } from 'react'
import _ from 'lodash'
import { Button, HStack, Input, Box, Flex } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  apply: (name: string) => void
}

const AddTableButton: FC<Props> = ({ apply }) => {
  const [focused, setFocused] = useState(false)
  const [name, setName] = useState('')

  const close = () => {
    setFocused(false)
    setName('')
  }

  const onSubmit = () => {
    console.log('onSubmit')
    if (!_.isEmpty(name)) {
      apply(name)
    }
    close()
  }

  const renderContent = () => {
    if (focused) {
      return (
        <HStack spacing={4} pl={2}>
          <Input
            size='sm'
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder='Section name'
            onSubmit={onSubmit}
            onKeyDown={e => (e.key === 'Enter' ? onSubmit() : undefined)}
            autoFocus
          />
          <Button px={8} size='sm' colorScheme={'teal'} onClick={onSubmit}>
            Add
          </Button>
          <Button size='sm' color='gray.400' variant={'link'} onClick={close}>
            Cancel
          </Button>
        </HStack>
      )
    } else {
      return (
        <Button
          pl={4}
          size='sm'
          color='gray.400'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          variant={'link'}
          onClick={() => setFocused(true)}
        >
          Add section
        </Button>
      )
    }
  }

  return (
    <Box mx={4} h={12} position={'relative'}>
      <Flex
        w={'full'}
        h='full'
        align={'center'}
        overflow={'hidden'}
        position={'relative'}
        _before={{
          content: '""',
          position: 'absolute',
          borderWidth: '5px',
          borderStyle: 'dashed',
          borderColor: 'gray.300',
          top: '-4px',
          bottom: '-4px',
          left: '-4px',
          right: '-4px'
        }}
      >
        {renderContent()}
      </Flex>
    </Box>
  )
}

export default AddTableButton
