import { useImperativeHandle, ForwardRefRenderFunction, Ref, forwardRef, useContext, useState } from 'react'
import _ from 'lodash'
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Divider,
  ModalFooter,
  Button
} from '@chakra-ui/react'
import ProjectContext from 'contexts/ProjectContext'
import { ParticipantDetailsT } from 'model/types'
import { getAddress, getName } from 'shared/utils/stringUtils'
import { dbUpdateProject } from 'controllers/projects'
import { useSelector } from 'react-redux'
import { getCurrentAccountProfile } from 'model/selectors/profiles'
import { formatNational } from 'shared/utils/phone'

export interface IEditReportParticipantModal {
  open(): void
}

type Props = {}

const EditReportParticipantModal: ForwardRefRenderFunction<IEditReportParticipantModal, Props> = (
  props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { project } = useContext(ProjectContext)
  const accountProfile = useSelector(getCurrentAccountProfile)
  const [details, setDetails] = useState<ParticipantDetailsT>({
    projectAddress: _.get(project, 'participantDetails.projectAddress', getAddress(project?.address)),
    gcName: _.get(project, 'participantDetails.gcName', getName(accountProfile)),
    gcAddress: _.get(project, 'participantDetails.gcAddress', getAddress(accountProfile)),
    gcEmail: _.get(project, 'participantDetails.gcEmail', accountProfile?.email),
    gcPhone: formatNational(_.get(project, 'participantDetails.gcPhone', accountProfile?.phone)),
    ...(project?.participantDetails || {})
  })

  useImperativeHandle(ref, () => ({ open: () => onOpen() }))

  const onSave = () => {
    dbUpdateProject({ ...project, participantDetails: details })
    onClose()
  }

  const body = (
    <ModalBody>
      <FormControl pt={4}>
        <FormLabel htmlFor='address'>Project Address</FormLabel>
        <Input
          id='address'
          onChange={e => setDetails({ ...details, projectAddress: e.target.value })}
          value={details.projectAddress}
        />
      </FormControl>

      <Divider pt={6} />

      <Heading pt={6} size='sm'>
        Prepared for
      </Heading>

      <HStack pt={4}>
        <FormControl>
          <FormLabel htmlFor='firstName'>First Name</FormLabel>
          <Input
            id='firstName'
            placeholder='Susan'
            onChange={e => setDetails({ ...details, firstName: e.target.value })}
            value={details.firstName}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='lastName'>Last Name</FormLabel>
          <Input
            id='lastName'
            placeholder='Richards'
            onChange={e => setDetails({ ...details, lastName: e.target.value })}
            value={details.lastName}
          />
        </FormControl>
      </HStack>

      <FormControl pt={4}>
        <FormLabel htmlFor='customerAddress'>Street Address</FormLabel>
        <Input
          id='customerAddress'
          onChange={e => setDetails({ ...details, customerAddress: e.target.value })}
          value={details.customerAddress}
        />
      </FormControl>

      <HStack pt={4}>
        <FormControl>
          <FormLabel htmlFor='email'>Email</FormLabel>
          <Input
            id='email'
            type='email'
            placeholder='user@user.com'
            onChange={e => setDetails({ ...details, customerEmail: e.target.value })}
            value={details.customerEmail}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='tel'>Phone</FormLabel>
          <Input
            id='phone'
            type='tel'
            placeholder='(500) 200-2211'
            onChange={e => setDetails({ ...details, customerPhone: e.target.value })}
            value={details.customerPhone}
          />
        </FormControl>
      </HStack>

      <Divider pt={6} />

      <Heading pt={6} size='sm'>
        Prepared by
      </Heading>

      <FormControl pt={4}>
        <FormLabel htmlFor='gcName'>GC Company Name</FormLabel>
        <Input id='gcName' onChange={e => setDetails({ ...details, gcName: e.target.value })} value={details.gcName} />
      </FormControl>

      <FormControl pt={4}>
        <FormLabel htmlFor='gcAddres'>Address</FormLabel>
        <Input
          id='gcAddress'
          onChange={e => setDetails({ ...details, gcAddress: e.target.value })}
          value={details.gcAddress}
        />
      </FormControl>

      <HStack pt={4}>
        <FormControl>
          <FormLabel htmlFor='email'>Email</FormLabel>
          <Input
            id='gcEmails'
            placeholder='user@user.com'
            type='email'
            onChange={e => setDetails({ ...details, gcEmail: e.target.value })}
            value={details.gcEmail}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='tel'>Phone</FormLabel>
          <Input
            id='phone'
            type='tel'
            placeholder='(500) 200-2211'
            onChange={e => setDetails({ ...details, gcPhone: e.target.value })}
            value={details.gcPhone}
          />
        </FormControl>
      </HStack>
    </ModalBody>
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl' scrollBehavior={'inside'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={1}>
          <VStack spacing={0} align='flex-start'>
            <Heading size='md' color={'gray.900'}>
              Report details
            </Heading>
            <Text fontSize='sm' color={'gray.600'}>
              Update participant details below
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        {body}
        <ModalFooter>
          <Button mr={3} onClick={onClose} variant='outline'>
            Close
          </Button>
          <Button colorScheme={'blue'} onClick={onSave}>
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(EditReportParticipantModal)
