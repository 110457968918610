import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getAccountsProfiles as getAllAccountsProfiles,
  getProfiles,
  getUserId,
  getAccountId
} from 'model/selectors/base'

export const getCurrentUserProfile = createSelector([getUserId, getProfiles], (userId, profiles) => {
  console.log('getCurrentUserProfile, userId', userId)
  return _.get(profiles, userId, {})
})

export const getCurrentAccountProfile = createSelector(
  [getAccountId, getAllAccountsProfiles],
  (accountId, profiles) => {
    return _.get(profiles, accountId)
  }
)
