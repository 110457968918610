import { Flex, Text } from '@chakra-ui/react'

const CustomTableHeaderCell = ({
  className,
  column,
  columnIndex,
  color = 'gray.600'
}: {
  className: string
  column: any
  columnIndex: number
  color?: string
}) => (
  <Flex
    className={className}
    w='full'
    h='full'
    align='center'
    justifyContent={columnIndex > 0 ? 'center' : 'flex-start'}
    borderLeftWidth={columnIndex > 0 ? 1 : 0}
  >
    <Text color={color}>{column.title}</Text>
  </Flex>
)

export default CustomTableHeaderCell
